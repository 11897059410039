<aside class="main-sidebar">

  <section class="sidebar">

    <app-sidebar-user *ngIf="globals.config.elements.sidebar.user"></app-sidebar-user>
    <app-sidebar-search *ngIf="globals.config.elements.sidebar.search"></app-sidebar-search>

    <app-sidebar-menu [title]="'LABEL.MENU'|translate"></app-sidebar-menu>
    
  </section>

</aside>