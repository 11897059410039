// tslint:disable: directive-selector
import { Directive, ElementRef, HostListener, Input, OnInit, AfterContentChecked } from '@angular/core';

@Directive({
    selector: 'textarea[autogrow]'
})
export class AutogrowDirective implements AfterContentChecked {

    @Input() maximumHeight: number;
    @Input() minimumHeight: number;

    constructor(public element: ElementRef) {
    }

    ngAfterContentChecked(): void {
        this.adjust();
    }

    @HostListener('input', ['$event.target'])
    @HostListener('cut', ['$event.target'])
    @HostListener('paste', ['$event.target'])
    @HostListener('change', ['$event.target'])

    adjust(): void {
        const element = this.element.nativeElement;
        if (this.maximumHeight === undefined) { this.maximumHeight = 300; }
        if (this.minimumHeight === undefined) { this.minimumHeight = 60; }
        element.style.height = (this.minimumHeight + 5) + 'px';
        if (element.scrollHeight > 0)
            element.style.height = Math.min(element.scrollHeight + 5, this.maximumHeight) + 'px';
    }
}
