import { Audit } from 'app/shared/model/audit';

// Image class
export class Image {

  // Key
  url: string;

  // Fields
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;
  photo: string;
  attrib: string;

  // Audit
  audit : Audit = new Audit();
}

