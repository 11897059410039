// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Privilege } from 'app/crud/system/privilege/privilege';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all privileges
  readAll(sort: string): Observable<Page<Privilege>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of privileges
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Privilege>> {
    let url = `${this.globals.server}privileges?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Privilege>>(url).pipe(
      // tap(_ => console.log('read privileges')),
      catchError(this.handleError<Page<Privilege>>('read Privilege'))
    );
  }

  // Read pages of privileges from roles, roles cannot have composite keys
  readFromRole(role: number, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Privilege>> {
    let url = `${this.globals.server}roles/${role}/privileges?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Privilege>>(url).pipe(
      // tap(_ => console.log('read privileges')),
      catchError(this.handleError<Page<Privilege>>('read Privilege'))
    );
  }

  // Read one privilege
  readOne(id: number): Observable<Privilege> {
    return this.http.get<Privilege>(`${this.globals.server}privileges/${id}`).pipe(
      // tap(_ => console.log(`readOne privilege ${id} `)),
      catchError(this.handleError<Privilege>(`readOne Privilege ${id} `))
    );
  }

  // Count privileges
  count(): Observable<Privilege[]> {
    return this.http.get<Privilege[]>(`${this.globals.server}privileges/count`).pipe(
      // tap(_ => console.log('count privileges')),
      catchError(this.handleError('count Privilege', []))
    );
  }

  // Create privilege
  create(o: Privilege): Observable<Privilege> {
    return this.http.post<Privilege>(`${this.globals.server}privileges`, o, httpOptions).pipe(
      // tap(_ => console.log('create privilege')),
      catchError(this.handleError<Privilege>('create Privilege'))
    );
  }

  // Update privilege
  update(id: number, o: Privilege): Observable<any> {
    return this.http.put(`${this.globals.server}privileges/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update privilege ${id} `)),
      catchError(this.handleError<any>(`update Privilege ${id} `))
    );
  }

  // Delete privilege
  delete(id: number): Observable<Privilege> {
    return this.http.delete<Privilege>(`${this.globals.server}privileges/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete privilege ${id} `)),
      catchError(this.handleError<Privilege>(`delete Privilege ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
