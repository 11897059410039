// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Config } from 'app/crud/system/config/config';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all configs
  readAll(sort: string): Observable<Page<Config>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of configs
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Config>> {
    let url = `${this.globals.server}configs?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Config>>(url).pipe(
      // tap(_ => console.log('read configs')),
      catchError(this.handleError<Page<Config>>('read Config'))
    );
  }

  // Read one config
  readOne(code: string): Observable<Config> {
    return this.http.get<Config>(`${this.globals.server}configs/${code}`).pipe(
      // tap(_ => console.log(`readOne config ${code} `)),
      catchError(this.handleError<Config>(`readOne Config ${code} `))
    );
  }

  // Count configs
  count(): Observable<Config[]> {
    return this.http.get<Config[]>(`${this.globals.server}configs/count`).pipe(
      // tap(_ => console.log('count configs')),
      catchError(this.handleError('count Config', []))
    );
  }

  // Create config
  create(o: Config): Observable<Config> {
    return this.http.post<Config>(`${this.globals.server}configs`, o, httpOptions).pipe(
      // tap(_ => console.log('create config')),
      catchError(this.handleError<Config>('create Config'))
    );
  }

  // Update config
  update(code: string, o: Config): Observable<any> {
    return this.http.put(`${this.globals.server}configs/${code}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update config ${code} `)),
      catchError(this.handleError<any>(`update Config ${code} `))
    );
  }

  // Delete config
  delete(code: string): Observable<Config> {
    return this.http.delete<Config>(`${this.globals.server}configs/${code}`, httpOptions).pipe(
      // tap(_ => console.log(`delete config ${code} `)),
      catchError(this.handleError<Config>(`delete Config ${code} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
