// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Agent } from 'app/crud/sales/agent/agent';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class AgentService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all agents
  readAll(sort: string): Observable<Page<Agent>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of agents
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Agent>> {
    let url = `${this.globals.server}agents?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Agent>>(url).pipe(
      // tap(_ => console.log('read agents')),
      catchError(this.handleError<Page<Agent>>('read Agent'))
    );
  }

  // Read one agent
  readOne(id: number): Observable<Agent> {
    return this.http.get<Agent>(`${this.globals.server}agents/${id}`).pipe(
      // tap(_ => console.log(`readOne agent ${id} `)),
      catchError(this.handleError<Agent>(`readOne Agent ${id} `))
    );
  }

  // Count agents
  count(): Observable<Agent[]> {
    return this.http.get<Agent[]>(`${this.globals.server}agents/count`).pipe(
      // tap(_ => console.log('count agents')),
      catchError(this.handleError('count Agent', []))
    );
  }

  // Create agent
  create(o: Agent): Observable<Agent> {
    return this.http.post<Agent>(`${this.globals.server}agents`, o, httpOptions).pipe(
      // tap(_ => console.log('create agent')),
      catchError(this.handleError<Agent>('create Agent'))
    );
  }

  // Update agent
  update(id: number, o: Agent): Observable<any> {
    return this.http.put(`${this.globals.server}agents/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update agent ${id} `)),
      catchError(this.handleError<any>(`update Agent ${id} `))
    );
  }

  // Delete agent
  delete(id: number): Observable<Agent> {
    return this.http.delete<Agent>(`${this.globals.server}agents/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete agent ${id} `)),
      catchError(this.handleError<Agent>(`delete Agent ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
