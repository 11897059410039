import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { SalecontactService } from 'app/crud/sales/salecontact/salecontact.service';
import { Salecontact } from 'app/crud/sales/salecontact/salecontact';

import { ContacttypeService } from 'app/crud/sales/contacttype/contacttype.service';
import { Contacttype } from 'app/crud/sales/contacttype/contacttype';
import { SaleService } from 'app/crud/sales/sale/sale.service';
import { Sale } from 'app/crud/sales/sale/sale';
import { SourceService } from 'app/crud/sales/source/source.service';
import { Source } from 'app/crud/sales/source/source';
import { TimeframeService } from 'app/crud/sales/timeframe/timeframe.service';
import { Timeframe } from 'app/crud/sales/timeframe/timeframe';
import { WhyService } from 'app/crud/sales/why/why.service';
import { Why } from 'app/crud/sales/why/why';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-salecontact-sale-form',
  templateUrl: './salecontact-sale-form.component.html',
})
export class SalecontactSaleFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  contacttypeoptions: Contacttype[] = [];
  sourceoptions: Source[] = [];
  whyoptions: Why[] = [];
  timeframeoptions: Timeframe[] = [];

  // Form
  salecontactForm = this.fb.group({
    // Fields
    email: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    contacttype: [{ value: '', disabled: false }, [Validators.required, ]],
    crmid: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(8), ]],
    source: [{ value: '', disabled: false }, []],
    confirm: [{ value: false, disabled: false }],
    rgpd: [{ value: false, disabled: false }],
    name: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(50), ]],
    phone: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(20), ]],
    address: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    zip: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(5), ]],
    city: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    comments: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(2000), ]],
    why: [{ value: '', disabled: false }, []],
    timeframe: [{ value: '', disabled: false }, []],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Salecontact = new Salecontact();

  // Loading indicators
  isLoading = false;

  // Components constructor
  constructor(
    private apiContacttype: ContacttypeService,
    private apiSale: SaleService,
    private apiSource: SourceService,
    private apiTimeframe: TimeframeService,
    private apiWhy: WhyService,
    private api: SalecontactService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Salecontact();
    this.data.contacttype = [];
    this.data.source = new Source();
    this.data.why = new Why();
    this.data.timeframe = new Timeframe();

    // Set the master relation field
    this.data.sale = new Sale();
    this.data.sale.id = this.route.snapshot.params.sale;
    this.mapDataToForm(this.data, this.salecontactForm);

    // Load contacttype lookup
    this.loadContacttype();

    // Load source lookup
    this.loadSource();

    // Load why lookup
    this.loadWhy();

    // Load timeframe lookup
    this.loadTimeframe();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.salecontactForm.controls).forEach(key => {
        this.salecontactForm.get(key).disable();
      });
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: Salecontact, form: FormGroup) {
    form.get('email').setValue(data.email);
    if (data.contacttype) { form.get('contacttype').setValue(data.contacttype.map(x => x.id)); } else { data.contacttype = []; }
    form.get('crmid').setValue(data.crmid);
    if (data.source) { form.get('source').setValue(data.source.id); } else { data.source = new Source(); }
    form.get('confirm').setValue(data.confirm);
    form.get('rgpd').setValue(data.rgpd);
    form.get('name').setValue(data.name);
    form.get('phone').setValue(data.phone);
    form.get('address').setValue(data.address);
    form.get('zip').setValue(data.zip);
    form.get('city').setValue(data.city);
    form.get('comments').setValue(data.comments);
    if (data.why) { form.get('why').setValue(data.why.id); } else { data.why = new Why(); }
    if (data.timeframe) { form.get('timeframe').setValue(data.timeframe.id); } else { data.timeframe = new Timeframe(); }
  }

  mapFormToData(form: FormGroup, data: Salecontact) {
    data.email = form.get('email').value;
    if (form.get('contacttype').value) { data.contacttype = form.get('contacttype').value.map(x => ({id: x})); }
    data.crmid = form.get('crmid').value;
    if (!form.get('source').value) { data.source = null; } else { data.source.id = form.get('source').value; }
    data.confirm = form.get('confirm').value;
    data.rgpd = form.get('rgpd').value;
    data.name = form.get('name').value;
    data.phone = form.get('phone').value;
    data.address = form.get('address').value;
    data.zip = form.get('zip').value;
    data.city = form.get('city').value;
    data.comments = form.get('comments').value;
    if (!form.get('why').value) { data.why = null; } else { data.why.id = form.get('why').value; }
    if (!form.get('timeframe').value) { data.timeframe = null; } else { data.timeframe.id = form.get('timeframe').value; }
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.salecontactForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'SALECONTACT', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.salecontactForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'SALECONTACT');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'SALECONTACT', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.salecontactForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'SALECONTACT');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'SALECONTACT', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.SALECONTACT');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'SALECONTACT');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'SALECONTACT', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.salecontactForm, name);
  }

  // Load contacttype lookup
  loadContacttype() {
    this.apiContacttype.readAll('+name').subscribe(
      res => {
        this.contacttypeoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load source lookup
  loadSource() {
    this.apiSource.readAll('+name').subscribe(
      res => {
        this.sourceoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load why lookup
  loadWhy() {
    this.apiWhy.readAll('+name').subscribe(
      res => {
        this.whyoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load timeframe lookup
  loadTimeframe() {
    this.apiTimeframe.readAll('+name').subscribe(
      res => {
        this.timeframeoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
