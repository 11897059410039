// Angular common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap';

// Common modules
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';

// Crud components
import { EntryTableComponent } from 'app/crud/sales/entry/entry-table.component';
import { EntryFormComponent } from 'app/crud/sales/entry/entry-form.component';
import { ContentTableComponent } from 'app/crud/sales/content/content-table.component';
import { ContentFormComponent } from 'app/crud/sales/content/content-form.component';
import { ImageTableComponent } from 'app/crud/sales/image/image-table.component';
import { ImageFormComponent } from 'app/crud/sales/image/image-form.component';
import { SaleTableComponent } from 'app/crud/sales/sale/sale-table.component';
import { SaleFormComponent } from 'app/crud/sales/sale/sale-form.component';
import { SalecontactSaleTableComponent } from 'app/crud/sales/salecontact/salecontact-sale-table.component';
import { SalecontactSaleFormComponent } from 'app/crud/sales/salecontact/salecontact-sale-form.component';
import { SaledocumentSaleTableComponent } from 'app/crud/sales/saledocument/saledocument-sale-table.component';
import { SaledocumentSaleFormComponent } from 'app/crud/sales/saledocument/saledocument-sale-form.component';
import { SalevaluationSaleTableComponent } from 'app/crud/sales/salevaluation/salevaluation-sale-table.component';
import { SalevaluationSaleFormComponent } from 'app/crud/sales/salevaluation/salevaluation-sale-form.component';
import { SaletaskTableComponent } from 'app/crud/sales/saletask/saletask-table.component';
import { SaletaskFormComponent } from 'app/crud/sales/saletask/saletask-form.component';
import { SaletaskSaleTableComponent } from 'app/crud/sales/saletask/saletask-sale-table.component';
import { SaletaskSaleFormComponent } from 'app/crud/sales/saletask/saletask-sale-form.component';
import { PurchaseTableComponent } from 'app/crud/sales/purchase/purchase-table.component';
import { PurchaseFormComponent } from 'app/crud/sales/purchase/purchase-form.component';
import { PurchasedocumentPurchaseTableComponent } from 'app/crud/sales/purchasedocument/purchasedocument-purchase-table.component';
import { PurchasedocumentPurchaseFormComponent } from 'app/crud/sales/purchasedocument/purchasedocument-purchase-form.component';
import { PurchasetaskTableComponent } from 'app/crud/sales/purchasetask/purchasetask-table.component';
import { PurchasetaskFormComponent } from 'app/crud/sales/purchasetask/purchasetask-form.component';
import { PurchasetaskPurchaseTableComponent } from 'app/crud/sales/purchasetask/purchasetask-purchase-table.component';
import { PurchasetaskPurchaseFormComponent } from 'app/crud/sales/purchasetask/purchasetask-purchase-form.component';
import { CommandTableComponent } from 'app/crud/sales/command/command-table.component';
import { CommandFormComponent } from 'app/crud/sales/command/command-form.component';
import { AgentTableComponent } from 'app/crud/sales/agent/agent-table.component';
import { AgentFormComponent } from 'app/crud/sales/agent/agent-form.component';
import { HometypeTableComponent } from 'app/crud/sales/hometype/hometype-table.component';
import { HometypeFormComponent } from 'app/crud/sales/hometype/hometype-form.component';
import { DoctypeTableComponent } from 'app/crud/sales/doctype/doctype-table.component';
import { DoctypeFormComponent } from 'app/crud/sales/doctype/doctype-form.component';
import { TasktypeTableComponent } from 'app/crud/sales/tasktype/tasktype-table.component';
import { TasktypeFormComponent } from 'app/crud/sales/tasktype/tasktype-form.component';
import { ContacttypeTableComponent } from 'app/crud/sales/contacttype/contacttype-table.component';
import { ContacttypeFormComponent } from 'app/crud/sales/contacttype/contacttype-form.component';
import { SalestatusTableComponent } from 'app/crud/sales/salestatus/salestatus-table.component';
import { SalestatusFormComponent } from 'app/crud/sales/salestatus/salestatus-form.component';
import { PurchasestatusTableComponent } from 'app/crud/sales/purchasestatus/purchasestatus-table.component';
import { PurchasestatusFormComponent } from 'app/crud/sales/purchasestatus/purchasestatus-form.component';
import { SourceTableComponent } from 'app/crud/sales/source/source-table.component';
import { SourceFormComponent } from 'app/crud/sales/source/source-form.component';
import { ZoneTableComponent } from 'app/crud/sales/zone/zone-table.component';
import { ZoneFormComponent } from 'app/crud/sales/zone/zone-form.component';
import { OrientationTableComponent } from 'app/crud/sales/orientation/orientation-table.component';
import { OrientationFormComponent } from 'app/crud/sales/orientation/orientation-form.component';
import { WhyTableComponent } from 'app/crud/sales/why/why-table.component';
import { WhyFormComponent } from 'app/crud/sales/why/why-form.component';
import { TimeframeTableComponent } from 'app/crud/sales/timeframe/timeframe-table.component';
import { TimeframeFormComponent } from 'app/crud/sales/timeframe/timeframe-form.component';
import { StatusTableComponent } from 'app/crud/sales/status/status-table.component';
import { StatusFormComponent } from 'app/crud/sales/status/status-form.component';
import { TagTableComponent } from 'app/crud/sales/tag/tag-table.component';
import { TagFormComponent } from 'app/crud/sales/tag/tag-form.component';
import { PrincipalTableComponent } from 'app/crud/system/principal/principal-table.component';
import { PrincipalFormComponent } from 'app/crud/system/principal/principal-form.component';
import { PrivilegeRoleTableComponent } from 'app/crud/system/privilege/privilege-role-table.component';
import { PrivilegeRoleFormComponent } from 'app/crud/system/privilege/privilege-role-form.component';
import { GroupTableComponent } from 'app/crud/system/group/group-table.component';
import { GroupFormComponent } from 'app/crud/system/group/group-form.component';
import { RoleTableComponent } from 'app/crud/system/role/role-table.component';
import { RoleFormComponent } from 'app/crud/system/role/role-form.component';
import { ConfigTableComponent } from 'app/crud/system/config/config-table.component';
import { ConfigFormComponent } from 'app/crud/system/config/config-form.component';

@NgModule({
  declarations: [
	EntryTableComponent,
	EntryFormComponent,
	ContentTableComponent,
	ContentFormComponent,
	ImageTableComponent,
	ImageFormComponent,
	SaleTableComponent,
	SaleFormComponent,
	SalecontactSaleTableComponent,
	SalecontactSaleFormComponent,
	SaledocumentSaleTableComponent,
	SaledocumentSaleFormComponent,
	SalevaluationSaleTableComponent,
	SalevaluationSaleFormComponent,
	SaletaskTableComponent,
	SaletaskFormComponent,
	SaletaskSaleTableComponent,
	SaletaskSaleFormComponent,
	PurchaseTableComponent,
	PurchaseFormComponent,
	PurchasedocumentPurchaseTableComponent,
	PurchasedocumentPurchaseFormComponent,
	PurchasetaskTableComponent,
	PurchasetaskFormComponent,
	PurchasetaskPurchaseTableComponent,
	PurchasetaskPurchaseFormComponent,
	CommandTableComponent,
	CommandFormComponent,
	AgentTableComponent,
	AgentFormComponent,
	HometypeTableComponent,
	HometypeFormComponent,
	DoctypeTableComponent,
	DoctypeFormComponent,
	TasktypeTableComponent,
	TasktypeFormComponent,
	ContacttypeTableComponent,
	ContacttypeFormComponent,
	SalestatusTableComponent,
	SalestatusFormComponent,
	PurchasestatusTableComponent,
	PurchasestatusFormComponent,
	SourceTableComponent,
	SourceFormComponent,
	ZoneTableComponent,
	ZoneFormComponent,
	OrientationTableComponent,
	OrientationFormComponent,
	WhyTableComponent,
	WhyFormComponent,
	TimeframeTableComponent,
	TimeframeFormComponent,
	StatusTableComponent,
	StatusFormComponent,
	TagTableComponent,
	TagFormComponent,
	PrincipalTableComponent,
	PrincipalFormComponent,
	PrivilegeRoleTableComponent,
	PrivilegeRoleFormComponent,
	GroupTableComponent,
	GroupFormComponent,
	RoleTableComponent,
	RoleFormComponent,
	ConfigTableComponent,
	ConfigFormComponent,
  ],
  imports: [
	CommonModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	NgSelectModule,
	LayoutModule,
	SharedModule,
	BsDatepickerModule
  ],
  providers: [
  ]
})
export class CrudModule { }
