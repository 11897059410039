import { Audit } from 'app/shared/model/audit';

// Command class
export class Command {

  // Key
  instruction: string;

  // Fields
  run: string;

  // Audit
  audit : Audit = new Audit();
}

