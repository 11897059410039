import { Permission } from 'app/shared/model/permission';
export class Login {
    id = '';                    // User id or "sub" claim
    name = '';                  // User name
    principal = '';             // Internal user name
    logged = false;             // Logged in or not
    type = '';                  // Type of login: 'internal' or 'oidc'
    issuer = '';                // Issuer of the 'oidc' login
    state = '';                 // State, usually the return URL
    code = '';                  // Auth code to get access token
    token = '';                 // Id Token
    accessToken = '';           // Access Token
    refreshToken = '';          // Refresh token, to refresh the access or id token
    decoded: any;               // Decoded Id or Access Token
    roles: string[];            // User roles
    permissions: Permission[];  // User privileges
}
