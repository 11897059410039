import { Role } from 'app/crud/system/role/role';
import { Audit } from 'app/shared/model/audit';

// Privilege class
export class Privilege {

  // Key
  id: number;

  // Fields
  role: Role; // = new Role();
  resource: string;
  action: string;

  // Audit
  audit : Audit = new Audit();
}

