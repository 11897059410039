import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { SessionService } from 'app/shared/services/session.service';

@Pipe({
    name: 'localNumber',
    pure: false
})
export class LocalNumberPipe implements PipeTransform {

    constructor(private session: SessionService) { }

    transform(value: any, format: string) {
        // Empty value, return blank
        if (!value) { return ''; }

        // Get format
        let decimals = 2;
        let grouping = true;
        const str: string[] = format.split('-');
        if (str.length > 0) { decimals = parseInt(str[0], 10); }
        if (str.length > 1) { grouping = (str[1] === 'true'); }

        // Formatter
        const fmt = new Intl.NumberFormat(this.session.lang.code, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            useGrouping: grouping
        });

        // Format output
        let result = value;
        result = fmt.format(value);
        return result;
    }
}

@Pipe({
    name: 'localDate',
    pure: false
})
export class LocalDatePipe implements PipeTransform {

    constructor(private session: SessionService) { }

    transform(value: any, format: string) {
        // Empty value, return blank
        if (!value) { return ''; }

        // Default date format, long
        if (!format) { format = 'L'; }

        // Set locale
        moment.locale(this.session.lang.code);

        // Parse date
        const d = moment.utc(value);

        // Format output
        let result = value;
        if (d.isValid()) {
          result =  d.local().format(format);
        }
        return result;
    }
}
