<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.CONTENT'|translate" [optional]="'LABEL.ENTITY.CONTENT.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="contentForm" focus>

    <!-- Tabbed panel-->
    <div class="nav-tabs-custom">

      <!-- Tabs labels -->
      <ul class="nav nav-tabs">
        <li class="pull-right header">{{ 'LABEL.ENTITY.CONTENT'|translate }}</li>
        <li class="active">
          <a href="#tabpost" data-toggle="tab">{{ 'LABEL.TAB.CONTENT.POST'|translate }}</a>
        </li>
        <li>
          <a href="#tabcontent" data-toggle="tab">{{ 'LABEL.TAB.CONTENT.CONTENT'|translate }}</a>
        </li>
      </ul> <!-- end Tabbed panel -->

      <!-- Tabs content -->
      <div class="tab-content">

      <!-- Tab post -->
      <div class="active tab-pane" id="tabpost">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.CONTENT.URL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('contenttype')">
                <label class="control-label" for="contenttype">
                  {{ 'LABEL.FIELD.CONTENT.CONTENTTYPE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="contenttype"
                  clearable="false"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.CONTENTTYPE':'')|translate }}"
                  style="width: 100%;">
                  <ng-option *ngFor="let item of contenttypeoptions" value="{{ item|uppercase }}">{{ 'ENUM.CONTENTTYPE.'+item|translate }}</ng-option>
                </ng-select>
                <span class="help-block">{{err('contenttype')?.msg|translate:err('contenttype')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('url')">
                <label class="control-label" for="url">
                  {{ 'LABEL.FIELD.CONTENT.URL'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="url"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.URL':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('url')?.msg|translate:err('url')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('postdate')">
                <label class="control-label" for="postdate">
                  {{ 'LABEL.FIELD.CONTENT.POSTDATE'|translate }}
                </label>
                <div class="input-group">
                <input
                  formControlName="postdate"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.POSTDATE':'')|translate }}"
                  type="text"
                  bsDatepicker
                  [bsConfig]="{containerClass:'theme-dark-blue'}"
                  #postdate="bsDatepicker"
                  class="form-control" autocomplete="off" >
                <div class="input-group-addon" (click)="postdate.toggle()"><i class="fa fa-calendar"></i></div>
                </div> <!-- End input group -->
                <span class="help-block">{{err('postdate')?.msg|translate:err('postdate')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('published')">
                <label class="control-label" for="published">
                  {{ 'LABEL.FIELD.CONTENT.PUBLISHED'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="published" id="published" 
                    type="checkbox">
                  <label for="published">{{ 'LABEL.FIELD.CONTENT.PUBLISHED'|translate }}</label>
                </div>
                <span class="help-block">{{err('published')?.msg|translate:err('published')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.CONTENT.GENERAL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-8">
              <div class="form-group" [class.has-error]="err('title')">
                <label class="control-label" for="title">
                  {{ 'LABEL.FIELD.CONTENT.TITLE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="title"
                  maxlength="100"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.TITLE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('title')?.msg|translate:err('title')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('tags')">
                <label class="control-label" for="tags">
                  {{ 'LABEL.FIELD.CONTENT.TAGS'|translate }}
                </label>
                <input
                  formControlName="tags"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.TAGS':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('tags')?.msg|translate:err('tags')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('subtitle')">
                <label class="control-label" for="subtitle">
                  {{ 'LABEL.FIELD.CONTENT.SUBTITLE'|translate }}
                </label>
                <input
                  formControlName="subtitle"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.SUBTITLE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('subtitle')?.msg|translate:err('subtitle')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-8">
              <div class="form-group" [class.has-error]="err('description')">
                <label class="control-label" for="description">
                  {{ 'LABEL.FIELD.CONTENT.DESCRIPTION'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <textarea
                  formControlName="description"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="500"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.DESCRIPTION':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('description')?.msg|translate:err('description')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('file')">
                <label class="control-label" for="file">
                  {{ 'LABEL.FIELD.CONTENT.FILE'|translate }}
                </label>
                &nbsp;<i (click)="onFileDelete('file', $event)" class="fa fa-trash"></i>
                <input *ngIf="mode!='view'"
                  (change)="onFileChange('file', $event)"
                  type="file"
                  class="form-control" autocomplete="off" style="margin-bottom:10px;">
                <div *ngIf="mode!='add' && !data?.fileFile">
                  <div *ngIf="data?.filetype?.startsWith('image/')" style="margin-bottom:10px;">
                    <!--<img width="120px" src="data:{{data.filetype}};base64,{{data.fileBytes}}">-->
                    <img [hidden]="!fileLoaded" (load)="fileLoaded=true" width="120px" [src]="globals.server + 'contents/' + data.id + '/file' | secure | async">
                  </div>
                  <!--<a *ngIf="data?.filetype" href="{{ globals.server }}contents/{{ data.id }}/file" type="{{ data.filetype }}">-->
                  <a *ngIf="data?.filetype" target="_blank" [href]="globals.server + 'contents/' + data.id + '/file' | secure | async" type="{{ data.filetype }}">
                    <i [class]="'fa fa-lg ' + mimeIcon(data?.filetype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                  </a>
                </div>
                <span class="help-block">{{err('file')?.msg|translate:err('file')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.CONTENT.AUTHOR'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('author')">
                <label class="control-label" for="author">
                  {{ 'LABEL.FIELD.CONTENT.AUTHOR'|translate }}
                </label>
                <input
                  formControlName="author"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.AUTHOR':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('author')?.msg|translate:err('author')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('avatar')">
                <label class="control-label" for="avatar">
                  {{ 'LABEL.FIELD.CONTENT.AVATAR'|translate }}
                </label>
                <input
                  formControlName="avatar"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.AVATAR':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('avatar')?.msg|translate:err('avatar')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-8">
              <div class="form-group" [class.has-error]="err('bio')">
                <label class="control-label" for="bio">
                  {{ 'LABEL.FIELD.CONTENT.BIO'|translate }}
                </label>
                <input
                  formControlName="bio"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.BIO':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('bio')?.msg|translate:err('bio')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

      </div> <!-- end Tab -->
      <!-- Tab content -->
      <div class="tab-pane" id="tabcontent">

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('content')">
                <label class="control-label" for="content">
                  {{ 'LABEL.FIELD.CONTENT.CONTENT'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <textarea
                  formControlName="content"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="8000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.CONTENT.CONTENT':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('content')?.msg|translate:err('content')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

      </div> <!-- end Tab -->
<!-- Button bar -->
<div class="box-footer">
  <div class="btn-grp">
    <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
    <button [disabled]="contentForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:CONTENT')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
    <button [disabled]="contentForm.invalid || contentForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:CONTENT')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
    <button *ngIf="mode=='edit' && auth.canShow('DELETE:CONTENT')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
  </div>
</div> <!-- end Button bar -->
      </div> <!-- end Tabs content -->

    </div> <!-- end Tabbed panel -->


    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
