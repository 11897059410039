<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.PURCHASE'|translate" [optional]="'LABEL.ENTITY.PURCHASE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="purchaseForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.PURCHASE'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.PURCHASE.STATUS'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('purchasestatus')">
                <label class="control-label" for="purchasestatus">
                  {{ 'LABEL.FIELD.PURCHASE.PURCHASESTATUS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="purchasestatus"
                  clearable="false"
                  [items]="purchasestatusoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.PURCHASESTATUS':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('purchasestatus')?.msg|translate:err('purchasestatus')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('statusdate')">
                <label class="control-label" for="statusdate">
                  {{ 'LABEL.FIELD.PURCHASE.STATUSDATE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <div class="input-group">
                <input
                  formControlName="statusdate"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.STATUSDATE':'')|translate }}"
                  type="text"
                  bsDatepicker
                  [bsConfig]="{containerClass:'theme-dark-blue'}"
                  #statusdate="bsDatepicker"
                  class="form-control" autocomplete="off" >
                <div class="input-group-addon" (click)="statusdate.toggle()"><i class="fa fa-calendar"></i></div>
                </div> <!-- End input group -->
                <span class="help-block">{{err('statusdate')?.msg|translate:err('statusdate')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('agent')">
                <label class="control-label" for="agent">
                  {{ 'LABEL.FIELD.PURCHASE.AGENT'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="agent"
                  clearable="false"
                  [items]="agentoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.AGENT':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('agent')?.msg|translate:err('agent')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('source')">
                <label class="control-label" for="source">
                  {{ 'LABEL.FIELD.PURCHASE.SOURCE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="source"
                  clearable="false"
                  [items]="sourceoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.SOURCE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('source')?.msg|translate:err('source')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('comments')">
                <label class="control-label" for="comments">
                  {{ 'LABEL.FIELD.PURCHASE.COMMENTS'|translate }}
                </label>
                <textarea
                  formControlName="comments"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.COMMENTS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('tags')">
                <label class="control-label" for="tags">
                  {{ 'LABEL.FIELD.PURCHASE.TAGS'|translate }}
                </label>
                <ng-select 
                  formControlName="tags"
                  multiple="true"
                  [items]="tagsoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.TAGS':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('tags')?.msg|translate:err('tags')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.PURCHASE.CONTACT'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('name')">
                <label class="control-label" for="name">
                  {{ 'LABEL.FIELD.PURCHASE.NAME'|translate }}
                </label>
                <input
                  formControlName="name"
                  maxlength="100"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.NAME':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('email')">
                <label class="control-label" for="email">
                  {{ 'LABEL.FIELD.PURCHASE.EMAIL'|translate }}
                </label>
                <input
                  formControlName="email"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.EMAIL':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('email')?.msg|translate:err('email')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('phone')">
                <label class="control-label" for="phone">
                  {{ 'LABEL.FIELD.PURCHASE.PHONE'|translate }}
                </label>
                <input
                  formControlName="phone"
                  maxlength="20"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.PHONE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('phone')?.msg|translate:err('phone')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('address')">
                <label class="control-label" for="address">
                  {{ 'LABEL.FIELD.PURCHASE.ADDRESS'|translate }}
                </label>
                <input
                  formControlName="address"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.ADDRESS':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('zip')">
                <label class="control-label" for="zip">
                  {{ 'LABEL.FIELD.PURCHASE.ZIP'|translate }}
                </label>
                <input
                  formControlName="zip"
                  maxlength="5"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.ZIP':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('zip')?.msg|translate:err('zip')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('zone')">
                <label class="control-label" for="zone">
                  {{ 'LABEL.FIELD.PURCHASE.ZONE'|translate }}
                </label>
                <ng-select 
                  formControlName="zone"
                  multiple="true"
                  [items]="zoneoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.ZONE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('zone')?.msg|translate:err('zone')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('lat')">
                <label class="control-label" for="lat">
                  {{ 'LABEL.FIELD.PURCHASE.LAT'|translate }}
                </label>
                <input
                  formControlName="lat"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.LAT':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('lat')?.msg|translate:err('lat')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('lon')">
                <label class="control-label" for="lon">
                  {{ 'LABEL.FIELD.PURCHASE.LON'|translate }}
                </label>
                <input
                  formControlName="lon"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.LON':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('lon')?.msg|translate:err('lon')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.PURCHASE.FEATURES'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('hometype')">
                <label class="control-label" for="hometype">
                  {{ 'LABEL.FIELD.PURCHASE.HOMETYPE'|translate }}
                </label>
                <ng-select 
                  formControlName="hometype"
                  [items]="hometypeoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.HOMETYPE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('hometype')?.msg|translate:err('hometype')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('area')">
                <label class="control-label" for="area">
                  {{ 'LABEL.FIELD.PURCHASE.AREA'|translate }}
                </label>
                <input
                  formControlName="area"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.AREA':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('area')?.msg|translate:err('area')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('rooms')">
                <label class="control-label" for="rooms">
                  {{ 'LABEL.FIELD.PURCHASE.ROOMS'|translate }}
                </label>
                <input
                  formControlName="rooms"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.ROOMS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('rooms')?.msg|translate:err('rooms')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('baths')">
                <label class="control-label" for="baths">
                  {{ 'LABEL.FIELD.PURCHASE.BATHS'|translate }}
                </label>
                <input
                  formControlName="baths"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.BATHS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('baths')?.msg|translate:err('baths')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('orientation')">
                <label class="control-label" for="orientation">
                  {{ 'LABEL.FIELD.PURCHASE.ORIENTATION'|translate }}
                </label>
                <ng-select 
                  formControlName="orientation"
                  [items]="orientationoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.ORIENTATION':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('orientation')?.msg|translate:err('orientation')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('terrace')">
                <label class="control-label" for="terrace">
                  {{ 'LABEL.FIELD.PURCHASE.TERRACE'|translate }}
                </label>
                <input
                  formControlName="terrace"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.TERRACE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('terrace')?.msg|translate:err('terrace')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('garage')">
                <label class="control-label" for="garage">
                  {{ 'LABEL.FIELD.PURCHASE.GARAGE'|translate }}
                </label>
                <input
                  formControlName="garage"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.GARAGE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('garage')?.msg|translate:err('garage')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('exterior')">
                <label class="control-label" for="exterior">
                  {{ 'LABEL.FIELD.PURCHASE.EXTERIOR'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="exterior" id="exterior" 
                    type="checkbox">
                  <label for="exterior">{{ 'LABEL.FIELD.PURCHASE.EXTERIOR'|translate }}</label>
                </div>
                <span class="help-block">{{err('exterior')?.msg|translate:err('exterior')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('elevator')">
                <label class="control-label" for="elevator">
                  {{ 'LABEL.FIELD.PURCHASE.ELEVATOR'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="elevator" id="elevator" 
                    type="checkbox">
                  <label for="elevator">{{ 'LABEL.FIELD.PURCHASE.ELEVATOR'|translate }}</label>
                </div>
                <span class="help-block">{{err('elevator')?.msg|translate:err('elevator')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('pool')">
                <label class="control-label" for="pool">
                  {{ 'LABEL.FIELD.PURCHASE.POOL'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="pool" id="pool" 
                    type="checkbox">
                  <label for="pool">{{ 'LABEL.FIELD.PURCHASE.POOL'|translate }}</label>
                </div>
                <span class="help-block">{{err('pool')?.msg|translate:err('pool')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('description')">
                <label class="control-label" for="description">
                  {{ 'LABEL.FIELD.PURCHASE.DESCRIPTION'|translate }}
                </label>
                <textarea
                  formControlName="description"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.DESCRIPTION':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('description')?.msg|translate:err('description')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('details')">
                <label class="control-label" for="details">
                  {{ 'LABEL.FIELD.PURCHASE.DETAILS'|translate }}
                </label>
                <textarea
                  formControlName="details"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASE.DETAILS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="purchaseForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:PURCHASE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="purchaseForm.invalid || purchaseForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:PURCHASE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:PURCHASE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->


    </form>
        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs labels -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabPurchasedocument" data-toggle="tab">{{ 'LABEL.ENTITY.PURCHASEDOCUMENT'|translate }}</a>
            </li>
            <li>
              <a href="#tabPurchasetask" data-toggle="tab">{{ 'LABEL.ENTITY.PURCHASETASK'|translate }}</a>
            </li>
          </ul>

            <!-- Tabs content -->
            <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabPurchasedocument">
              <div class="box-body">
              <app-purchasedocument-purchase-table *ngIf="mode!='add' && data.id" 
                parent="purchases" 
                [value]="data.id">
              </app-purchasedocument-purchase-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabPurchasetask">
              <div class="box-body">
              <app-purchasetask-purchase-table *ngIf="mode!='add' && data.id" 
                parent="purchases" 
                [value]="data.id">
              </app-purchasetask-purchase-table>
              </div>
            </div> <!-- end tab -->
    
            </div> <!-- end tab-content -->

        </div>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
