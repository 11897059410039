// Angular imports
import { Component, OnInit  } from '@angular/core';
import { FormBuilder } from '@angular/forms';

// Leaflet
import * as L from 'leaflet';
import 'leaflet-draw';
import { Map, FeatureGroup, tileLayer, featureGroup, marker, circleMarker } from 'leaflet';

// Custom
import { GeoService } from './geo.service';

// Constants
const STROKE_COLOR = ['#ff8080', '#8080ff'];
const FILL_COLOR   = ['#ff0000', '#0000ff'];

@Component({
  selector: 'app-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.css']
})
export class GeoComponent implements OnInit {

  // Select fields
  public clientoptions = [
    { id: '0', name: 'Compradores'},
    { id: '1', name: 'Vendedores'}
  ];

  // Form
  public geoForm = this.fb.group({
    client: [{ value: '0', disabled: false }, []],
  });

  // Initialize vars
  private map: Map;
  private searchGroup: FeatureGroup;
  private markersGroup: FeatureGroup;
  
  // Init
  private lat = 40.45;
  private lon = -3.69;
  private zoom = 12;

  // Data
  public data;
  public checkedAll = null;

  // Components constructor
  constructor(
    private api: GeoService,
    private fb: FormBuilder
    ) {}

  // Component initialization
  ngOnInit() {
    this.initMap();
  }

  initMap(): void {

    // Set up map
    this.map = new Map('map', { center: [ this.lat, this.lon ], zoom: this.zoom });

    // Tiles
    const tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 10,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);

    // Markers group
    this.markersGroup = new featureGroup();
    this.map.addLayer(this.markersGroup);

    // Search group
    this.searchGroup = featureGroup();
    this.map.addLayer(this.searchGroup);

    // Initialize the draw control
    var drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        marker: false,
        circlemarker: false
      }
    });
    this.map.addControl(drawControl);

    // Handlers
    this.map.on("draw:drawstart", e => this.drawstart(e));
    this.map.on('draw:created', e => this.create(e));
  }

  drawstart(e): void {

    // Clear layers
    this.markersGroup.clearLayers();
    this.searchGroup.clearLayers();
  }

  create(e): void {

    // Show shape
    this.searchGroup.addLayer(e.layer);

    // Client type
    var client = parseInt(this.geoForm.get('client').value);

    // Search
    if (e.layerType == 'circle') {
      this.api.circle(client, e.layer.getLatLng().lng, e.layer.getLatLng().lat, e.layer.getRadius())
        .subscribe(
          res => {
            this.data = res;
            this.showMarkers(client, res);
          }
        );
    } else {
      this.api.geojson(client, e.layer.toGeoJSON().geometry)
        .subscribe(
          res => { 
            this.data = res;
            this.showMarkers(client, res);
          }
        );
    }
  }

  showMarkers(client, res): void {

    console.log(res);
    
    // Clear previous markers
    this.markersGroup.clearLayers();

    // Add markers to feature group
    for (const home of res) {
      home.checked = null;
      const m = circleMarker(
        [home.lat, home.lng], 
        { radius: 8, fillcolor: FILL_COLOR[client], color: STROKE_COLOR[client], weight: 2, fillOpacity: 0.7 }
      )
      m.bindPopup(home.address + '<br>' + home.reference + '<br>' + home.id + '<br>' + home.email); 
      this.markersGroup.addLayer(m);
    }

    // Add group to map and show it
    this.map.fitBounds(this.markersGroup.getBounds());
  }

  clientChange(e): void {

    // Clear list
    this.data = []
    this.checkedAll = null;
    
    // Clear layers
    this.markersGroup.clearLayers();
    this.searchGroup.clearLayers();
  }
  
  checkboxChange(e): void {

    var home = this.data.find(h => h.id == e.target.value);
    if (e.target.checked)
      home.checked = e.target.checked;
    else
      home.checked = null;
  }
  
  checkboxChangeAll(e): void {

    if (e.target.checked)
      this.checkedAll = true;
    else
      this.checkedAll = null;
    for (var home of this.data) {
      if (e.target.checked)
        home.checked = e.target.checked;
      else
        home.checked = null;
    }
  }

}
