// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Why } from 'app/crud/sales/why/why';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class WhyService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all whies
  readAll(sort: string): Observable<Page<Why>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of whies
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Why>> {
    let url = `${this.globals.server}whies?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Why>>(url).pipe(
      // tap(_ => console.log('read whies')),
      catchError(this.handleError<Page<Why>>('read Why'))
    );
  }

  // Read one why
  readOne(id: string): Observable<Why> {
    return this.http.get<Why>(`${this.globals.server}whies/${id}`).pipe(
      // tap(_ => console.log(`readOne why ${id} `)),
      catchError(this.handleError<Why>(`readOne Why ${id} `))
    );
  }

  // Count whies
  count(): Observable<Why[]> {
    return this.http.get<Why[]>(`${this.globals.server}whies/count`).pipe(
      // tap(_ => console.log('count whies')),
      catchError(this.handleError('count Why', []))
    );
  }

  // Create why
  create(o: Why): Observable<Why> {
    return this.http.post<Why>(`${this.globals.server}whies`, o, httpOptions).pipe(
      // tap(_ => console.log('create why')),
      catchError(this.handleError<Why>('create Why'))
    );
  }

  // Update why
  update(id: string, o: Why): Observable<any> {
    return this.http.put(`${this.globals.server}whies/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update why ${id} `)),
      catchError(this.handleError<any>(`update Why ${id} `))
    );
  }

  // Delete why
  delete(id: string): Observable<Why> {
    return this.http.delete<Why>(`${this.globals.server}whies/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete why ${id} `)),
      catchError(this.handleError<Why>(`delete Why ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
