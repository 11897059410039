// Angular imports
import { HttpClient } from '@angular/common/http';
import { Component, OnInit  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'app/shared/services/globals.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  // Form
  public reportForm = this.fb.group({
    ref: [{ value: '', disabled: false }, [Validators.required, ]],
  });

  // Loading
  public isLoading = false;

  // Components constructor
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private globals: Globals) {}

  // Component initialization
  ngOnInit() {
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.reportForm, name);
  }

  // Valuate
  report() {

    // Loading
    this.isLoading = true;

    // Get form values
    const ref   = this.reportForm.get('ref').value;

    // Call
    let url = 'https://alpha.arkittai.com/app/val/report?rc=' + ref;
    this.http.get(url, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        let objectURL = URL.createObjectURL(response);
        this.isLoading = false;
        window.open(objectURL, '_blank');
      });
  }  
}
