// Angular common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

// Common modules
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';

// Custom components
import { GeoComponent } from 'app/custom/geo/geo.component';
import { ValuatorComponent } from 'app/custom/valuator/valuator.component';
import { ReportComponent } from './report/report.component';

@NgModule({
  declarations: [
	GeoComponent,
	ValuatorComponent,
	ReportComponent
  ],
  imports: [
	CommonModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	NgSelectModule,
	LayoutModule,
	SharedModule
  ],
  providers: [
  ]
})
export class CustomModule { }
