// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Principal } from 'app/crud/system/principal/principal';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all principals
  readAll(sort: string): Observable<Page<Principal>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of principals
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Principal>> {
    let url = `${this.globals.server}principals?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Principal>>(url).pipe(
      // tap(_ => console.log('read principals')),
      catchError(this.handleError<Page<Principal>>('read Principal'))
    );
  }

  // Read one principal
  readOne(id: string): Observable<Principal> {
    return this.http.get<Principal>(`${this.globals.server}principals/${id}`).pipe(
      // tap(_ => console.log(`readOne principal ${id} `)),
      catchError(this.handleError<Principal>(`readOne Principal ${id} `))
    );
  }

  // Count principals
  count(): Observable<Principal[]> {
    return this.http.get<Principal[]>(`${this.globals.server}principals/count`).pipe(
      // tap(_ => console.log('count principals')),
      catchError(this.handleError('count Principal', []))
    );
  }

  // Create principal
  create(o: Principal): Observable<Principal> {
    return this.http.post<Principal>(`${this.globals.server}principals`, o, httpOptions).pipe(
      // tap(_ => console.log('create principal')),
      catchError(this.handleError<Principal>('create Principal'))
    );
  }

  // Update principal
  update(id: string, o: Principal): Observable<any> {
    return this.http.put(`${this.globals.server}principals/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update principal ${id} `)),
      catchError(this.handleError<any>(`update Principal ${id} `))
    );
  }

  // Delete principal
  delete(id: string): Observable<Principal> {
    return this.http.delete<Principal>(`${this.globals.server}principals/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete principal ${id} `)),
      catchError(this.handleError<Principal>(`delete Principal ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
