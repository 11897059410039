import { Audit } from 'app/shared/model/audit';

// Salestatus class
export class Salestatus {

  // Key
  id: string;

  // Fields
  name: string;

  // Audit
  audit : Audit = new Audit();
}

