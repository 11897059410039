<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.SALETASK'|translate" [optional]="'LABEL.ENTITY.SALETASK.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="saletaskForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.SALETASK'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('sale')">
                <label class="control-label" for="sale">
                  {{ 'LABEL.FIELD.SALETASK.SALE'|translate }}
                </label>
                <div class="input-group">
                  <input
                    formControlName="sale"
                    typeaheadOptionField="address"
                    [typeaheadMinLength]="0"
                    [typeaheadAsync]="true"
                    [typeahead]="saleoptions"
                    (typeaheadOnSelect)="saleOnSelected($event)"
                    placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALETASK.SALE':'')|translate }}"
                    class="form-control">
                    <span class="input-group-addon">
                      <i *ngIf="data.sale?.id" class="fa fa-search" [routerLink]="['/sales', data.sale.id, 'view']"></i>
                      <i *ngIf="!data.sale?.id" class="fa fa-search"></i>
                    </span>
                  </div>
                <span class="help-block">{{err('sale')?.msg|translate:err('sale')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('tasktype')">
                <label class="control-label" for="tasktype">
                  {{ 'LABEL.FIELD.SALETASK.TASKTYPE'|translate }}
                </label>
                <ng-select 
                  formControlName="tasktype"
                  [items]="tasktypeoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALETASK.TASKTYPE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('tasktype')?.msg|translate:err('tasktype')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('plandate')">
                <label class="control-label" for="plandate">
                  {{ 'LABEL.FIELD.SALETASK.PLANDATE'|translate }}
                </label>
                <div class="input-group">
                <input
                  formControlName="plandate"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALETASK.PLANDATE':'')|translate }}"
                  type="text"
                  bsDatepicker
                  [bsConfig]="{containerClass:'theme-dark-blue'}"
                  #plandate="bsDatepicker"
                  class="form-control" autocomplete="off" >
                <div class="input-group-addon" (click)="plandate.toggle()"><i class="fa fa-calendar"></i></div>
                </div> <!-- End input group -->
                <span class="help-block">{{err('plandate')?.msg|translate:err('plandate')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('donedate')">
                <label class="control-label" for="donedate">
                  {{ 'LABEL.FIELD.SALETASK.DONEDATE'|translate }}
                </label>
                <div class="input-group">
                <input
                  formControlName="donedate"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALETASK.DONEDATE':'')|translate }}"
                  type="text"
                  bsDatepicker
                  [bsConfig]="{containerClass:'theme-dark-blue'}"
                  #donedate="bsDatepicker"
                  class="form-control" autocomplete="off" >
                <div class="input-group-addon" (click)="donedate.toggle()"><i class="fa fa-calendar"></i></div>
                </div> <!-- End input group -->
                <span class="help-block">{{err('donedate')?.msg|translate:err('donedate')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('details')">
                <label class="control-label" for="details">
                  {{ 'LABEL.FIELD.SALETASK.DETAILS'|translate }}
                </label>
                <textarea
                  formControlName="details"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="4000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALETASK.DETAILS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="saletaskForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:SALETASK')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="saletaskForm.invalid || saletaskForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:SALETASK')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:SALETASK')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->


    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
