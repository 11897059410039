import { Audit } from 'app/shared/model/audit';

// Timeframe class
export class Timeframe {

  // Key
  id: string;

  // Fields
  name: string;

  // Audit
  audit : Audit = new Audit();
}

