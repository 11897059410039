// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class GeoService {

  // Constructor
  constructor(private http: HttpClient) {
  }

  // Read houses inside a circle
  circle(client, lat, lng, dist): Observable<Array<any>> {
    let url = `https://newhomers.com/app/near/${client}/${lat}/${lng}/${dist}`;
    return this.http
      .get<Array<any>>(url)
      .pipe(catchError(this.handleError<Array<any>>('read'))
    );
  }

  // Read houses inside a geo json
  geojson(client, body): Observable<Array<any>> {
    let url = `https://newhomers.com/app/near/${client}`;
    return this.http
      .post<Array<any>>(url, body)
      .pipe(catchError(this.handleError<Array<any>>('read'))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}