<!-- Pagination -->
<div *ngIf="total" class="row">

  <!-- Row shown -->
  <div class="col-sm-5">
    <div *ngIf="of() >= 0" class="dataTables_info" role="status" aria-live="polite">
      {{ 'PAGINATION.PAGE'|translate:{a:from(), b:to(), c:of()} }}
    </div>
    <div *ngIf="of() < 0" class="dataTables_info" role="status" aria-live="polite">
      {{ 'PAGINATION.SLICE'|translate:{a:from(), b:to()} }}
    </div>
  </div>

  <!-- Pagination controls -->
  <div class="col-sm-7">
    <div class="dataTables_paginate paging_simple_numbers">
      <ul class="pagination">
        <li [ngClass]="{disabled: isFirst()}" class="paginate_button previous">
          <a (click)="doFirst()">{{'PAGINATION.FIRST'|translate}}</a>
        </li>
          <li [ngClass]="{disabled: isFirst()}" class="paginate_button previous">
        <a (click)="doPrev()">{{'PAGINATION.PREV'|translate}}</a>
        </li>
        <li *ngFor="let p of showPages()" class="paginate_button" [ngClass]="{active: p == currentPage}">
          <a (click)="doPage(p)">{{p+1}}</a>
        </li>
        <li [ngClass]="{disabled: !hasNext()}" class="paginate_button next">
          <a (click)="doNext()">{{'PAGINATION.NEXT'|translate}}</a>
        </li>
        <li *ngIf="total < 999999999" [ngClass]="{disabled: isLast()}" class="paginate_button next">
          <a (click)="doLast()">{{'PAGINATION.LAST'|translate}}</a>
        </li>
      </ul>
    </div>
  </div>

</div> <!-- end Pagination -->

