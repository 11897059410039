import { Agent } from 'app/crud/sales/agent/agent';
import { Hometype } from 'app/crud/sales/hometype/hometype';
import { Orientation } from 'app/crud/sales/orientation/orientation';
import { Purchasestatus } from 'app/crud/sales/purchasestatus/purchasestatus';
import { Source } from 'app/crud/sales/source/source';
import { Tag } from 'app/crud/sales/tag/tag';
import { Zone } from 'app/crud/sales/zone/zone';
import { Audit } from 'app/shared/model/audit';

// Purchase class
export class Purchase {

  // Key
  id: number;

  // Fields
  email: string;
  name: string;
  phone: string;
  address: string;
  zip: string;
  zone: Zone[]; // = new Zone();
  lat: number;
  lon: number;
  purchasestatus: Purchasestatus; // = new Purchasestatus();
  statusdate: string;
  agent: Agent; // = new Agent();
  source: Source; // = new Source();
  description: string;
  details: string;
  comments: string;
  tags: Tag[]; // = new Tag();
  hometype: Hometype; // = new Hometype();
  area: number;
  rooms: number;
  baths: number;
  exterior: string;
  elevator: string;
  terrace: number;
  garage: number;
  pool: string;
  orientation: Orientation; // = new Orientation();

  // Audit
  audit : Audit = new Audit();
}

