import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-header-messages]',
  templateUrl: './header-messages.component.html'
})
export class HeaderMessagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
