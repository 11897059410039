import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { SalecontactService } from 'app/crud/sales/salecontact/salecontact.service';
import { Salecontact } from 'app/crud/sales/salecontact/salecontact';

import { TimeframeService } from 'app/crud/sales/timeframe/timeframe.service';
import { Timeframe } from 'app/crud/sales/timeframe/timeframe';
import { ContacttypeService } from 'app/crud/sales/contacttype/contacttype.service';
import { Contacttype } from 'app/crud/sales/contacttype/contacttype';
import { SaleService } from 'app/crud/sales/sale/sale.service';
import { Sale } from 'app/crud/sales/sale/sale';
import { SourceService } from 'app/crud/sales/source/source.service';
import { Source } from 'app/crud/sales/source/source';
import { WhyService } from 'app/crud/sales/why/why.service';
import { Why } from 'app/crud/sales/why/why';

@Component({
  selector: 'app-salecontact-sale-table',
  templateUrl: './salecontact-sale-table.component.html',
})
export class SalecontactSaleTableComponent implements OnInit {

  // Select fields
  timeframeoptions: Timeframe[] = [];
  contacttypeoptions: Contacttype[] = [];
  saleoptions: Sale[] = [];
  sourceoptions: Source[] = [];
  whyoptions: Why[] = [];

  // Parent
  @Input() parent: string;
  @Input() value: any;

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    email: '',
    contacttype_id: '',
    crmid: '',
    name: '',
    phone: '',
    why_id: '',
    why_name: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  //? sort = 'email';
  //? dir = '+';
  sort = '';
  dir = '';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Salecontact>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiTimeframe: TimeframeService,
    private apiContacttype: ContacttypeService,
    private apiSale: SaleService,
    private apiSource: SourceService,
    private apiWhy: WhyService,
    private api: SalecontactService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'SalecontactSale') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load contacttype lookup
    this.loadContacttype();

    // Load why lookup
    this.loadWhy();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.readFromSale(this.value, this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'SALECONTACT', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'SalecontactSale';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'email', this.search.email, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'contacttype.id', this.search.contacttype_id, 'contains', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'crmid', this.search.crmid, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'name', this.search.name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'phone', this.search.phone, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'why.id', this.search.why_id, 'eq', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'why.name', this.search.why_name, 'like', this.session.lang.code, 'String');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'SalecontactSale';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.email = '';
    this.search.contacttype_id = '';
    this.search.crmid = '';
    this.search.name = '';
    this.search.phone = '';
    this.search.why_id = '';
    this.search.why_name = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load contacttype lookup
  loadContacttype() {
    this.apiContacttype.readAll('').subscribe(
      res => {
        this.contacttypeoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'CONTACTTYPE', err);
      }
    );
  }

  // Load why lookup
  loadWhy() {
    this.apiWhy.readAll('').subscribe(
      res => {
        this.whyoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'WHY', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
