import { Group } from 'app/crud/system/group/group';
import { Role } from 'app/crud/system/role/role';
import { Audit } from 'app/shared/model/audit';

// Principal class
export class Principal {

  // Key
  id: string;

  // Fields
  email: string;
  name: string;
  password: string;
  role: Role[]; // = new Role();
  group: Group; // = new Group();
  provider: string;

  // Audit
  audit : Audit = new Audit();
}

