// Angular and infastructure imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Security
import { AuthService } from 'app/shared/services/auth.service';

// Layouts
import { FramedLayoutComponent } from 'app/layout/framed/framed.component';

// Crud components
import { EntryTableComponent } from 'app/crud/sales/entry/entry-table.component';
import { EntryFormComponent } from 'app/crud/sales/entry/entry-form.component';
import { ContentTableComponent } from 'app/crud/sales/content/content-table.component';
import { ContentFormComponent } from 'app/crud/sales/content/content-form.component';
import { ImageTableComponent } from 'app/crud/sales/image/image-table.component';
import { ImageFormComponent } from 'app/crud/sales/image/image-form.component';
import { SaleTableComponent } from 'app/crud/sales/sale/sale-table.component';
import { SaleFormComponent } from 'app/crud/sales/sale/sale-form.component';
import { SalecontactSaleTableComponent } from 'app/crud/sales/salecontact/salecontact-sale-table.component';
import { SalecontactSaleFormComponent } from 'app/crud/sales/salecontact/salecontact-sale-form.component';
import { SaledocumentSaleTableComponent } from 'app/crud/sales/saledocument/saledocument-sale-table.component';
import { SaledocumentSaleFormComponent } from 'app/crud/sales/saledocument/saledocument-sale-form.component';
import { SalevaluationSaleTableComponent } from 'app/crud/sales/salevaluation/salevaluation-sale-table.component';
import { SalevaluationSaleFormComponent } from 'app/crud/sales/salevaluation/salevaluation-sale-form.component';
import { SaletaskTableComponent } from 'app/crud/sales/saletask/saletask-table.component';
import { SaletaskFormComponent } from 'app/crud/sales/saletask/saletask-form.component';
import { SaletaskSaleTableComponent } from 'app/crud/sales/saletask/saletask-sale-table.component';
import { SaletaskSaleFormComponent } from 'app/crud/sales/saletask/saletask-sale-form.component';
import { PurchaseTableComponent } from 'app/crud/sales/purchase/purchase-table.component';
import { PurchaseFormComponent } from 'app/crud/sales/purchase/purchase-form.component';
import { PurchasedocumentPurchaseTableComponent } from 'app/crud/sales/purchasedocument/purchasedocument-purchase-table.component';
import { PurchasedocumentPurchaseFormComponent } from 'app/crud/sales/purchasedocument/purchasedocument-purchase-form.component';
import { PurchasetaskTableComponent } from 'app/crud/sales/purchasetask/purchasetask-table.component';
import { PurchasetaskFormComponent } from 'app/crud/sales/purchasetask/purchasetask-form.component';
import { PurchasetaskPurchaseTableComponent } from 'app/crud/sales/purchasetask/purchasetask-purchase-table.component';
import { PurchasetaskPurchaseFormComponent } from 'app/crud/sales/purchasetask/purchasetask-purchase-form.component';
import { CommandTableComponent } from 'app/crud/sales/command/command-table.component';
import { CommandFormComponent } from 'app/crud/sales/command/command-form.component';
import { AgentTableComponent } from 'app/crud/sales/agent/agent-table.component';
import { AgentFormComponent } from 'app/crud/sales/agent/agent-form.component';
import { HometypeTableComponent } from 'app/crud/sales/hometype/hometype-table.component';
import { HometypeFormComponent } from 'app/crud/sales/hometype/hometype-form.component';
import { DoctypeTableComponent } from 'app/crud/sales/doctype/doctype-table.component';
import { DoctypeFormComponent } from 'app/crud/sales/doctype/doctype-form.component';
import { TasktypeTableComponent } from 'app/crud/sales/tasktype/tasktype-table.component';
import { TasktypeFormComponent } from 'app/crud/sales/tasktype/tasktype-form.component';
import { ContacttypeTableComponent } from 'app/crud/sales/contacttype/contacttype-table.component';
import { ContacttypeFormComponent } from 'app/crud/sales/contacttype/contacttype-form.component';
import { SalestatusTableComponent } from 'app/crud/sales/salestatus/salestatus-table.component';
import { SalestatusFormComponent } from 'app/crud/sales/salestatus/salestatus-form.component';
import { PurchasestatusTableComponent } from 'app/crud/sales/purchasestatus/purchasestatus-table.component';
import { PurchasestatusFormComponent } from 'app/crud/sales/purchasestatus/purchasestatus-form.component';
import { SourceTableComponent } from 'app/crud/sales/source/source-table.component';
import { SourceFormComponent } from 'app/crud/sales/source/source-form.component';
import { ZoneTableComponent } from 'app/crud/sales/zone/zone-table.component';
import { ZoneFormComponent } from 'app/crud/sales/zone/zone-form.component';
import { OrientationTableComponent } from 'app/crud/sales/orientation/orientation-table.component';
import { OrientationFormComponent } from 'app/crud/sales/orientation/orientation-form.component';
import { WhyTableComponent } from 'app/crud/sales/why/why-table.component';
import { WhyFormComponent } from 'app/crud/sales/why/why-form.component';
import { TimeframeTableComponent } from 'app/crud/sales/timeframe/timeframe-table.component';
import { TimeframeFormComponent } from 'app/crud/sales/timeframe/timeframe-form.component';
import { StatusTableComponent } from 'app/crud/sales/status/status-table.component';
import { StatusFormComponent } from 'app/crud/sales/status/status-form.component';
import { TagTableComponent } from 'app/crud/sales/tag/tag-table.component';
import { TagFormComponent } from 'app/crud/sales/tag/tag-form.component';
import { PrincipalTableComponent } from 'app/crud/system/principal/principal-table.component';
import { PrincipalFormComponent } from 'app/crud/system/principal/principal-form.component';
import { PrivilegeRoleTableComponent } from 'app/crud/system/privilege/privilege-role-table.component';
import { PrivilegeRoleFormComponent } from 'app/crud/system/privilege/privilege-role-form.component';
import { GroupTableComponent } from 'app/crud/system/group/group-table.component';
import { GroupFormComponent } from 'app/crud/system/group/group-form.component';
import { RoleTableComponent } from 'app/crud/system/role/role-table.component';
import { RoleFormComponent } from 'app/crud/system/role/role-form.component';
import { ConfigTableComponent } from 'app/crud/system/config/config-table.component';
import { ConfigFormComponent } from 'app/crud/system/config/config-form.component';

// Custom components
import { ValuatorComponent } from 'app/custom/valuator/valuator.component';
import { ReportComponent } from 'app/custom/report/report.component';
import { GeoComponent } from 'app/custom/geo/geo.component';

const routes: Routes = [
  {
	path: '', component: FramedLayoutComponent, canActivate: [AuthService], children: [

	  { path: 'entries', canActivate: [AuthService], component: EntryTableComponent, data: { resource: 'ENTRY', mode: 'list' } },
	  { path: 'entries/add', canActivate: [AuthService], component: EntryFormComponent, data: { resource: 'ENTRY', mode: 'add' } },
	  { path: 'entries/:id/view', canActivate: [AuthService], component: EntryFormComponent, data: { resource: 'ENTRY', mode: 'view' } },
	  { path: 'entries/:id/edit', canActivate: [AuthService], component: EntryFormComponent, data: { resource: 'ENTRY', mode: 'edit' } },

	  { path: 'contents', canActivate: [AuthService], component: ContentTableComponent, data: { resource: 'CONTENT', mode: 'list' } },
	  { path: 'contents/add', canActivate: [AuthService], component: ContentFormComponent, data: { resource: 'CONTENT', mode: 'add' } },
	  { path: 'contents/:id/view', canActivate: [AuthService], component: ContentFormComponent, data: { resource: 'CONTENT', mode: 'view' } },
	  { path: 'contents/:id/edit', canActivate: [AuthService], component: ContentFormComponent, data: { resource: 'CONTENT', mode: 'edit' } },

	  { path: 'images', canActivate: [AuthService], component: ImageTableComponent, data: { resource: 'IMAGE', mode: 'list' } },
	  { path: 'images/add', canActivate: [AuthService], component: ImageFormComponent, data: { resource: 'IMAGE', mode: 'add' } },
	  { path: 'images/:url/view', canActivate: [AuthService], component: ImageFormComponent, data: { resource: 'IMAGE', mode: 'view' } },
	  { path: 'images/:url/edit', canActivate: [AuthService], component: ImageFormComponent, data: { resource: 'IMAGE', mode: 'edit' } },

	  { path: 'sales', canActivate: [AuthService], component: SaleTableComponent, data: { resource: 'SALE', mode: 'list' } },
	  { path: 'sales/add', canActivate: [AuthService], component: SaleFormComponent, data: { resource: 'SALE', mode: 'add' } },
	  { path: 'sales/:id/view', canActivate: [AuthService], component: SaleFormComponent, data: { resource: 'SALE', mode: 'view' } },
	  { path: 'sales/:id/edit', canActivate: [AuthService], component: SaleFormComponent, data: { resource: 'SALE', mode: 'edit' } },

	  { path: 'sales/:sale/salecontacts/add', canActivate: [AuthService], component: SalecontactSaleFormComponent, data: { resource: 'SALE', mode: 'add' } },
	  { path: 'sales/:sale/salecontacts/:id/view', canActivate: [AuthService], component: SalecontactSaleFormComponent, data: { resource: 'SALE', mode: 'view' } },
	  { path: 'sales/:sale/salecontacts/:id/edit', canActivate: [AuthService], component: SalecontactSaleFormComponent, data: { resource: 'SALE', mode: 'edit' } },

	  { path: 'sales/:sale/saledocuments/add', canActivate: [AuthService], component: SaledocumentSaleFormComponent, data: { resource: 'SALE', mode: 'add' } },
	  { path: 'sales/:sale/saledocuments/:id/view', canActivate: [AuthService], component: SaledocumentSaleFormComponent, data: { resource: 'SALE', mode: 'view' } },
	  { path: 'sales/:sale/saledocuments/:id/edit', canActivate: [AuthService], component: SaledocumentSaleFormComponent, data: { resource: 'SALE', mode: 'edit' } },

	  { path: 'sales/:sale/salevaluations/add', canActivate: [AuthService], component: SalevaluationSaleFormComponent, data: { resource: 'SALE', mode: 'add' } },
	  { path: 'sales/:sale/salevaluations/:id/view', canActivate: [AuthService], component: SalevaluationSaleFormComponent, data: { resource: 'SALE', mode: 'view' } },
	  { path: 'sales/:sale/salevaluations/:id/edit', canActivate: [AuthService], component: SalevaluationSaleFormComponent, data: { resource: 'SALE', mode: 'edit' } },

	  { path: 'saletasks', canActivate: [AuthService], component: SaletaskTableComponent, data: { resource: 'SALETASK', mode: 'list' } },
	  { path: 'saletasks/add', canActivate: [AuthService], component: SaletaskFormComponent, data: { resource: 'SALETASK', mode: 'add' } },
	  { path: 'saletasks/:id/view', canActivate: [AuthService], component: SaletaskFormComponent, data: { resource: 'SALETASK', mode: 'view' } },
	  { path: 'saletasks/:id/edit', canActivate: [AuthService], component: SaletaskFormComponent, data: { resource: 'SALETASK', mode: 'edit' } },

	  { path: 'sales/:sale/saletasks/add', canActivate: [AuthService], component: SaletaskSaleFormComponent, data: { resource: 'SALE', mode: 'add' } },
	  { path: 'sales/:sale/saletasks/:id/view', canActivate: [AuthService], component: SaletaskSaleFormComponent, data: { resource: 'SALE', mode: 'view' } },
	  { path: 'sales/:sale/saletasks/:id/edit', canActivate: [AuthService], component: SaletaskSaleFormComponent, data: { resource: 'SALE', mode: 'edit' } },

	  { path: 'purchases', canActivate: [AuthService], component: PurchaseTableComponent, data: { resource: 'PURCHASE', mode: 'list' } },
	  { path: 'purchases/add', canActivate: [AuthService], component: PurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'add' } },
	  { path: 'purchases/:id/view', canActivate: [AuthService], component: PurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'view' } },
	  { path: 'purchases/:id/edit', canActivate: [AuthService], component: PurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'edit' } },

	  { path: 'purchases/:purchase/purchasedocuments/add', canActivate: [AuthService], component: PurchasedocumentPurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'add' } },
	  { path: 'purchases/:purchase/purchasedocuments/:id/view', canActivate: [AuthService], component: PurchasedocumentPurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'view' } },
	  { path: 'purchases/:purchase/purchasedocuments/:id/edit', canActivate: [AuthService], component: PurchasedocumentPurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'edit' } },

	  { path: 'purchasetasks', canActivate: [AuthService], component: PurchasetaskTableComponent, data: { resource: 'PURCHASETASK', mode: 'list' } },
	  { path: 'purchasetasks/add', canActivate: [AuthService], component: PurchasetaskFormComponent, data: { resource: 'PURCHASETASK', mode: 'add' } },
	  { path: 'purchasetasks/:id/view', canActivate: [AuthService], component: PurchasetaskFormComponent, data: { resource: 'PURCHASETASK', mode: 'view' } },
	  { path: 'purchasetasks/:id/edit', canActivate: [AuthService], component: PurchasetaskFormComponent, data: { resource: 'PURCHASETASK', mode: 'edit' } },

	  { path: 'purchases/:purchase/purchasetasks/add', canActivate: [AuthService], component: PurchasetaskPurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'add' } },
	  { path: 'purchases/:purchase/purchasetasks/:id/view', canActivate: [AuthService], component: PurchasetaskPurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'view' } },
	  { path: 'purchases/:purchase/purchasetasks/:id/edit', canActivate: [AuthService], component: PurchasetaskPurchaseFormComponent, data: { resource: 'PURCHASE', mode: 'edit' } },

	  { path: 'commands', canActivate: [AuthService], component: CommandTableComponent, data: { resource: 'COMMAND', mode: 'list' } },
	  { path: 'commands/add', canActivate: [AuthService], component: CommandFormComponent, data: { resource: 'COMMAND', mode: 'add' } },
	  { path: 'commands/:instruction/view', canActivate: [AuthService], component: CommandFormComponent, data: { resource: 'COMMAND', mode: 'view' } },
	  { path: 'commands/:instruction/edit', canActivate: [AuthService], component: CommandFormComponent, data: { resource: 'COMMAND', mode: 'edit' } },

	  { path: 'agents', canActivate: [AuthService], component: AgentTableComponent, data: { resource: 'AGENT', mode: 'list' } },
	  { path: 'agents/add', canActivate: [AuthService], component: AgentFormComponent, data: { resource: 'AGENT', mode: 'add' } },
	  { path: 'agents/:id/view', canActivate: [AuthService], component: AgentFormComponent, data: { resource: 'AGENT', mode: 'view' } },
	  { path: 'agents/:id/edit', canActivate: [AuthService], component: AgentFormComponent, data: { resource: 'AGENT', mode: 'edit' } },

	  { path: 'hometypes', canActivate: [AuthService], component: HometypeTableComponent, data: { resource: 'HOMETYPE', mode: 'list' } },
	  { path: 'hometypes/add', canActivate: [AuthService], component: HometypeFormComponent, data: { resource: 'HOMETYPE', mode: 'add' } },
	  { path: 'hometypes/:id/view', canActivate: [AuthService], component: HometypeFormComponent, data: { resource: 'HOMETYPE', mode: 'view' } },
	  { path: 'hometypes/:id/edit', canActivate: [AuthService], component: HometypeFormComponent, data: { resource: 'HOMETYPE', mode: 'edit' } },

	  { path: 'doctypes', canActivate: [AuthService], component: DoctypeTableComponent, data: { resource: 'DOCTYPE', mode: 'list' } },
	  { path: 'doctypes/add', canActivate: [AuthService], component: DoctypeFormComponent, data: { resource: 'DOCTYPE', mode: 'add' } },
	  { path: 'doctypes/:id/view', canActivate: [AuthService], component: DoctypeFormComponent, data: { resource: 'DOCTYPE', mode: 'view' } },
	  { path: 'doctypes/:id/edit', canActivate: [AuthService], component: DoctypeFormComponent, data: { resource: 'DOCTYPE', mode: 'edit' } },

	  { path: 'tasktypes', canActivate: [AuthService], component: TasktypeTableComponent, data: { resource: 'TASKTYPE', mode: 'list' } },
	  { path: 'tasktypes/add', canActivate: [AuthService], component: TasktypeFormComponent, data: { resource: 'TASKTYPE', mode: 'add' } },
	  { path: 'tasktypes/:id/view', canActivate: [AuthService], component: TasktypeFormComponent, data: { resource: 'TASKTYPE', mode: 'view' } },
	  { path: 'tasktypes/:id/edit', canActivate: [AuthService], component: TasktypeFormComponent, data: { resource: 'TASKTYPE', mode: 'edit' } },

	  { path: 'contacttypes', canActivate: [AuthService], component: ContacttypeTableComponent, data: { resource: 'CONTACTTYPE', mode: 'list' } },
	  { path: 'contacttypes/add', canActivate: [AuthService], component: ContacttypeFormComponent, data: { resource: 'CONTACTTYPE', mode: 'add' } },
	  { path: 'contacttypes/:id/view', canActivate: [AuthService], component: ContacttypeFormComponent, data: { resource: 'CONTACTTYPE', mode: 'view' } },
	  { path: 'contacttypes/:id/edit', canActivate: [AuthService], component: ContacttypeFormComponent, data: { resource: 'CONTACTTYPE', mode: 'edit' } },

	  { path: 'salestatuses', canActivate: [AuthService], component: SalestatusTableComponent, data: { resource: 'SALESTATUS', mode: 'list' } },
	  { path: 'salestatuses/add', canActivate: [AuthService], component: SalestatusFormComponent, data: { resource: 'SALESTATUS', mode: 'add' } },
	  { path: 'salestatuses/:id/view', canActivate: [AuthService], component: SalestatusFormComponent, data: { resource: 'SALESTATUS', mode: 'view' } },
	  { path: 'salestatuses/:id/edit', canActivate: [AuthService], component: SalestatusFormComponent, data: { resource: 'SALESTATUS', mode: 'edit' } },

	  { path: 'purchasestatuses', canActivate: [AuthService], component: PurchasestatusTableComponent, data: { resource: 'PURCHASESTATUS', mode: 'list' } },
	  { path: 'purchasestatuses/add', canActivate: [AuthService], component: PurchasestatusFormComponent, data: { resource: 'PURCHASESTATUS', mode: 'add' } },
	  { path: 'purchasestatuses/:id/view', canActivate: [AuthService], component: PurchasestatusFormComponent, data: { resource: 'PURCHASESTATUS', mode: 'view' } },
	  { path: 'purchasestatuses/:id/edit', canActivate: [AuthService], component: PurchasestatusFormComponent, data: { resource: 'PURCHASESTATUS', mode: 'edit' } },

	  { path: 'sources', canActivate: [AuthService], component: SourceTableComponent, data: { resource: 'SOURCE', mode: 'list' } },
	  { path: 'sources/add', canActivate: [AuthService], component: SourceFormComponent, data: { resource: 'SOURCE', mode: 'add' } },
	  { path: 'sources/:id/view', canActivate: [AuthService], component: SourceFormComponent, data: { resource: 'SOURCE', mode: 'view' } },
	  { path: 'sources/:id/edit', canActivate: [AuthService], component: SourceFormComponent, data: { resource: 'SOURCE', mode: 'edit' } },

	  { path: 'zones', canActivate: [AuthService], component: ZoneTableComponent, data: { resource: 'ZONE', mode: 'list' } },
	  { path: 'zones/add', canActivate: [AuthService], component: ZoneFormComponent, data: { resource: 'ZONE', mode: 'add' } },
	  { path: 'zones/:id/view', canActivate: [AuthService], component: ZoneFormComponent, data: { resource: 'ZONE', mode: 'view' } },
	  { path: 'zones/:id/edit', canActivate: [AuthService], component: ZoneFormComponent, data: { resource: 'ZONE', mode: 'edit' } },

	  { path: 'orientations', canActivate: [AuthService], component: OrientationTableComponent, data: { resource: 'ORIENTATION', mode: 'list' } },
	  { path: 'orientations/add', canActivate: [AuthService], component: OrientationFormComponent, data: { resource: 'ORIENTATION', mode: 'add' } },
	  { path: 'orientations/:id/view', canActivate: [AuthService], component: OrientationFormComponent, data: { resource: 'ORIENTATION', mode: 'view' } },
	  { path: 'orientations/:id/edit', canActivate: [AuthService], component: OrientationFormComponent, data: { resource: 'ORIENTATION', mode: 'edit' } },

	  { path: 'whies', canActivate: [AuthService], component: WhyTableComponent, data: { resource: 'WHY', mode: 'list' } },
	  { path: 'whies/add', canActivate: [AuthService], component: WhyFormComponent, data: { resource: 'WHY', mode: 'add' } },
	  { path: 'whies/:id/view', canActivate: [AuthService], component: WhyFormComponent, data: { resource: 'WHY', mode: 'view' } },
	  { path: 'whies/:id/edit', canActivate: [AuthService], component: WhyFormComponent, data: { resource: 'WHY', mode: 'edit' } },

	  { path: 'timeframes', canActivate: [AuthService], component: TimeframeTableComponent, data: { resource: 'TIMEFRAME', mode: 'list' } },
	  { path: 'timeframes/add', canActivate: [AuthService], component: TimeframeFormComponent, data: { resource: 'TIMEFRAME', mode: 'add' } },
	  { path: 'timeframes/:id/view', canActivate: [AuthService], component: TimeframeFormComponent, data: { resource: 'TIMEFRAME', mode: 'view' } },
	  { path: 'timeframes/:id/edit', canActivate: [AuthService], component: TimeframeFormComponent, data: { resource: 'TIMEFRAME', mode: 'edit' } },

	  { path: 'statuses', canActivate: [AuthService], component: StatusTableComponent, data: { resource: 'STATUS', mode: 'list' } },
	  { path: 'statuses/add', canActivate: [AuthService], component: StatusFormComponent, data: { resource: 'STATUS', mode: 'add' } },
	  { path: 'statuses/:id/view', canActivate: [AuthService], component: StatusFormComponent, data: { resource: 'STATUS', mode: 'view' } },
	  { path: 'statuses/:id/edit', canActivate: [AuthService], component: StatusFormComponent, data: { resource: 'STATUS', mode: 'edit' } },

	  { path: 'tags', canActivate: [AuthService], component: TagTableComponent, data: { resource: 'TAG', mode: 'list' } },
	  { path: 'tags/add', canActivate: [AuthService], component: TagFormComponent, data: { resource: 'TAG', mode: 'add' } },
	  { path: 'tags/:id/view', canActivate: [AuthService], component: TagFormComponent, data: { resource: 'TAG', mode: 'view' } },
	  { path: 'tags/:id/edit', canActivate: [AuthService], component: TagFormComponent, data: { resource: 'TAG', mode: 'edit' } },

	  { path: 'principals', canActivate: [AuthService], component: PrincipalTableComponent, data: { resource: 'PRINCIPAL', mode: 'list' } },
	  { path: 'principals/add', canActivate: [AuthService], component: PrincipalFormComponent, data: { resource: 'PRINCIPAL', mode: 'add' } },
	  { path: 'principals/:id/view', canActivate: [AuthService], component: PrincipalFormComponent, data: { resource: 'PRINCIPAL', mode: 'view' } },
	  { path: 'principals/:id/edit', canActivate: [AuthService], component: PrincipalFormComponent, data: { resource: 'PRINCIPAL', mode: 'edit' } },

	  { path: 'roles/:role/privileges/add', canActivate: [AuthService], component: PrivilegeRoleFormComponent, data: { resource: 'ROLE', mode: 'add' } },
	  { path: 'roles/:role/privileges/:id/view', canActivate: [AuthService], component: PrivilegeRoleFormComponent, data: { resource: 'ROLE', mode: 'view' } },
	  { path: 'roles/:role/privileges/:id/edit', canActivate: [AuthService], component: PrivilegeRoleFormComponent, data: { resource: 'ROLE', mode: 'edit' } },

	  { path: 'groups', canActivate: [AuthService], component: GroupTableComponent, data: { resource: 'GROUP', mode: 'list' } },
	  { path: 'groups/add', canActivate: [AuthService], component: GroupFormComponent, data: { resource: 'GROUP', mode: 'add' } },
	  { path: 'groups/:id/view', canActivate: [AuthService], component: GroupFormComponent, data: { resource: 'GROUP', mode: 'view' } },
	  { path: 'groups/:id/edit', canActivate: [AuthService], component: GroupFormComponent, data: { resource: 'GROUP', mode: 'edit' } },

	  { path: 'roles', canActivate: [AuthService], component: RoleTableComponent, data: { resource: 'ROLE', mode: 'list' } },
	  { path: 'roles/add', canActivate: [AuthService], component: RoleFormComponent, data: { resource: 'ROLE', mode: 'add' } },
	  { path: 'roles/:id/view', canActivate: [AuthService], component: RoleFormComponent, data: { resource: 'ROLE', mode: 'view' } },
	  { path: 'roles/:id/edit', canActivate: [AuthService], component: RoleFormComponent, data: { resource: 'ROLE', mode: 'edit' } },

	  { path: 'configs', canActivate: [AuthService], component: ConfigTableComponent, data: { resource: 'CONFIG', mode: 'list' } },
	  { path: 'configs/add', canActivate: [AuthService], component: ConfigFormComponent, data: { resource: 'CONFIG', mode: 'add' } },
	  { path: 'configs/:code/view', canActivate: [AuthService], component: ConfigFormComponent, data: { resource: 'CONFIG', mode: 'view' } },
	  { path: 'configs/:code/edit', canActivate: [AuthService], component: ConfigFormComponent, data: { resource: 'CONFIG', mode: 'edit' } },

	  { path: 'valuator', canActivate: [AuthService], component: ValuatorComponent, data: { resource: 'VALUATOR', mode: 'custom' } },
	  { path: 'report', canActivate: [AuthService], component: ReportComponent, data: { resource: 'REPORT', mode: 'custom' } },
	  { path: 'geo', canActivate: [AuthService], component: GeoComponent, data: { resource: 'GEO', mode: 'custom' } },
	]
  }
];

@NgModule({
  imports: [
	RouterModule.forRoot(routes)
  ],
  exports: [
	RouterModule
  ]
})
export class CrudRoutingModule { }
