<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.SALEDOCUMENT'|translate" [optional]="'LABEL.ENTITY.SALEDOCUMENT.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="saledocumentForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.SALEDOCUMENT'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('doctype')">
              <label class="control-label" for="doctype">
                {{ 'LABEL.FIELD.SALEDOCUMENT.DOCTYPE'|translate }}
              </label>
              <ng-select 
                formControlName="doctype"
                [items]="doctypeoptions"
                bindLabel="name"
                bindValue="id"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALEDOCUMENT.DOCTYPE':'')|translate }}"
                style="width: 100%;">
              </ng-select>
              <span class="help-block">{{err('doctype')?.msg|translate:err('doctype')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('details')">
              <label class="control-label" for="details">
                {{ 'LABEL.FIELD.SALEDOCUMENT.DETAILS'|translate }}
              </label>
              <input
                formControlName="details"
                maxlength="200"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALEDOCUMENT.DETAILS':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (3) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('file')">
              <label class="control-label" for="file">
                {{ 'LABEL.FIELD.SALEDOCUMENT.FILE'|translate }}
              </label>
              &nbsp;<i (click)="onFileDelete('file', $event)" class="fa fa-trash"></i>
              <input *ngIf="mode!='view'"
                (change)="onFileChange('file', $event)"
                type="file"
                class="form-control" autocomplete="off" style="margin-bottom:10px;">
              <div *ngIf="mode!='add' && !data?.fileFile">
                <div *ngIf="data?.filetype?.startsWith('image/')" style="margin-bottom:10px;">
                  <!--<img width="120px" src="data:{{data.filetype}};base64,{{data.fileBytes}}">-->
                  <img [hidden]="!fileLoaded" (load)="fileLoaded=true" width="120px" [src]="globals.server + 'saledocuments/' + data.id + '/file' | secure | async">
                </div>
                <!--<a *ngIf="data?.filetype" href="{{ globals.server }}saledocuments/{{ data.id }}/file" type="{{ data.filetype }}">-->
                <a *ngIf="data?.filetype" target="_blank" [href]="globals.server + 'saledocuments/' + data.id + '/file' | secure | async" type="{{ data.filetype }}">
                  <i [class]="'fa fa-lg ' + mimeIcon(data?.filetype)"></i>{{ 'LABEL.DOWNLOAD'| translate }}
                </a>
              </div>
              <span class="help-block">{{err('file')?.msg|translate:err('file')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="saledocumentForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:SALEDOCUMENT')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="saledocumentForm.invalid || saledocumentForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:SALEDOCUMENT')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:SALEDOCUMENT')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
