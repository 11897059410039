import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { SaleService } from 'app/crud/sales/sale/sale.service';
import { Sale } from 'app/crud/sales/sale/sale';

import { AgentService } from 'app/crud/sales/agent/agent.service';
import { Agent } from 'app/crud/sales/agent/agent';
import { HometypeService } from 'app/crud/sales/hometype/hometype.service';
import { Hometype } from 'app/crud/sales/hometype/hometype';
import { OrientationService } from 'app/crud/sales/orientation/orientation.service';
import { Orientation } from 'app/crud/sales/orientation/orientation';
import { SalestatusService } from 'app/crud/sales/salestatus/salestatus.service';
import { Salestatus } from 'app/crud/sales/salestatus/salestatus';
import { SourceService } from 'app/crud/sales/source/source.service';
import { Source } from 'app/crud/sales/source/source';
import { StatusService } from 'app/crud/sales/status/status.service';
import { Status } from 'app/crud/sales/status/status';
import { TagService } from 'app/crud/sales/tag/tag.service';
import { Tag } from 'app/crud/sales/tag/tag';
import { WhyService } from 'app/crud/sales/why/why.service';
import { Why } from 'app/crud/sales/why/why';
import { ZoneService } from 'app/crud/sales/zone/zone.service';
import { Zone } from 'app/crud/sales/zone/zone';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-sale-form',
  templateUrl: './sale-form.component.html',
})
export class SaleFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  zoneoptions: Zone[] = [];
  agentoptions: Agent[] = [];
  sourceoptions: Source[] = [];
  salestatusoptions: Salestatus[] = [];
  hometypeoptions: Hometype[] = [];
  tagsoptions: Tag[] = [];
  orientationoptions: Orientation[] = [];
  statusoptions: Status[] = [];
  whyoptions: Why[] = [];

  // Form
  saleForm = this.fb.group({
    // Fields
    reference: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(0), Validators.maxLength(30), ]],
    address: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(0), Validators.maxLength(200), ]],
    zip: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(0), Validators.maxLength(5), ]],
    zone: [{ value: '', disabled: false }, []],
    agent: [{ value: '', disabled: false }, [Validators.required, ]],
    source: [{ value: '', disabled: false }, [Validators.required, ]],
    salestatus: [{ value: '', disabled: false }, [Validators.required, ]],
    lastvaluation: [{ value: '', disabled: true }, [this.dateValidator('L'), ]],
    statusdate: [{ value: '', disabled: false }, [Validators.required, this.dateValidator('L'), ]],
    hometype: [{ value: '', disabled: false }, []],
    comments: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(2000), ]],
    tags: [{ value: '', disabled: false }, []],
    lat: [{ value: '', disabled: false }, []],
    lon: [{ value: '', disabled: false }, []],
    description: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    details: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(4000), ]],
    rooms: [{ value: '', disabled: false }, []],
    baths: [{ value: '', disabled: false }, []],
    area: [{ value: '', disabled: false }, []],
    terrace: [{ value: '', disabled: false }, []],
    garage: [{ value: '', disabled: false }, []],
    exterior: [{ value: false, disabled: false }],
    elevator: [{ value: false, disabled: false }],
    pool: [{ value: false, disabled: false }],
    orientation: [{ value: '', disabled: false }, []],
    status: [{ value: '', disabled: false }, [Validators.required, ]],
    why: [{ value: '', disabled: false }, [Validators.required, ]],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Sale = new Sale();

  // Loading indicators
  isLoading = false;

  // Components constructor
  constructor(
    private apiAgent: AgentService,
    private apiHometype: HometypeService,
    private apiOrientation: OrientationService,
    private apiSalestatus: SalestatusService,
    private apiSource: SourceService,
    private apiStatus: StatusService,
    private apiTag: TagService,
    private apiWhy: WhyService,
    private apiZone: ZoneService,
    private api: SaleService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Sale();
    this.data.zone = [];
    this.data.agent = new Agent();
    this.data.source = new Source();
    this.data.salestatus = new Salestatus();
    this.data.hometype = new Hometype();
    this.data.tags = [];
    this.data.orientation = new Orientation();
    this.data.status = new Status();
    this.data.why = new Why();

    // Load zone lookup
    this.loadZone();

    // Load agent lookup
    this.loadAgent();

    // Load source lookup
    this.loadSource();

    // Load salestatus lookup
    this.loadSalestatus();

    // Load hometype lookup
    this.loadHometype();

    // Load tags lookup
    this.loadTags();

    // Load orientation lookup
    this.loadOrientation();

    // Load status lookup
    this.loadStatus();

    // Load why lookup
    this.loadWhy();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.saleForm.controls).forEach(key => {
        this.saleForm.get(key).disable();
      });
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: Sale, form: FormGroup) {
    form.get('reference').setValue(data.reference);
    form.get('address').setValue(data.address);
    form.get('zip').setValue(data.zip);
    if (data.zone) { form.get('zone').setValue(data.zone.map(x => x.id)); } else { data.zone = []; }
    if (data.agent) { form.get('agent').setValue(data.agent.id); } else { data.agent = new Agent(); }
    if (data.source) { form.get('source').setValue(data.source.id); } else { data.source = new Source(); }
    if (data.salestatus) { form.get('salestatus').setValue(data.salestatus.id); } else { data.salestatus = new Salestatus(); }
    this.setDateTime(form.get('createdon'), data.audit.createdon, 'L LTS');
    this.setDateTime(form.get('lastvaluation'), data.lastvaluation, 'L');
    this.setDateTime(form.get('statusdate'), data.statusdate, 'L');
    if (data.hometype) { form.get('hometype').setValue(data.hometype.id); } else { data.hometype = new Hometype(); }
    form.get('comments').setValue(data.comments);
    if (data.tags) { form.get('tags').setValue(data.tags.map(x => x.id)); } else { data.tags = []; }
    form.get('lat').setValue(data.lat);
    form.get('lon').setValue(data.lon);
    form.get('description').setValue(data.description);
    form.get('details').setValue(data.details);
    form.get('rooms').setValue(data.rooms);
    form.get('baths').setValue(data.baths);
    form.get('area').setValue(data.area);
    form.get('terrace').setValue(data.terrace);
    form.get('garage').setValue(data.garage);
    form.get('exterior').setValue(data.exterior);
    form.get('elevator').setValue(data.elevator);
    form.get('pool').setValue(data.pool);
    if (data.orientation) { form.get('orientation').setValue(data.orientation.id); } else { data.orientation = new Orientation(); }
    if (data.status) { form.get('status').setValue(data.status.id); } else { data.status = new Status(); }
    if (data.why) { form.get('why').setValue(data.why.id); } else { data.why = new Why(); }
  }

  mapFormToData(form: FormGroup, data: Sale) {
    data.reference = form.get('reference').value;
    data.address = form.get('address').value;
    data.zip = form.get('zip').value;
    if (form.get('zone').value) { data.zone = form.get('zone').value.map(x => ({id: x})); }
    if (!form.get('agent').value) { data.agent = null; } else { data.agent.id = form.get('agent').value; }
    if (!form.get('source').value) { data.source = null; } else { data.source.id = form.get('source').value; }
    if (!form.get('salestatus').value) { data.salestatus = null; } else { data.salestatus.id = form.get('salestatus').value; }
    data.lastvaluation = this.getDateTime(form.get('lastvaluation'), data.lastvaluation, 'L');
    data.statusdate = this.getDateTime(form.get('statusdate'), data.statusdate, 'L');
    if (!form.get('hometype').value) { data.hometype = null; } else { data.hometype.id = form.get('hometype').value; }
    data.comments = form.get('comments').value;
    if (form.get('tags').value) { data.tags = form.get('tags').value.map(x => ({id: x})); }
    data.lat = form.get('lat').value;
    data.lon = form.get('lon').value;
    data.description = form.get('description').value;
    data.details = form.get('details').value;
    data.rooms = form.get('rooms').value;
    data.baths = form.get('baths').value;
    data.area = form.get('area').value;
    data.terrace = form.get('terrace').value;
    data.garage = form.get('garage').value;
    data.exterior = form.get('exterior').value;
    data.elevator = form.get('elevator').value;
    data.pool = form.get('pool').value;
    if (!form.get('orientation').value) { data.orientation = null; } else { data.orientation.id = form.get('orientation').value; }
    if (!form.get('status').value) { data.status = null; } else { data.status.id = form.get('status').value; }
    if (!form.get('why').value) { data.why = null; } else { data.why.id = form.get('why').value; }
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.saleForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'SALE', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.saleForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'SALE');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'SALE', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.saleForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'SALE');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'SALE', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.SALE');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'SALE');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'SALE', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.saleForm, name);
  }

  // Load zone lookup
  loadZone() {
    this.apiZone.readAll('+name').subscribe(
      res => {
        this.zoneoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load agent lookup
  loadAgent() {
    this.apiAgent.readAll('+name').subscribe(
      res => {
        this.agentoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load source lookup
  loadSource() {
    this.apiSource.readAll('+name').subscribe(
      res => {
        this.sourceoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load salestatus lookup
  loadSalestatus() {
    this.apiSalestatus.readAll('+name').subscribe(
      res => {
        this.salestatusoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load hometype lookup
  loadHometype() {
    this.apiHometype.readAll('+name').subscribe(
      res => {
        this.hometypeoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load tag lookup
  loadTags() {
    this.apiTag.readAll('+name').subscribe(
      res => {
        this.tagsoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load orientation lookup
  loadOrientation() {
    this.apiOrientation.readAll('+name').subscribe(
      res => {
        this.orientationoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load status lookup
  loadStatus() {
    this.apiStatus.readAll('+name').subscribe(
      res => {
        this.statusoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load why lookup
  loadWhy() {
    this.apiWhy.readAll('+name').subscribe(
      res => {
        this.whyoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
