// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Salestatus } from 'app/crud/sales/salestatus/salestatus';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class SalestatusService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all salestatuses
  readAll(sort: string): Observable<Page<Salestatus>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of salestatuses
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Salestatus>> {
    let url = `${this.globals.server}salestatuses?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Salestatus>>(url).pipe(
      // tap(_ => console.log('read salestatuses')),
      catchError(this.handleError<Page<Salestatus>>('read Salestatus'))
    );
  }

  // Read one salestatus
  readOne(id: string): Observable<Salestatus> {
    return this.http.get<Salestatus>(`${this.globals.server}salestatuses/${id}`).pipe(
      // tap(_ => console.log(`readOne salestatus ${id} `)),
      catchError(this.handleError<Salestatus>(`readOne Salestatus ${id} `))
    );
  }

  // Count salestatuses
  count(): Observable<Salestatus[]> {
    return this.http.get<Salestatus[]>(`${this.globals.server}salestatuses/count`).pipe(
      // tap(_ => console.log('count salestatuses')),
      catchError(this.handleError('count Salestatus', []))
    );
  }

  // Create salestatus
  create(o: Salestatus): Observable<Salestatus> {
    return this.http.post<Salestatus>(`${this.globals.server}salestatuses`, o, httpOptions).pipe(
      // tap(_ => console.log('create salestatus')),
      catchError(this.handleError<Salestatus>('create Salestatus'))
    );
  }

  // Update salestatus
  update(id: string, o: Salestatus): Observable<any> {
    return this.http.put(`${this.globals.server}salestatuses/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update salestatus ${id} `)),
      catchError(this.handleError<any>(`update Salestatus ${id} `))
    );
  }

  // Delete salestatus
  delete(id: string): Observable<Salestatus> {
    return this.http.delete<Salestatus>(`${this.globals.server}salestatuses/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete salestatus ${id} `)),
      catchError(this.handleError<Salestatus>(`delete Salestatus ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
