<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.ENTRY'|translate" [optional]="'LABEL.ENTITY.ENTRY.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="entryForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.ENTRY'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.ENTRY.CONTACT'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('createdon')">
                <label class="control-label" for="createdon">
                  {{ 'LABEL.FIELD.ENTRY.CREATEDON'|translate }}
                </label>
                <input
                  formControlName="createdon"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.CREATEDON':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('createdon')?.msg|translate:err('createdon')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('contact')">
                <label class="control-label" for="contact">
                  {{ 'LABEL.FIELD.ENTRY.CONTACT'|translate }}
                </label>
                <input
                  formControlName="contact"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.CONTACT':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('contact')?.msg|translate:err('contact')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('crmid')">
                <label class="control-label" for="crmid">
                  {{ 'LABEL.FIELD.ENTRY.CRMID'|translate }}
                </label>
                <input
                  formControlName="crmid"
                  maxlength="8"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.CRMID':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('crmid')?.msg|translate:err('crmid')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('client')">
                <label class="control-label" for="client">
                  {{ 'LABEL.FIELD.ENTRY.CLIENT'|translate }}
                </label>
                <input
                  formControlName="client"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.CLIENT':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('client')?.msg|translate:err('client')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('email')">
                <label class="control-label" for="email">
                  {{ 'LABEL.FIELD.ENTRY.EMAIL'|translate }}
                </label>
                <input
                  formControlName="email"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.EMAIL':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('email')?.msg|translate:err('email')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('phone')">
                <label class="control-label" for="phone">
                  {{ 'LABEL.FIELD.ENTRY.PHONE'|translate }}
                </label>
                <input
                  formControlName="phone"
                  maxlength="20"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.PHONE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('phone')?.msg|translate:err('phone')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-1">
              <div class="form-group" [class.has-error]="err('rgpd')">
                <label class="control-label" for="rgpd">
                  {{ 'LABEL.FIELD.ENTRY.RGPD'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="rgpd" id="rgpd" 
                    type="checkbox">
                  <label for="rgpd">{{ 'LABEL.FIELD.ENTRY.RGPD'|translate }}</label>
                </div>
                <span class="help-block">{{err('rgpd')?.msg|translate:err('rgpd')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-1">
              <div class="form-group" [class.has-error]="err('confirm')">
                <label class="control-label" for="confirm">
                  {{ 'LABEL.FIELD.ENTRY.CONFIRM'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="confirm" id="confirm" 
                    type="checkbox">
                  <label for="confirm">{{ 'LABEL.FIELD.ENTRY.CONFIRM'|translate }}</label>
                </div>
                <span class="help-block">{{err('confirm')?.msg|translate:err('confirm')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-1">
              <div class="form-group" [class.has-error]="err('transferred')">
                <label class="control-label" for="transferred">
                  {{ 'LABEL.FIELD.ENTRY.TRANSFERRED'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="transferred" id="transferred" 
                    type="checkbox">
                  <label for="transferred">{{ 'LABEL.FIELD.ENTRY.TRANSFERRED'|translate }}</label>
                </div>
                <span class="help-block">{{err('transferred')?.msg|translate:err('transferred')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.ENTRY.HOME'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('reference')">
                <label class="control-label" for="reference">
                  {{ 'LABEL.FIELD.ENTRY.REFERENCE'|translate }}
                </label>
                <input
                  formControlName="reference"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.REFERENCE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('reference')?.msg|translate:err('reference')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('why')">
                <label class="control-label" for="why">
                  {{ 'LABEL.FIELD.ENTRY.WHY'|translate }}
                </label>
                <ng-select 
                  formControlName="why"
                  [items]="whyoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.WHY':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('why')?.msg|translate:err('why')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-8">
              <div class="form-group" [class.has-error]="err('data')">
                <label class="control-label" for="data">
                  {{ 'LABEL.FIELD.ENTRY.DATA'|translate }}
                </label>
                <input
                  formControlName="data"
                  maxlength="2000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.DATA':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('data')?.msg|translate:err('data')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.ENTRY.MESSAGE'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('subject')">
                <label class="control-label" for="subject">
                  {{ 'LABEL.FIELD.ENTRY.SUBJECT'|translate }}
                </label>
                <input
                  formControlName="subject"
                  maxlength="100"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.SUBJECT':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('subject')?.msg|translate:err('subject')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('message')">
                <label class="control-label" for="message">
                  {{ 'LABEL.FIELD.ENTRY.MESSAGE'|translate }}
                </label>
                <textarea
                  formControlName="message"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="2000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.MESSAGE':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('message')?.msg|translate:err('message')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('comments')">
                <label class="control-label" for="comments">
                  {{ 'LABEL.FIELD.ENTRY.COMMENTS'|translate }}
                </label>
                <textarea
                  formControlName="comments"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="2000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.COMMENTS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.ENTRY.MARKETING'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('tags')">
                <label class="control-label" for="tags">
                  {{ 'LABEL.FIELD.ENTRY.TAGS'|translate }}
                </label>
                <textarea
                  formControlName="tags"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.ENTRY.TAGS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('tags')?.msg|translate:err('tags')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="entryForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:ENTRY')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="entryForm.invalid || entryForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:ENTRY')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:ENTRY')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->


    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
