<header class="main-header">

  <!-- Logo -->
  <a (click)="fullScreen()" class="logo">
    <span class="logo-mini" [innerHTML]="globals.config.companyshort"></span>
    <span class="logo-lg" [innerHTML]="globals.config.company"></span>
  </a>

  <!-- Header Navbar -->
  <nav class="navbar navbar-static-top" role="navigation">

    <!-- Menu toggle-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>

    <!-- Custom menu-->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">

        <!-- Tasks dropdown -->
        <li *ngIf="globals.config.elements.header.tasks" app-header-tasks class="dropdown tasks-menu"></li>
        <!-- Notification dropdown -->
        <li *ngIf="globals.config.elements.header.notifications" app-header-notifications class="dropdown notifications-menu"></li>
        <!-- Messages dropdown -->
        <li *ngIf="globals.config.elements.header.messages" app-header-messages class="dropdown messages-menu"></li>
        <!-- Languages dropdown -->
        <li *ngIf="globals.config.elements.header.languages" app-header-languages class="dropdown messages-menu"></li>
        <!-- Users dropdown -->
        <li app-header-user class="dropdown user user-menu"></li>

        <!-- Config -->
        <li *ngIf="globals.config.elements.header.settings" >
          <a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
        </li>

      </ul>
    </div>
  </nav>

</header>