<div class="content-wrapper">

  <app-header-content header="Valorador"></app-header-content>
  <app-spinner *ngIf="isLoading"></app-spinner>

  <section class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="valuatorForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Body -->
      <div class="box-body">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">Datos del inmueble</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">

            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('ref')">
                <label class="control-label" for="ref">
                  Referencia:
                </label>
                <input
                  formControlName="ref"
                  placeholder="Referencia catastral"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('ref')?.msg|translate:err('ref')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('ren')">
                <label class="control-label" for="ren">
                  Reformado hace:
                </label>
                <input
                  formControlName="ren"
                  placeholder="Años última reforma"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('ren')?.msg|translate:err('ren')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('exi')">
                <label class="control-label" for="exi">Exterior/Interior: <i class="text-danger fa fa-exclamation"></i></label>
                <ng-select 
                  formControlName="exi"
                  clearable="false"
                  [items]="exioptions"
                  bindLabel="name"
                  bindValue="id"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('exi')?.msg|translate:err('exi')?.param}}</span>
              </div>
            </div> <!-- end Field -->

            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('tpl')">
                <label class="control-label" for="tpl">Plantilla: <i class="text-danger fa fa-exclamation"></i></label>
                <ng-select 
                  formControlName="tpl"
                  clearable="false"
                  [items]="tploptions"
                  bindLabel="name"
                  bindValue="id"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('tpl')?.msg|translate:err('tpl')?.param}}</span>
              </div>
            </div> <!-- end Field -->

          </div> <!-- end Row -->

        </div>

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">Sobre-escribir datos</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

            <!-- Row -->
          <div class="row">

            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('sup')">
                <label class="control-label" for="sup">
                  Superficie:
                </label>
                <input
                  formControlName="sup"
                  placeholder="Superficie"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('sup')?.msg|translate:err('sup')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('p_act')">
                <label class="control-label" for="p_act">
                  Precio situación actual:
                </label>
                <input
                  formControlName="p_act"
                  placeholder="Precio actual"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('p_act')?.msg|translate:err('p_act')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('p_new')">
                <label class="control-label" for="p_new">
                  Precio reformado:
                </label>
                <input
                  formControlName="p_new"
                  placeholder="Precio reformado"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('p_new')?.msg|translate:err('p_new')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('p_ren')">
                <label class="control-label" for="p_ren">
                  Precio reforma:
                </label>
                <input
                  formControlName="p_ren"
                  placeholder="Precio reforma"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('p_ren')?.msg|translate:err('p_ren')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('p_sal')">
                <label class="control-label" for="p_sal">
                  Precio de salida:
                </label>
                <input
                  formControlName="p_sal"
                  placeholder="Precio salida"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('p_sal')?.msg|translate:err('p_sal')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->

        </div>

      </div> <!-- end Body -->

      <!-- Button bar -->
      <div class="box-footer">
        <div class="btn-grp">
          <button [disabled]="valuatorForm.invalid" (click)="valuate()" type="submit" class="btn btn-primary">Valorar</button>
        </div>
      </div> <!-- end Button bar -->

    </div> <!-- end Panel -->

    </form> 

  </div>
  </div>
  </section> <!-- end Section -->

</div>