import { Audit } from 'app/shared/model/audit';

// Purchasestatus class
export class Purchasestatus {

  // Key
  id: string;

  // Fields
  name: string;

  // Audit
  audit : Audit = new Audit();
}

