// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Hometype } from 'app/crud/sales/hometype/hometype';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class HometypeService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all hometypes
  readAll(sort: string): Observable<Page<Hometype>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of hometypes
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Hometype>> {
    let url = `${this.globals.server}hometypes?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Hometype>>(url).pipe(
      // tap(_ => console.log('read hometypes')),
      catchError(this.handleError<Page<Hometype>>('read Hometype'))
    );
  }

  // Read one hometype
  readOne(id: number): Observable<Hometype> {
    return this.http.get<Hometype>(`${this.globals.server}hometypes/${id}`).pipe(
      // tap(_ => console.log(`readOne hometype ${id} `)),
      catchError(this.handleError<Hometype>(`readOne Hometype ${id} `))
    );
  }

  // Count hometypes
  count(): Observable<Hometype[]> {
    return this.http.get<Hometype[]>(`${this.globals.server}hometypes/count`).pipe(
      // tap(_ => console.log('count hometypes')),
      catchError(this.handleError('count Hometype', []))
    );
  }

  // Create hometype
  create(o: Hometype): Observable<Hometype> {
    return this.http.post<Hometype>(`${this.globals.server}hometypes`, o, httpOptions).pipe(
      // tap(_ => console.log('create hometype')),
      catchError(this.handleError<Hometype>('create Hometype'))
    );
  }

  // Update hometype
  update(id: number, o: Hometype): Observable<any> {
    return this.http.put(`${this.globals.server}hometypes/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update hometype ${id} `)),
      catchError(this.handleError<any>(`update Hometype ${id} `))
    );
  }

  // Delete hometype
  delete(id: number): Observable<Hometype> {
    return this.http.delete<Hometype>(`${this.globals.server}hometypes/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete hometype ${id} `)),
      catchError(this.handleError<Hometype>(`delete Hometype ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
