import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { PrincipalService } from 'app/crud/system/principal/principal.service';
import { Principal } from 'app/crud/system/principal/principal';

import { GroupService } from 'app/crud/system/group/group.service';
import { Group } from 'app/crud/system/group/group';
import { RoleService } from 'app/crud/system/role/role.service';
import { Role } from 'app/crud/system/role/role';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-principal-form',
  templateUrl: './principal-form.component.html',
})
export class PrincipalFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  roleoptions: Role[] = [];
  groupoptions: Group[] = [];
  provideroptions: string[] = ['INTERNAL', 'GOOGLE', 'OKTA', 'AUTH0'];

  // Form
  principalForm = this.fb.group({
    // Key
    id: ['', [Validators.required, Validators.minLength(0), Validators.maxLength(80), ]],
    // Fields
    email: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(0), Validators.maxLength(120), ]],
    name: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(0), Validators.maxLength(120), ]],
    password: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(80), ]],
    role: [{ value: '', disabled: false }, [Validators.required, ]],
    group: [{ value: '', disabled: false }, [Validators.required, ]],
    provider: [{ value: '', disabled: false }, [Validators.required, ]],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Principal = new Principal();

  // Loading indicators
  isLoading = false;

  // Components constructor
  constructor(
    private apiGroup: GroupService,
    private apiRole: RoleService,
    private api: PrincipalService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Principal();
    this.data.role = [];
    this.data.group = new Group();

    // Load role lookup
    this.loadRole();

    // Load group lookup
    this.loadGroup();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.principalForm.controls).forEach(key => {
        this.principalForm.get(key).disable();
      });
    }

    // Disable key fields in edit mode
    if (this.mode === 'edit') {
      this.principalForm.get('id').disable();
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: Principal, form: FormGroup) {
    form.get('id').setValue(data.id);
    form.get('email').setValue(data.email);
    form.get('name').setValue(data.name);
    form.get('password').setValue(data.password);
    if (data.role) { form.get('role').setValue(data.role.map(x => x.id)); } else { data.role = []; }
    if (data.group) { form.get('group').setValue(data.group.id); } else { data.group = new Group(); }
    form.get('provider').setValue(data.provider);
  }

  mapFormToData(form: FormGroup, data: Principal) {
    data.id = form.get('id').value;
    data.email = form.get('email').value;
    data.name = form.get('name').value;
    data.password = form.get('password').value;
    if (form.get('role').value) { data.role = form.get('role').value.map(x => ({id: x})); }
    if (!form.get('group').value) { data.group = null; } else { data.group.id = form.get('group').value; }
    data.provider = form.get('provider').value;
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.principalForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'PRINCIPAL', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.principalForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'PRINCIPAL');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'PRINCIPAL', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.principalForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'PRINCIPAL');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'PRINCIPAL', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.PRINCIPAL');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'PRINCIPAL');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'PRINCIPAL', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.principalForm, name);
  }

  // Load role lookup
  loadRole() {
    this.apiRole.readAll('+name').subscribe(
      res => {
        this.roleoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load group lookup
  loadGroup() {
    this.apiGroup.readAll('+name').subscribe(
      res => {
        this.groupoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
