import { Doctype } from 'app/crud/sales/doctype/doctype';
import { Sale } from 'app/crud/sales/sale/sale';
import { Salecontact } from 'app/crud/sales/salecontact/salecontact';
import { Audit } from 'app/shared/model/audit';

// Salevaluation class
export class Salevaluation {

  // Key
  id: number;

  // Fields
  sale: Sale; // = new Sale();
  contact: Salecontact; // = new Salecontact();
  doctype: Doctype; // = new Doctype();
  priceold: number;
  pricenew: number;
  details: string;
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;

  // Audit
  audit : Audit = new Audit();
}

