// tslint:disable: no-string-literal
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject  } from 'rxjs';
import { share, map, catchError } from 'rxjs/operators';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';

import { Login } from 'app/shared/model/login';

import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';
import { Globals } from './globals.service';
import { SessionService } from './session.service';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  // Authentication state
  authenticationState = new BehaviorSubject(null);

  // OIDC login configs (.well-known/openid-configuration)
  configs = {
    google: {
      issuer: 'https://accounts.google.com',
      loginUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
      tokenEndpoint: 'https://oauth2.googleapis.com/token',
      userinfoEndpoint: 'https://openidconnect.googleapis.com/v1/userinfo',
      clientId: '165116811642-i1783kk5niik0nq5j142jn9jqit0r1ha.apps.googleusercontent.com',
      scope: 'openid profile email',
      redirectUri: window.location.origin + '/',
      timeoutFactor: 0.0,  // Silent refresh dont work
      strictDiscoveryDocumentValidation: false,
      showDebugInformation: true,
      sessionChecksEnabled: false,
    },
    auth0: {
      issuer: 'https://alejandroandre.auth0.com/',
      loginUrl: 'https://alejandroandre.auth0.com/authorize',
      tokenEndpoint: 'https://alejandroandre.auth0.com/token',
      userinfoEndpoint: 'https://alejandroandre.auth0.com/userinfo',
      clientId: 'EoPcK3dmocWQ1uLHK36UqdGmq15qHiu3',
      scope: 'openid profile email',
      redirectUri: window.location.origin + '/',
      silentRefreshRedirectUri: window.location.origin + '/refresh.html',
      timeoutFactor: 0.75,
      strictDiscoveryDocumentValidation: false,
      showDebugInformation: true,
      sessionChecksEnabled: false,
    },
    okta: {
      issuer: 'https://dev-715710.okta.com/oauth2/default',
      loginUrl: 'https://dev-715710.okta.com/oauth2/default/v1/authorize',
      tokenEndpoint: 'https://dev-715710.okta.com/oauth2/default/v1/token',
      userinfoEndpoint: 'https://dev-715710.okta.com/oauth2/default/v1/userinfo',
      clientId: '0oaiojqsiz3Sqk2BP356',
      scope: 'openid profile email',
      redirectUri: window.location.origin + '/',
      silentRefreshRedirectUri: window.location.origin + '/refresh.html',
      timeoutFactor: 0.75,
      strictDiscoveryDocumentValidation: false,
      showDebugInformation: true,
      sessionChecksEnabled: false
    }
  };

  constructor(
    private globals: Globals,
    private router: Router,
    private http: HttpClient,
    private oidc: OAuthService) {

    console.log('Auth created');

    // Suscribe to OIDC events
    this.oidc.events.subscribe(event => {

      // Error?
      if (event instanceof OAuthErrorEvent) {
        console.error(event);

      // Token refreshed silently?
      } else {
        if (event.type === 'silently_refreshed') {
          console.log('refresh!');
          const login: Login = this.login;
          const type = localStorage.getItem('logintype');
          this.storeToken(login, type);
        }
      }

    });
  }

  // Activate/deactivate menu items
  canShow(permission: string) {
    // Current login
    const login: Login = this.login;

    // Not logged in? Forbid
    if (!login.logged) {
      return false;
    }

    // Action
    const action = permission.split(':')[0];
    if (action === undefined) {
      return true;
    }

    // Resource
    const resource = permission.split(':')[1];
    if (resource === undefined) {
      return true;
    }

    // Check access
    // tslint:disable-next-line: forin
    for (const p in login.permissions) {

      // Permission
      const r = login.permissions[p].resource.toUpperCase();
      const a = login.permissions[p].action.toUpperCase();

      // Check resource
      if ((r === 'ANY' || r === resource) && (a === 'ANY' || a === action)) {
        return true;
      }
    }

    // Forbid
    // console.log(action + ':' + resource + ' forbidden');
    return false;
  }

  // Activate/deactivate menu items
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Current login
    const login: Login = this.login;

    // Not logged in? Forbid
    if (!login.logged) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    // Action
    const action = route.data.mode;
    if (action === undefined) {
      return true;
    }

    // Resource
    const resource = route.data.resource;
    if (resource === undefined) {
      return true;
    }

    // Check access
    // tslint:disable-next-line: forin
    for (const p in login.permissions) {

      // Permission
      const r = login.permissions[p].resource.toUpperCase();
      const a = login.permissions[p].action.toUpperCase();

      // Check resource
      if (r === 'ANY' || r === resource) {
        if (a === 'ANY') { return true; }
        if (action === 'list') { return true; }
        if (action === 'view') { return true; }
        if (a === 'CREATE' && action === 'add' ) { return true; }
        if (a === 'UPDATE' && action === 'edit') { return true; }
      }
    }

    // Forbid
    console.log(action + ':' + resource + ' forbidden');
    return false;
  }

  // Get current login
  get login(): Login {
    // Get stored login, from storage
    let login: Login = JSON.parse(localStorage.getItem('login'));

    // No login, create a new one
    if (login == null) {
      login = new Login();
    }

    // Return login object
    return login;
  }

  // Set current login to storage
  set login(login: Login) {
    // No login, create a new one
    if (login == null) {
      login = new Login();
    }

    // Store login object
    localStorage.setItem('login', JSON.stringify(login));
  }

  // Change auth state
  state(value) {
    console.log(`Login state set to ${value}`);
    if (this.authenticationState.value !== value) {
      this.authenticationState.next(value);
    }
  }

  tryLogin() {
    // Get stored login, from storage
    const login: Login = this.login;
    const me = this;

    // Promise
    return new Promise((resolve, reject) => {

        // Logged in and token valid? return login
        if (login.logged === true && !helper.isTokenExpired(login.accessToken)) {
          this.state(true);
          resolve(true);
          return;

        // Try to log using oidc
        } else {

          // Login type used?
          const type = localStorage.getItem('logintype');
          if (type == null) {
            this.state(false);
            resolve(false);
            return;
          }

          // Configure and login
          me.oidc.configure(me.configs[type]);
          if (me.configs[type].timeoutFactor > 0.0) {
            me.oidc.setupAutomaticSilentRefresh();
          }
          me.oidc.tryLogin().then(_ => {
            this.storeToken(login, type);
            this.state(login.logged);
            resolve (login.logged);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
        }
      }
    );
  }

  // Store OIDC token
  private storeToken(login, type) {
    // this.oidc.oidc = false;
    if (this.oidc.getIdToken()) {
      login.id = this.oidc.getIdentityClaims()['sub'];
      login.name = this.oidc.getIdentityClaims()['name'];
      login.logged = true;
      login.type = 'oidc';
      login.issuer = type;
      login.state = this.oidc.state;
      login.token = this.oidc.getIdToken();
      login.accessToken = this.oidc.getAccessToken();
      login.refreshToken = this.oidc.getRefreshToken();
      login.decoded = helper.decodeToken(login.token);
      this.login = login;
    }
  }

  // Login with user and password
  doLogin(user: string, password: string): Observable<Login> {
    // Logout first
    this.doLogout();

    // Initialize login
    const login: Login = this.login;
    login.id = user;
    login.name = user;

    login.logged = false;
    login.type = 'internal';
    login.issuer = 'meiras';

    // Get OAUTH2 token
    const data = 'username=' + user + '&password=' + password + '&grant_type=password';
    return this.auth(login, data);
  }

  // Login with OIDC
  doLoginOidc(type: string, url: string) {
    // Logout first
    this.doLogout();

    // Initiate implicit flow
    localStorage.setItem('logintype', type);
    this.oidc.configure(this.configs[type]);
    this.oidc.initImplicitFlow(url);
  }

  doLogout() {
    // OIDC logout
    const login: Login = this.login;
    if (login.logged && login.type === 'oidc') {
      this.oidc.logOut(true);
    }

    // Clear login data
    localStorage.removeItem('login');
    localStorage.removeItem('logintype');

    // State
    this.state(false);
  }

  // Get access token from auth code
  getAccessToken(code: string): Observable<Login> {
    // Get login
    const login: Login = this.login;
    login.type = 'code';
    login.code = code;

    // Get OAUTH2 token
    const data = 'grant_type=authorization_code&code=' + code;
    return this.auth(login, data);
  }

  // Get access token from refresh token
  getRefreshedToken(): Observable<Login> {
    // Get current login data
    const login: Login = this.login;
    if (login.type === 'oidc' || login.refreshToken === undefined) {
      return throwError('OIDC or no refresh token');
    }

    // Get OAUTH2 token
    const data = 'grant_type=refresh_token&refresh_token=' + login.refreshToken;
    return this.auth(login, data);
  }

  // Call OAuth endppint
  auth(login: Login, data: string): Observable<Login> {

    // Headers
    const headers = {
      Authorization: 'Basic ' + btoa('meiras-client:meiras-secret'),
      'Content-type': 'application/x-www-form-urlencoded'
    };

    // Internal authorization server url
    const url = this.globals.auth + 'oauth/token/';

    // Call auth server
    return this.http
      .post(url, data, { headers })
      .pipe(
        share(),
        map(x => {
          login.decoded = helper.decodeToken(x['access_token']);
          login.id = login.decoded.name;
          login.name = login.decoded.name;
          login.token = x['access_token'];
          login.refreshToken = x['refresh_token'];
          login.logged = true;
          this.login = login;
          this.state(true);
          return login;
        }),
        catchError (error => {
          console.log(error);
          return throwError(error);
        })
      );
  }

}
