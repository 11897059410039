// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Group } from 'app/crud/system/group/group';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class GroupService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all groups
  readAll(sort: string): Observable<Page<Group>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of groups
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Group>> {
    let url = `${this.globals.server}groups?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Group>>(url).pipe(
      // tap(_ => console.log('read groups')),
      catchError(this.handleError<Page<Group>>('read Group'))
    );
  }

  // Read one group
  readOne(id: number): Observable<Group> {
    return this.http.get<Group>(`${this.globals.server}groups/${id}`).pipe(
      // tap(_ => console.log(`readOne group ${id} `)),
      catchError(this.handleError<Group>(`readOne Group ${id} `))
    );
  }

  // Count groups
  count(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.globals.server}groups/count`).pipe(
      // tap(_ => console.log('count groups')),
      catchError(this.handleError('count Group', []))
    );
  }

  // Create group
  create(o: Group): Observable<Group> {
    return this.http.post<Group>(`${this.globals.server}groups`, o, httpOptions).pipe(
      // tap(_ => console.log('create group')),
      catchError(this.handleError<Group>('create Group'))
    );
  }

  // Update group
  update(id: number, o: Group): Observable<any> {
    return this.http.put(`${this.globals.server}groups/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update group ${id} `)),
      catchError(this.handleError<any>(`update Group ${id} `))
    );
  }

  // Delete group
  delete(id: number): Observable<Group> {
    return this.http.delete<Group>(`${this.globals.server}groups/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete group ${id} `)),
      catchError(this.handleError<Group>(`delete Group ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
