// Angular common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular common functions
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// i18n
import { TranslateModule } from '@ngx-translate/core';

// Plugins
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// OAuth and OpenID
import { JwksValidationHandler, ValidationHandler } from 'angular-oauth2-oidc';

// Services and interceptors
import { AuthInterceptor } from 'app/shared/other/auth.interceptor';
import { HttpErrorInterceptor } from 'app/shared/other/httperror.interceptor';
import { Globals } from 'app/shared/services/globals.service';

// Pipes and directives
import { LocalDatePipe, LocalNumberPipe } from 'app/shared/other/local.pipe';
import { SecurePipe } from 'app/shared/other/secure.pipe';
import { AutofocusDirective } from 'app/shared/other/focus.directive';
import { AutogrowDirective } from 'app/shared/other/autogrow.directive';
import { PasswordDirective } from 'app/shared/other/password.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    LocalDatePipe,
    LocalNumberPipe,
    AutofocusDirective,
    AutogrowDirective,
    PasswordDirective,
    SecurePipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      enableHtml: true,
      extendedTimeOut: 3000,
      progressBar: true,
      closeButton: true,
      maxOpened: 5,
      autoDismiss: true,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
  ],
  exports: [
    TranslateModule,
    TypeaheadModule,
    ToastrModule,
    LocalDatePipe,
    LocalNumberPipe,
    AutofocusDirective,
    AutogrowDirective,
    PasswordDirective,
    SecurePipe
  ],
  providers: [
    Globals,
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
})
export class SharedModule { }
