<div class="content-wrapper">

  <app-header-content header="Informe de mercado"></app-header-content>
  <app-spinner *ngIf="isLoading"></app-spinner>

  <section class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="reportForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Body -->
      <div class="box-body">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">Datos del inmueble</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">

            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('ref')">
                <label class="control-label" for="ref">
                  Referencia:
                </label>
                <input
                  formControlName="ref"
                  placeholder="Referencia catastral"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('ref')?.msg|translate:err('ref')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->

        </div>

      </div> <!-- end Body -->

      <!-- Button bar -->
      <div class="box-footer">
        <div class="btn-grp">
          <button [disabled]="reportForm.invalid" (click)="report()" type="submit" class="btn btn-primary">Informe</button>
        </div>
      </div> <!-- end Button bar -->

    </div> <!-- end Panel -->

    </form> 

  </div>
  </div>
  </section> <!-- end Section -->

</div>