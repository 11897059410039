import { Audit } from 'app/shared/model/audit';

// Tasktype class
export class Tasktype {

  // Key
  id: number;

  // Fields
  name: string;

  // Audit
  audit : Audit = new Audit();
}

