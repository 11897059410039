import { Agent } from 'app/crud/sales/agent/agent';
import { Hometype } from 'app/crud/sales/hometype/hometype';
import { Orientation } from 'app/crud/sales/orientation/orientation';
import { Salestatus } from 'app/crud/sales/salestatus/salestatus';
import { Source } from 'app/crud/sales/source/source';
import { Status } from 'app/crud/sales/status/status';
import { Tag } from 'app/crud/sales/tag/tag';
import { Why } from 'app/crud/sales/why/why';
import { Zone } from 'app/crud/sales/zone/zone';
import { Audit } from 'app/shared/model/audit';

// Sale class
export class Sale {

  // Key
  id: number;

  // Fields
  reference: string;
  address: string;
  zip: string;
  zone: Zone[]; // = new Zone();
  agent: Agent; // = new Agent();
  source: Source; // = new Source();
  salestatus: Salestatus; // = new Salestatus();
  lastvaluation: string;
  statusdate: string;
  hometype: Hometype; // = new Hometype();
  comments: string;
  tags: Tag[]; // = new Tag();
  lat: number;
  lon: number;
  description: string;
  details: string;
  rooms: number;
  baths: number;
  area: number;
  terrace: number;
  garage: number;
  exterior: string;
  elevator: string;
  pool: string;
  orientation: Orientation; // = new Orientation();
  status: Status; // = new Status();
  why: Why; // = new Why();

  // Audit
  audit : Audit = new Audit();
}

