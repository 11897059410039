// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Purchasedocument } from 'app/crud/sales/purchasedocument/purchasedocument';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class PurchasedocumentService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all purchasedocuments
  readAll(sort: string): Observable<Page<Purchasedocument>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of purchasedocuments
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Purchasedocument>> {
    let url = `${this.globals.server}purchasedocuments?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Purchasedocument>>(url).pipe(
      // tap(_ => console.log('read purchasedocuments')),
      catchError(this.handleError<Page<Purchasedocument>>('read Purchasedocument'))
    );
  }

  // Read pages of purchasedocuments from purchases, purchases cannot have composite keys
  readFromPurchase(purchase: number, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Purchasedocument>> {
    let url = `${this.globals.server}purchases/${purchase}/purchasedocuments?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Purchasedocument>>(url).pipe(
      // tap(_ => console.log('read purchasedocuments')),
      catchError(this.handleError<Page<Purchasedocument>>('read Purchasedocument'))
    );
  }

  // Read one purchasedocument
  readOne(id: number): Observable<Purchasedocument> {
    return this.http.get<Purchasedocument>(`${this.globals.server}purchasedocuments/${id}`).pipe(
      // tap(_ => console.log(`readOne purchasedocument ${id} `)),
      catchError(this.handleError<Purchasedocument>(`readOne Purchasedocument ${id} `))
    );
  }

  // Count purchasedocuments
  count(): Observable<Purchasedocument[]> {
    return this.http.get<Purchasedocument[]>(`${this.globals.server}purchasedocuments/count`).pipe(
      // tap(_ => console.log('count purchasedocuments')),
      catchError(this.handleError('count Purchasedocument', []))
    );
  }

  // Create purchasedocument
  create(o: Purchasedocument): Observable<Purchasedocument> {
    const input = new FormData();
    if (o.file) { input.append('file', o.file, o.filename); }
    delete o.file;
    input.append('data', JSON.stringify(o));
    return this.http.post<Purchasedocument>(`${this.globals.server}purchasedocuments`, input).pipe(
      // tap(_ => console.log('create purchasedocument')),
      catchError(this.handleError<Purchasedocument>('create Purchasedocument'))
    );
  }

  // Update purchasedocument
  update(id: number, o: Purchasedocument): Observable<any> {
    const input = new FormData();
    if (o.file) { input.append('file', o.file, o.filename); }
    delete o.file;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}purchasedocuments/${id}`, input).pipe(
      // tap(_ => console.log(`update purchasedocument ${id} `)),
      catchError(this.handleError<any>(`update Purchasedocument ${id} `))
    );
  }

  // Delete purchasedocument
  delete(id: number): Observable<Purchasedocument> {
    return this.http.delete<Purchasedocument>(`${this.globals.server}purchasedocuments/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete purchasedocument ${id} `)),
      catchError(this.handleError<Purchasedocument>(`delete Purchasedocument ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
