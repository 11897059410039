<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.SALECONTACT'|translate" [optional]="'LABEL.ENTITY.SALECONTACT.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="salecontactForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.SALECONTACT'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALECONTACT.EMAIL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('email')">
                <label class="control-label" for="email">
                  {{ 'LABEL.FIELD.SALECONTACT.EMAIL'|translate }}
                </label>
                <input
                  formControlName="email"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.EMAIL':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('email')?.msg|translate:err('email')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('crmid')">
                <label class="control-label" for="crmid">
                  {{ 'LABEL.FIELD.SALECONTACT.CRMID'|translate }}
                </label>
                <input
                  formControlName="crmid"
                  maxlength="8"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.CRMID':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('crmid')?.msg|translate:err('crmid')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-1">
              <div class="form-group" [class.has-error]="err('rgpd')">
                <label class="control-label" for="rgpd">
                  {{ 'LABEL.FIELD.SALECONTACT.RGPD'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="rgpd" id="rgpd" 
                    type="checkbox">
                  <label for="rgpd">{{ 'LABEL.FIELD.SALECONTACT.RGPD'|translate }}</label>
                </div>
                <span class="help-block">{{err('rgpd')?.msg|translate:err('rgpd')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-1">
              <div class="form-group" [class.has-error]="err('confirm')">
                <label class="control-label" for="confirm">
                  {{ 'LABEL.FIELD.SALECONTACT.CONFIRM'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="confirm" id="confirm" 
                    type="checkbox">
                  <label for="confirm">{{ 'LABEL.FIELD.SALECONTACT.CONFIRM'|translate }}</label>
                </div>
                <span class="help-block">{{err('confirm')?.msg|translate:err('confirm')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('contacttype')">
                <label class="control-label" for="contacttype">
                  {{ 'LABEL.FIELD.SALECONTACT.CONTACTTYPE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="contacttype"
                  multiple="true"
                  clearable="false"
                  [items]="contacttypeoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.CONTACTTYPE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('contacttype')?.msg|translate:err('contacttype')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('source')">
                <label class="control-label" for="source">
                  {{ 'LABEL.FIELD.SALECONTACT.SOURCE'|translate }}
                </label>
                <ng-select 
                  formControlName="source"
                  [items]="sourceoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.SOURCE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('source')?.msg|translate:err('source')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('why')">
                <label class="control-label" for="why">
                  {{ 'LABEL.FIELD.SALECONTACT.WHY'|translate }}
                </label>
                <ng-select 
                  formControlName="why"
                  [items]="whyoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.WHY':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('why')?.msg|translate:err('why')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('timeframe')">
                <label class="control-label" for="timeframe">
                  {{ 'LABEL.FIELD.SALECONTACT.TIMEFRAME'|translate }}
                </label>
                <ng-select 
                  formControlName="timeframe"
                  [items]="timeframeoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.TIMEFRAME':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('timeframe')?.msg|translate:err('timeframe')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALECONTACT.DETAIL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('name')">
                <label class="control-label" for="name">
                  {{ 'LABEL.FIELD.SALECONTACT.NAME'|translate }}
                </label>
                <input
                  formControlName="name"
                  maxlength="50"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.NAME':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('phone')">
                <label class="control-label" for="phone">
                  {{ 'LABEL.FIELD.SALECONTACT.PHONE'|translate }}
                </label>
                <input
                  formControlName="phone"
                  maxlength="20"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.PHONE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('phone')?.msg|translate:err('phone')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-6">
              <div class="form-group" [class.has-error]="err('address')">
                <label class="control-label" for="address">
                  {{ 'LABEL.FIELD.SALECONTACT.ADDRESS'|translate }}
                </label>
                <input
                  formControlName="address"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.ADDRESS':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('zip')">
                <label class="control-label" for="zip">
                  {{ 'LABEL.FIELD.SALECONTACT.ZIP'|translate }}
                </label>
                <input
                  formControlName="zip"
                  maxlength="5"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.ZIP':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('zip')?.msg|translate:err('zip')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-3">
              <div class="form-group" [class.has-error]="err('city')">
                <label class="control-label" for="city">
                  {{ 'LABEL.FIELD.SALECONTACT.CITY'|translate }}
                </label>
                <input
                  formControlName="city"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.CITY':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('city')?.msg|translate:err('city')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALECONTACT.COMMENTS'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('comments')">
                <label class="control-label" for="comments">
                  {{ 'LABEL.FIELD.SALECONTACT.COMMENTS'|translate }}
                </label>
                <textarea
                  formControlName="comments"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="2000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALECONTACT.COMMENTS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="salecontactForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:SALECONTACT')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="salecontactForm.invalid || salecontactForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:SALECONTACT')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:SALECONTACT')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->


    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
