// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Command } from 'app/crud/sales/command/command';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class CommandService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all commands
  readAll(sort: string): Observable<Page<Command>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of commands
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Command>> {
    let url = `${this.globals.server}commands?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Command>>(url).pipe(
      // tap(_ => console.log('read commands')),
      catchError(this.handleError<Page<Command>>('read Command'))
    );
  }

  // Read one command
  readOne(instruction: string): Observable<Command> {
    return this.http.get<Command>(`${this.globals.server}commands/${instruction}`).pipe(
      // tap(_ => console.log(`readOne command ${instruction} `)),
      catchError(this.handleError<Command>(`readOne Command ${instruction} `))
    );
  }

  // Count commands
  count(): Observable<Command[]> {
    return this.http.get<Command[]>(`${this.globals.server}commands/count`).pipe(
      // tap(_ => console.log('count commands')),
      catchError(this.handleError('count Command', []))
    );
  }

  // Create command
  create(o: Command): Observable<Command> {
    return this.http.post<Command>(`${this.globals.server}commands`, o, httpOptions).pipe(
      // tap(_ => console.log('create command')),
      catchError(this.handleError<Command>('create Command'))
    );
  }

  // Update command
  update(instruction: string, o: Command): Observable<any> {
    return this.http.put(`${this.globals.server}commands/${instruction}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update command ${instruction} `)),
      catchError(this.handleError<any>(`update Command ${instruction} `))
    );
  }

  // Delete command
  delete(instruction: string): Observable<Command> {
    return this.http.delete<Command>(`${this.globals.server}commands/${instruction}`, httpOptions).pipe(
      // tap(_ => console.log(`delete command ${instruction} `)),
      catchError(this.handleError<Command>(`delete Command ${instruction} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
