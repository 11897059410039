// Angular and infastructure imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Security
import { AuthService } from 'app/shared/services/auth.service';

// Layouts
import { BlankLayoutComponent } from 'app/layout/blank/blank.component';
import { FramedLayoutComponent } from 'app/layout/framed/framed.component';

// Application components imports
import { RootComponent } from 'app/general/root/root.component';
import { LoginComponent } from 'app/general/login/login.component';
import { LogoutComponent } from 'app/general/logout/logout.component';
import { HomeComponent } from 'app/general/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: '', component: RootComponent },
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LogoutComponent }
    ]
  },
  {
    path: '',
    component: FramedLayoutComponent,
    children: [
      { path: 'home', canActivate: [AuthService], component: HomeComponent },
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class GeneralRoutingModule { }
