<a href="#" class="dropdown-toggle" data-toggle="dropdown">
  <img src="{{ photo }}" class="user-image" alt="{{ login.principal }}">
  <span class="hidden-xs">{{ login.principal }}</span>
</a>

<ul class="dropdown-menu">

  <li class="user-header">
    <img src="{{ photo }}" class="img-circle" alt="User Image">
    <p>{{ login.principal }}<small>{{ login.id }} | {{ login.type }}</small></p>
  </li>

  <li class="user-footer">
<!-- 
    <div class="pull-left">
      <a class="btn btn-default btn-flat">Profile</a>
    </div>
-->
    <div class="pull-right">
      <a [routerLink]="['/logout']" class="btn btn-default btn-flat">Sign out</a>
    </div>
  </li>
  
</ul>