import { Doctype } from 'app/crud/sales/doctype/doctype';
import { Purchase } from 'app/crud/sales/purchase/purchase';
import { Audit } from 'app/shared/model/audit';

// Purchasedocument class
export class Purchasedocument {

  // Key
  id: number;

  // Fields
  purchase: Purchase; // = new Purchase();
  doctype: Doctype; // = new Doctype();
  details: string;
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;

  // Audit
  audit : Audit = new Audit();
}

