import { Contacttype } from 'app/crud/sales/contacttype/contacttype';
import { Sale } from 'app/crud/sales/sale/sale';
import { Source } from 'app/crud/sales/source/source';
import { Timeframe } from 'app/crud/sales/timeframe/timeframe';
import { Why } from 'app/crud/sales/why/why';
import { Audit } from 'app/shared/model/audit';

// Salecontact class
export class Salecontact {

  // Key
  id: number;

  // Fields
  sale: Sale; // = new Sale();
  email: string;
  contacttype: Contacttype[]; // = new Contacttype();
  crmid: string;
  source: Source; // = new Source();
  confirm: string;
  rgpd: string;
  name: string;
  phone: string;
  address: string;
  zip: string;
  city: string;
  comments: string;
  why: Why; // = new Why();
  timeframe: Timeframe; // = new Timeframe();

  // Audit
  audit : Audit = new Audit();
}

