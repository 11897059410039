import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { SaledocumentService } from 'app/crud/sales/saledocument/saledocument.service';
import { Saledocument } from 'app/crud/sales/saledocument/saledocument';

import { DoctypeService } from 'app/crud/sales/doctype/doctype.service';
import { Doctype } from 'app/crud/sales/doctype/doctype';
import { SaleService } from 'app/crud/sales/sale/sale.service';
import { Sale } from 'app/crud/sales/sale/sale';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-saledocument-sale-form',
  templateUrl: './saledocument-sale-form.component.html',
})
export class SaledocumentSaleFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  doctypeoptions: Doctype[] = [];

  // Form
  saledocumentForm = this.fb.group({
    // Fields
    doctype: [{ value: '', disabled: false }, []],
    details: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    file: [{ value: '', disabled: false }, []],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Saledocument = new Saledocument();

  // Loading indicators
  isLoading = false;
  fileLoaded = false;

  // Components constructor
  constructor(
    private apiDoctype: DoctypeService,
    private apiSale: SaleService,
    private api: SaledocumentService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Saledocument();
    this.data.doctype = new Doctype();

    // Set the master relation field
    this.data.sale = new Sale();
    this.data.sale.id = this.route.snapshot.params.sale;
    this.mapDataToForm(this.data, this.saledocumentForm);

    // Load doctype lookup
    this.loadDoctype();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.saledocumentForm.controls).forEach(key => {
        this.saledocumentForm.get(key).disable();
      });
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: Saledocument, form: FormGroup) {
    if (data.doctype) { form.get('doctype').setValue(data.doctype.id); } else { data.doctype = new Doctype(); }
    form.get('details').setValue(data.details);
  }

  mapFormToData(form: FormGroup, data: Saledocument) {
    if (!form.get('doctype').value) { data.doctype = null; } else { data.doctype.id = form.get('doctype').value; }
    data.details = form.get('details').value;
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.saledocumentForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'SALEDOCUMENT', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.saledocumentForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'SALEDOCUMENT');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'SALEDOCUMENT', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.saledocumentForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'SALEDOCUMENT');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'SALEDOCUMENT', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.SALEDOCUMENT');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'SALEDOCUMENT');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'SALEDOCUMENT', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Selected file
  onFileChange(field, event) {
    if (event.target.files && event.target.files.length) {
      this.data[field] = event.target.files[0];
      this.data[field + 'type'] = event.target.files[0].type;
      this.data[field + 'name'] = event.target.files[0].name;
      this.saledocumentForm.markAsDirty();
    }
  }

  // Delete file
  onFileDelete(field, event) {
    this.data[field] = null;
    this.data[field + 'type'] = null;
    this.data[field + 'name'] = '{Delete}';
    this.saledocumentForm.markAsDirty();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.saledocumentForm, name);
  }

  // Load doctype lookup
  loadDoctype() {
    this.apiDoctype.readAll('+name').subscribe(
      res => {
        this.doctypeoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
