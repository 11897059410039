import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { PrivilegeService } from 'app/crud/system/privilege/privilege.service';
import { Privilege } from 'app/crud/system/privilege/privilege';

import { RoleService } from 'app/crud/system/role/role.service';
import { Role } from 'app/crud/system/role/role';

@Component({
  selector: 'app-privilege-role-table',
  templateUrl: './privilege-role-table.component.html',
})
export class PrivilegeRoleTableComponent implements OnInit {

  // Select fields
  roleoptions: Role[] = [];
  resourceoptions: string[] = ['ANY', 'LOGIN', 'ENTRY', 'CONTACT', 'CONTENT', 'IMAGE', 'SALE', 'SALECONTACT', 'SALEDOCUMENT', 'SALEVALUATION', 'SALETASK', 'PURCHASE', 'PURCHASEDOCUMENT', 'PURCHASETASK', 'COMMAND', 'AGENT', 'HOMETYPE', 'DOCTYPE', 'TASKTYPE', 'CONTACTTYPE', 'SALESTATUS', 'PURCHASESTATUS', 'SOURCE', 'ZONE', 'ORIENTATION', 'WHY', 'TIMEFRAME', 'STATUS', 'TAG', 'PRINCIPAL', 'PRIVILEGE', 'GROUP', 'ROLE', 'CONFIG'];
  actionoptions: string[] = ['ANY', 'READ', 'VIEW', 'CREATE', 'UPDATE', 'DELETE'];

  // Parent
  @Input() parent: string;
  @Input() value: any;

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    role_id: '',
    role_name: '',
    resource: '',
    action: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  //? sort = 'role';
  //? dir = '+';
  sort = '';
  dir = '';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Privilege>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiRole: RoleService,
    private api: PrivilegeService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'PrivilegeRole') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load role lookup
    this.loadRole();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.readFromRole(this.value, this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'PRIVILEGE', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'PrivilegeRole';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'role.id', this.search.role_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'role.name', this.search.role_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'resource', this.search.resource, 'eq', this.session.lang.code, 'Resource');
    Globals.pushFilter(f, 'action', this.search.action, 'eq', this.session.lang.code, 'Action');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'PrivilegeRole';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.role_id = '';
    this.search.role_name = '';
    this.search.resource = '';
    this.search.action = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load role lookup
  loadRole() {
    this.apiRole.readAll('').subscribe(
      res => {
        this.roleoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'ROLE', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
