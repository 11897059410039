import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-header-languages]',
  templateUrl: './header-languages.component.html',
  styleUrls: ['./header-languages.component.scss']
})
export class HeaderLanguagesComponent implements OnInit {

  constructor(
    public globals: Globals,
    public session: SessionService,
    private translate: TranslateService) {
  }

  ngOnInit() {
  }

  changeLang(lang: string) {
    this.session.setLang(lang);
  }
}
