// Angular common modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Shared modules
import { SharedModule } from 'app/shared/shared.module';

// Layouts
import { FramedLayoutComponent } from 'app/layout/framed/framed.component';
import { BlankLayoutComponent } from 'app/layout/blank/blank.component';

// Layout elements
import { FooterComponent } from 'app/layout/parts/footer/footer.component';
import { HeaderComponent } from 'app/layout/parts/header/header.component';
import { HeaderContentComponent } from 'app/layout/parts/header/header-content/header-content.component';
import { HeaderLanguagesComponent } from 'app/layout/parts/header/header-languages/header-languages.component';
import { HeaderMessagesComponent } from 'app/layout/parts/header/header-messages/header-messages.component';
import { HeaderNotificationsComponent } from 'app/layout/parts/header/header-notifications/header-notifications.component';
import { HeaderTasksComponent } from 'app/layout/parts/header/header-tasks/header-tasks.component';
import { HeaderUserComponent } from 'app/layout/parts/header/header-user/header-user.component';
import { SidebarComponent } from 'app/layout/parts/sidebar/sidebar.component';
import { SidebarMenuComponent } from 'app/layout/parts/sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarSearchComponent } from 'app/layout/parts/sidebar/sidebar-search/sidebar-search.component';
import { SidebarSettingsComponent } from 'app/layout/parts/sidebar/sidebar-settings/sidebar-settings.component';
import { SidebarUserComponent } from 'app/layout/parts/sidebar/sidebar-user/sidebar-user.component';
import { PaginationComponent } from 'app/layout/parts/pagination/pagination.component';

// Confirm dialog
import { ConfirmDialogComponent } from 'app/layout/modal/confirm-dialog.component';
import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';

// Spinner
import { SpinnerComponent } from 'app/layout/spinner/spinner.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HeaderContentComponent,
    HeaderLanguagesComponent,
    HeaderMessagesComponent,
    HeaderNotificationsComponent,
    HeaderTasksComponent,
    HeaderUserComponent,
    SidebarComponent,
    SidebarMenuComponent,
    SidebarSearchComponent,
    SidebarSettingsComponent,
    SidebarUserComponent,
    SpinnerComponent,
    FramedLayoutComponent,
    BlankLayoutComponent,
    PaginationComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    HeaderContentComponent,
    PaginationComponent,
    SpinnerComponent,
    ConfirmDialogComponent
  ],
  providers: [
    ConfirmDialogService
  ]
})
export class LayoutModule { }
