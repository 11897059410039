<div class="content-wrapper">

  <!-- Header -->
  <app-header-content [header]="'LABEL.ENTITY.PURCHASESTATUS'|translate" [optional]="'LABEL.ENTITY.PURCHASESTATUS.DESCRIPTION'|translate"></app-header-content>

  <!-- Section -->
  <section class="content">
  <div class="row">
  <div class="col-xs-12">
  <div class="box box-primary">

	<!-- Loading spinner -->
	<app-spinner *ngIf="isLoading"></app-spinner>

	<!-- Data -->
	<div *ngIf="data">

	  <!-- Header -->
	  <div class="box-header with-border">
		<h3 class="box-title">{{ 'LABEL.ENTITY.PURCHASESTATUS.TABLE'|translate }}</h3>
		<button *ngIf="mode!='view' && auth.canShow('CREATE:PURCHASESTATUS')"
		  [routerLink]="['/purchasestatuses', 'add']"
		  type="button" class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }} {{ 'LABEL.ENTITY.PURCHASESTATUS'|translate }}</button>
	  </div> <!-- end Header -->
	
	  <!-- Body -->
	  <div class="box-body dataTables_wrapper form-inline dt-bootstrap">

		<!-- Table -->
		<div class="row">
		<div class="col-sm-12">
		<table id="table-purchasestatus" class="table table-bordered table-hover dataTable" role="grid">

		  <!-- Titles row -->	
		  <thead>
			<tr role="row">
			  <!-- <th style="width:1%;"><div class="checkbox"><input type="checkbox"><label></label></div></th> -->
			  <th class="col-lg-1 " [ngClass]="isSorted('id')" (click)="doSort('id')">
			    {{'LABEL.FIELD.PURCHASESTATUS.ID'|translate}}
			  </th>
			  <th class="col-lg-1 " [ngClass]="isSorted('name')" (click)="doSort('name')">
			    {{'LABEL.FIELD.PURCHASESTATUS.NAME'|translate}}
			  </th>
			  <th style="width:1%;">{{'LABEL.ACTIONS'|translate}}</th>
			</tr>
		  </thead>

		  <!-- Filters row -->	
		  <thead>
			<tr role="row">
			  <!-- <th></th> -->
			  <th class="">
			    <input 
			  	[(ngModel)]="search.id" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.PURCHASESTATUS.ID'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th class="">
			    <input 
			  	[(ngModel)]="search.name" (input)="doFilter($event)" 
			  	placeholder="{{ 'MESSAGE.SELECT'|translate }} {{ 'LABEL.FIELD.PURCHASESTATUS.NAME'|translate }}"
			  	type="text" class="form-control" autocomplete="off" style="width: 100%;">
			  </th>
			  <th>
				<button (click)="clearFilter()" type="button" class="btn btn-default btn-sm">
				  <i class="fa fa-filter" style="position: relative;">
					<i class="fa fa-ban" style="position: absolute; left: 0px; color:Tomato"></i>
				  </i>
				</button>
			  </th>
			</tr>
		  </thead>

		  <!-- Data rows -->					
		  <tbody>
			<tr role="row"[ngClass]="{ odd:odd, even:even }" *ngFor="let o of data.content; odd as odd; even as even">
			  <!-- <td><div class="checkbox"><input type="checkbox"><label></label></div></td> -->
			  <td class="">
			    {{ o.id }}
			  </td>
			  <td class="">
			    {{ o.name }}
			  </td>
			  <td>
				<div class="btn-group">
				  <button *ngIf="mode!='view' && auth.canShow('UPDATE:PURCHASESTATUS')"
					[routerLink]="['/purchasestatuses', o.id, 'edit']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i>
				  </button>
				  <button
					[routerLink]="['/purchasestatuses', o.id, 'view']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i>
				  </button>
				</div>
			  </td>
			</tr>
		  </tbody>

		</table>
		</div>
		</div> <!-- end Table -->
	
		<!-- Pagination -->
		<app-pagination [total]="data.totalElements" [size]="data.size" [num]="data.numberOfElements" (go)="doPage($event)"></app-pagination>
	
	  </div> <!-- end Body -->

	</div> <!-- end Data -->
  </div>
  </div>
  </div>
  </section> <!-- end Section --> 

</div> <!-- end content-wrapper -->
