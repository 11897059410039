import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Globals } from 'app/shared/services/globals.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() size: number;  // Page size
  @Input() total: number; // Total elements
  @Input() num: number;   // Current elements on page

  @Output() go = new EventEmitter<number>();
  
  currentPage: number;    // Current page (starting in 0)
  lastPage: number;       // Last page
  maxPages: number;       // Max visible pages (last page if pageable, last visited if sliceable)

  SHOW = 5;              // Page links to show
  MAX = 999999999;

  constructor(private globals: Globals) {}

  ngOnInit() {
    this.maxPages = this.num > 0 ? 0 : -1;
    this.lastPage = this.total === this.MAX ? 0 : Math.floor((this.total - 1) / this.size);
    this.currentPage = 0;
  }

  ngOnChanges() {
    this.maxPages = this.num > 0 ? 0 : -1;
    this.lastPage = this.total === this.MAX ? 0 : Math.floor((this.total - 1) / this.size);
    if (this.currentPage > this.lastPage) this.currentPage = 0;
  }

  // First record on the page
  from() {
    if (this.num > 0) {
      return 1 + this.currentPage * this.size;
    }
    return 0;
  }

  // Last record on the page
  to() {
    if (this.num > 0) {
      return this.from() + this.num - 1;
    }
    return this.from();
  }

  // Total number of records, if known
  of() {
    return this.total === this.MAX ? -1 : this.total;
  }

  // Is first page?
  isFirst() {
    return this.currentPage === 0;
  }

  // Is last page?
  isLast() {
    return this.currentPage === this.lastPage;
  }

  // Is a next page available
  // TO DO: fix
  hasNext() {
    return this.total === this.MAX || this.currentPage < this.lastPage;
  }

  // Go to first page
  doFirst() {
    this.doPage(0);
  }

  // Go to last page
  doLast() {
    this.doPage(this.lastPage);
  }

  // Go to previous page
  doPrev() {
    this.doPage(this.currentPage - 1);
  }

  // Go to next page
  doNext() {
    if (this.hasNext()) {
      this.doPage(this.currentPage + 1);
    }
  }

  // Go to indicated page
  doPage(page: number) {
    if (page < 0) { page = 0; }
    if (page > this.maxPages) { this.maxPages = page; }
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.go.emit(page);
    }
  }

  // Show up to N (should be odd number) pages around current one
  showPages() {
    const pmax = Math.max(this.lastPage + 1, this.maxPages + 1);
    const pmin = Math.min(Math.max(this.currentPage - ((this.SHOW - 1) / 2), 0), Math.max(pmax - this.SHOW, 0));
    const pages = [];
    for (let i = pmin; i < pmin + this.SHOW && i < pmax; i++) {
      pages.push(i);
    }
    return pages;
  }

}
