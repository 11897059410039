import { Component, Renderer2, OnDestroy } from '@angular/core';

@Component({
  templateUrl: './blank.component.html'
})
export class BlankLayoutComponent implements OnDestroy {

  constructor(private renderer: Renderer2) {
    // Change body class
    this.renderer.addClass(document.body, 'login-page');
  }

  ngOnDestroy() {
    // Remove body class
    this.renderer.removeClass(document.body, 'login-page');
  }

}
