import { Doctype } from 'app/crud/sales/doctype/doctype';
import { Sale } from 'app/crud/sales/sale/sale';
import { Audit } from 'app/shared/model/audit';

// Saledocument class
export class Saledocument {

  // Key
  id: number;

  // Fields
  sale: Sale; // = new Sale();
  doctype: Doctype; // = new Doctype();
  details: string;
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;

  // Audit
  audit : Audit = new Audit();
}

