<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.PRIVILEGE'|translate" [optional]="'LABEL.ENTITY.PRIVILEGE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="privilegeForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.PRIVILEGE'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('resource')">
              <label class="control-label" for="resource">
                {{ 'LABEL.FIELD.PRIVILEGE.RESOURCE'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="resource"
                clearable="false"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRIVILEGE.RESOURCE':'')|translate }}"
                style="width: 100%;">
                <ng-option *ngFor="let item of resourceoptions" value="{{ item|uppercase }}">{{ 'ENUM.RESOURCE.'+item|translate }}</ng-option>
              </ng-select>
              <span class="help-block">{{err('resource')?.msg|translate:err('resource')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Field row (2) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('action')">
              <label class="control-label" for="action">
                {{ 'LABEL.FIELD.PRIVILEGE.ACTION'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <ng-select 
                formControlName="action"
                clearable="false"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PRIVILEGE.ACTION':'')|translate }}"
                style="width: 100%;">
                <ng-option *ngFor="let item of actionoptions" value="{{ item|uppercase }}">{{ 'ENUM.ACTION.'+item|translate }}</ng-option>
              </ng-select>
              <span class="help-block">{{err('action')?.msg|translate:err('action')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="privilegeForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:PRIVILEGE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="privilegeForm.invalid || privilegeForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:PRIVILEGE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:PRIVILEGE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
