// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Entry } from 'app/crud/sales/entry/entry';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class EntryService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all entries
  readAll(sort: string): Observable<Page<Entry>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of entries
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Entry>> {
    let url = `${this.globals.server}entries?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Entry>>(url).pipe(
      // tap(_ => console.log('read entries')),
      catchError(this.handleError<Page<Entry>>('read Entry'))
    );
  }

  // Read one entry
  readOne(id: number): Observable<Entry> {
    return this.http.get<Entry>(`${this.globals.server}entries/${id}`).pipe(
      // tap(_ => console.log(`readOne entry ${id} `)),
      catchError(this.handleError<Entry>(`readOne Entry ${id} `))
    );
  }

  // Count entries
  count(): Observable<Entry[]> {
    return this.http.get<Entry[]>(`${this.globals.server}entries/count`).pipe(
      // tap(_ => console.log('count entries')),
      catchError(this.handleError('count Entry', []))
    );
  }

  // Create entry
  create(o: Entry): Observable<Entry> {
    return this.http.post<Entry>(`${this.globals.server}entries`, o, httpOptions).pipe(
      // tap(_ => console.log('create entry')),
      catchError(this.handleError<Entry>('create Entry'))
    );
  }

  // Update entry
  update(id: number, o: Entry): Observable<any> {
    return this.http.put(`${this.globals.server}entries/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update entry ${id} `)),
      catchError(this.handleError<any>(`update Entry ${id} `))
    );
  }

  // Delete entry
  delete(id: number): Observable<Entry> {
    return this.http.delete<Entry>(`${this.globals.server}entries/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete entry ${id} `)),
      catchError(this.handleError<Entry>(`delete Entry ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
