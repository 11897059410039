// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Sale } from 'app/crud/sales/sale/sale';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class SaleService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all sales
  readAll(sort: string): Observable<Page<Sale>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of sales
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Sale>> {
    let url = `${this.globals.server}sales?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Sale>>(url).pipe(
      // tap(_ => console.log('read sales')),
      catchError(this.handleError<Page<Sale>>('read Sale'))
    );
  }

  // Read one sale
  readOne(id: number): Observable<Sale> {
    return this.http.get<Sale>(`${this.globals.server}sales/${id}`).pipe(
      // tap(_ => console.log(`readOne sale ${id} `)),
      catchError(this.handleError<Sale>(`readOne Sale ${id} `))
    );
  }

  // Count sales
  count(): Observable<Sale[]> {
    return this.http.get<Sale[]>(`${this.globals.server}sales/count`).pipe(
      // tap(_ => console.log('count sales')),
      catchError(this.handleError('count Sale', []))
    );
  }

  // Create sale
  create(o: Sale): Observable<Sale> {
    return this.http.post<Sale>(`${this.globals.server}sales`, o, httpOptions).pipe(
      // tap(_ => console.log('create sale')),
      catchError(this.handleError<Sale>('create Sale'))
    );
  }

  // Update sale
  update(id: number, o: Sale): Observable<any> {
    return this.http.put(`${this.globals.server}sales/${id}`, o, httpOptions).pipe(
      // tap(_ => console.log(`update sale ${id} `)),
      catchError(this.handleError<any>(`update Sale ${id} `))
    );
  }

  // Delete sale
  delete(id: number): Observable<Sale> {
    return this.http.delete<Sale>(`${this.globals.server}sales/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete sale ${id} `)),
      catchError(this.handleError<Sale>(`delete Sale ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
