<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.SALE'|translate" [optional]="'LABEL.ENTITY.SALE.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="saleForm" focus>

    <!-- Tabbed panel-->
    <div class="nav-tabs-custom">

      <!-- Tabs labels -->
      <ul class="nav nav-tabs">
        <li class="pull-right header">{{ 'LABEL.ENTITY.SALE'|translate }}</li>
        <li class="active">
          <a href="#tabhome" data-toggle="tab">{{ 'LABEL.TAB.SALE.HOME'|translate }}</a>
        </li>
        <li>
          <a href="#tabfeatures" data-toggle="tab">{{ 'LABEL.TAB.SALE.FEATURES'|translate }}</a>
        </li>
      </ul> <!-- end Tabbed panel -->

      <!-- Tabs content -->
      <div class="tab-content">

      <!-- Tab home -->
      <div class="active tab-pane" id="tabhome">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALE.GENERAL'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('reference')">
                <label class="control-label" for="reference">
                  {{ 'LABEL.FIELD.SALE.REFERENCE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="reference"
                  maxlength="30"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.REFERENCE':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('reference')?.msg|translate:err('reference')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('agent')">
                <label class="control-label" for="agent">
                  {{ 'LABEL.FIELD.SALE.AGENT'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="agent"
                  clearable="false"
                  [items]="agentoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.AGENT':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('agent')?.msg|translate:err('agent')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('salestatus')">
                <label class="control-label" for="salestatus">
                  {{ 'LABEL.FIELD.SALE.SALESTATUS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="salestatus"
                  clearable="false"
                  [items]="salestatusoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.SALESTATUS':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('salestatus')?.msg|translate:err('salestatus')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('statusdate')">
                <label class="control-label" for="statusdate">
                  {{ 'LABEL.FIELD.SALE.STATUSDATE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <div class="input-group">
                <input
                  formControlName="statusdate"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.STATUSDATE':'')|translate }}"
                  type="text"
                  bsDatepicker
                  [bsConfig]="{containerClass:'theme-dark-blue'}"
                  #statusdate="bsDatepicker"
                  class="form-control" autocomplete="off" >
                <div class="input-group-addon" (click)="statusdate.toggle()"><i class="fa fa-calendar"></i></div>
                </div> <!-- End input group -->
                <span class="help-block">{{err('statusdate')?.msg|translate:err('statusdate')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('source')">
                <label class="control-label" for="source">
                  {{ 'LABEL.FIELD.SALE.SOURCE'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="source"
                  clearable="false"
                  [items]="sourceoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.SOURCE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('source')?.msg|translate:err('source')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('why')">
                <label class="control-label" for="why">
                  {{ 'LABEL.FIELD.SALE.WHY'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="why"
                  clearable="false"
                  [items]="whyoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.WHY':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('why')?.msg|translate:err('why')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('comments')">
                <label class="control-label" for="comments">
                  {{ 'LABEL.FIELD.SALE.COMMENTS'|translate }}
                </label>
                <textarea
                  formControlName="comments"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="2000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.COMMENTS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('comments')?.msg|translate:err('comments')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('tags')">
                <label class="control-label" for="tags">
                  {{ 'LABEL.FIELD.SALE.TAGS'|translate }}
                </label>
                <ng-select 
                  formControlName="tags"
                  multiple="true"
                  [items]="tagsoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.TAGS':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('tags')?.msg|translate:err('tags')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALE.ADDRESS'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('address')">
                <label class="control-label" for="address">
                  {{ 'LABEL.FIELD.SALE.ADDRESS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="address"
                  maxlength="200"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.ADDRESS':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('address')?.msg|translate:err('address')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('zip')">
                <label class="control-label" for="zip">
                  {{ 'LABEL.FIELD.SALE.ZIP'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <input
                  formControlName="zip"
                  maxlength="5"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.ZIP':'')|translate }}"
                  type="text"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('zip')?.msg|translate:err('zip')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('zone')">
                <label class="control-label" for="zone">
                  {{ 'LABEL.FIELD.SALE.ZONE'|translate }}
                </label>
                <ng-select 
                  formControlName="zone"
                  multiple="true"
                  [items]="zoneoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.ZONE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('zone')?.msg|translate:err('zone')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('lat')">
                <label class="control-label" for="lat">
                  {{ 'LABEL.FIELD.SALE.LAT'|translate }}
                </label>
                <input
                  formControlName="lat"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.LAT':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('lat')?.msg|translate:err('lat')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('lon')">
                <label class="control-label" for="lon">
                  {{ 'LABEL.FIELD.SALE.LON'|translate }}
                </label>
                <input
                  formControlName="lon"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.LON':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('lon')?.msg|translate:err('lon')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

      </div> <!-- end Tab -->
      <!-- Tab features -->
      <div class="tab-pane" id="tabfeatures">

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALE.FEATURES'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('hometype')">
                <label class="control-label" for="hometype">
                  {{ 'LABEL.FIELD.SALE.HOMETYPE'|translate }}
                </label>
                <ng-select 
                  formControlName="hometype"
                  [items]="hometypeoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.HOMETYPE':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('hometype')?.msg|translate:err('hometype')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-4">
              <div class="form-group" [class.has-error]="err('status')">
                <label class="control-label" for="status">
                  {{ 'LABEL.FIELD.SALE.STATUS'|translate }} <i class="text-danger fa fa-exclamation"></i>
                </label>
                <ng-select 
                  formControlName="status"
                  clearable="false"
                  [items]="statusoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.STATUS':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('status')?.msg|translate:err('status')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('area')">
                <label class="control-label" for="area">
                  {{ 'LABEL.FIELD.SALE.AREA'|translate }}
                </label>
                <input
                  formControlName="area"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.AREA':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('area')?.msg|translate:err('area')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('rooms')">
                <label class="control-label" for="rooms">
                  {{ 'LABEL.FIELD.SALE.ROOMS'|translate }}
                </label>
                <input
                  formControlName="rooms"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.ROOMS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('rooms')?.msg|translate:err('rooms')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('baths')">
                <label class="control-label" for="baths">
                  {{ 'LABEL.FIELD.SALE.BATHS'|translate }}
                </label>
                <input
                  formControlName="baths"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.BATHS':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('baths')?.msg|translate:err('baths')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('exterior')">
                <label class="control-label" for="exterior">
                  {{ 'LABEL.FIELD.SALE.EXTERIOR'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="exterior" id="exterior" 
                    type="checkbox">
                  <label for="exterior">{{ 'LABEL.FIELD.SALE.EXTERIOR'|translate }}</label>
                </div>
                <span class="help-block">{{err('exterior')?.msg|translate:err('exterior')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('elevator')">
                <label class="control-label" for="elevator">
                  {{ 'LABEL.FIELD.SALE.ELEVATOR'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="elevator" id="elevator" 
                    type="checkbox">
                  <label for="elevator">{{ 'LABEL.FIELD.SALE.ELEVATOR'|translate }}</label>
                </div>
                <span class="help-block">{{err('elevator')?.msg|translate:err('elevator')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('pool')">
                <label class="control-label" for="pool">
                  {{ 'LABEL.FIELD.SALE.POOL'|translate }}
                </label>
                <div class="checkbox">
                  <input
                    formControlName="pool" id="pool" 
                    type="checkbox">
                  <label for="pool">{{ 'LABEL.FIELD.SALE.POOL'|translate }}</label>
                </div>
                <span class="help-block">{{err('pool')?.msg|translate:err('pool')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('terrace')">
                <label class="control-label" for="terrace">
                  {{ 'LABEL.FIELD.SALE.TERRACE'|translate }}
                </label>
                <input
                  formControlName="terrace"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.TERRACE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('terrace')?.msg|translate:err('terrace')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('garage')">
                <label class="control-label" for="garage">
                  {{ 'LABEL.FIELD.SALE.GARAGE'|translate }}
                </label>
                <input
                  formControlName="garage"
                  
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.GARAGE':'')|translate }}"
                  type="number"
                  class="form-control" autocomplete="off" >
                <span class="help-block">{{err('garage')?.msg|translate:err('garage')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
            <!-- Field -->
            <div class="col-xs-2">
              <div class="form-group" [class.has-error]="err('orientation')">
                <label class="control-label" for="orientation">
                  {{ 'LABEL.FIELD.SALE.ORIENTATION'|translate }}
                </label>
                <ng-select 
                  formControlName="orientation"
                  [items]="orientationoptions"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.ORIENTATION':'')|translate }}"
                  style="width: 100%;">
                </ng-select>
                <span class="help-block">{{err('orientation')?.msg|translate:err('orientation')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

        <!-- Header -->
        <div class="box-header with-border">
          <h3 class="box-title">{{ 'LABEL.PANEL.SALE.DESCRIPTION'|translate }}</h3>
        </div> <!-- end Header -->

        <!-- Body -->
        <div class="box-body">

          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('description')">
                <label class="control-label" for="description">
                  {{ 'LABEL.FIELD.SALE.DESCRIPTION'|translate }}
                </label>
                <textarea
                  formControlName="description"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="1000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.DESCRIPTION':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('description')?.msg|translate:err('description')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
          <!-- Row -->
          <div class="row">
          
            <!-- Field -->
            <div class="col-xs-12">
              <div class="form-group" [class.has-error]="err('details')">
                <label class="control-label" for="details">
                  {{ 'LABEL.FIELD.SALE.DETAILS'|translate }}
                </label>
                <textarea
                  formControlName="details"
                  autogrow [maximumHeight]="300" [minimumHeight]="60"
                  (keydown.enter)="$event.stopPropagation();"
                  maxlength="4000"
                  placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.SALE.DETAILS':'')|translate }}"
                  class="form-control" autocomplete="off">
                </textarea>
                <span class="help-block">{{err('details')?.msg|translate:err('details')?.param}}</span>
              </div>
            </div> <!-- end Field -->
          
          </div> <!-- end Row -->
          
        </div> <!-- end Body -->

      </div> <!-- end Tab -->
<!-- Button bar -->
<div class="box-footer">
  <div class="btn-grp">
    <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
    <button [disabled]="saleForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:SALE')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
    <button [disabled]="saleForm.invalid || saleForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:SALE')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
    <button *ngIf="mode=='edit' && auth.canShow('DELETE:SALE')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
  </div>
</div> <!-- end Button bar -->
      </div> <!-- end Tabs content -->

    </div> <!-- end Tabbed panel -->


    </form>
        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs labels -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabSalecontact" data-toggle="tab">{{ 'LABEL.ENTITY.SALECONTACT'|translate }}</a>
            </li>
            <li>
              <a href="#tabSaledocument" data-toggle="tab">{{ 'LABEL.ENTITY.SALEDOCUMENT'|translate }}</a>
            </li>
            <li>
              <a href="#tabSalevaluation" data-toggle="tab">{{ 'LABEL.ENTITY.SALEVALUATION'|translate }}</a>
            </li>
            <li>
              <a href="#tabSaletask" data-toggle="tab">{{ 'LABEL.ENTITY.SALETASK'|translate }}</a>
            </li>
          </ul>

            <!-- Tabs content -->
            <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabSalecontact">
              <div class="box-body">
              <app-salecontact-sale-table *ngIf="mode!='add' && data.id" 
                parent="sales" 
                [value]="data.id">
              </app-salecontact-sale-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabSaledocument">
              <div class="box-body">
              <app-saledocument-sale-table *ngIf="mode!='add' && data.id" 
                parent="sales" 
                [value]="data.id">
              </app-saledocument-sale-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabSalevaluation">
              <div class="box-body">
              <app-salevaluation-sale-table *ngIf="mode!='add' && data.id" 
                parent="sales" 
                [value]="data.id">
              </app-salevaluation-sale-table>
              </div>
            </div> <!-- end tab -->
    
            <!-- Tab -->
            <div class=" tab-pane" id="tabSaletask">
              <div class="box-body">
              <app-saletask-sale-table *ngIf="mode!='add' && data.id" 
                parent="sales" 
                [value]="data.id">
              </app-saletask-sale-table>
              </div>
            </div> <!-- end tab -->
    
            </div> <!-- end tab-content -->

        </div>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
