import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { HttpClient } from '@angular/common/http';
import { Menu } from 'app/shared/model/menu';
import { Login } from 'app/shared/model/login';
import { Globals } from 'app/shared/services/globals.service';

@Component({
  template: ''
})
export class RootComponent implements OnInit {

  constructor(
     private auth: AuthService,
     private http: HttpClient,
     private session: SessionService,
     private globals: Globals,
     private router: Router) {
      console.log('Root created');
  }

  ngOnInit() {
    // Subscribe to login events, no use so far
    this.auth.authenticationState.subscribe((state) => {
      if (state) {
        console.log('logged in');
        this.goHome();
      } else {
        console.log('logged out');
        this.goLogin();
      }
    });

    // Check login
    console.log('Root trying to log in');
    this.auth.tryLogin()
    .then(ok => {
      if (ok) {
        console.log('Root log in success');
        // this.goHome();
      } else {
        console.log('Root log in failure');
        // this.goLogin();
      }
    })
    .catch(error => {
      console.log('Root log in error');
    });
  }

  goHome() {
    // Get login data
    const login = this.auth.login;

    // Get session info (principal and roles) from server
    this.http.get(`${this.globals.server}session/${Globals.encode(login.id)}`).subscribe(
      session => {

        // Store login data
        login.principal = session['name'];
        login.roles = session['roles'];
        login.permissions = session['permissions'];
        this.auth.login = login;

        // Check if login allowed
        if (!this.auth.canShow('CREATE:LOGIN')) {
          this.auth.doLogout();
          return;
        }

        // Read menu
        this.http.get<Menu>('assets/json/menu.json').subscribe(data => {

          // Get only parent menus with access
          const m = data.menu.filter(o => this.auth.canShow('VIEW:' + o.code));

          for (const i of m) {
            if (i.menu) {
                // Restrict access to child menus
                i.menu = i.menu.filter(o => this.auth.canShow('VIEW:' + o.code));

                // Update parent route
                if (i.menu.length > 0) {
                  i.route = i.menu[0].route;
                }
            }
          }

          // Assign resulting menu
          this.session.menu = m;

          // Goto home page
          const url = this.auth.login.state || '/home';
          this.router.navigate([url]);
        });

      },
      error => {
        console.log(error);
        this.router.navigate(['/logout']);
      }
    );

  }

  goLogin() {
    // Goto login page
    this.router.navigate(['/login']);
  }

}
