import { Purchase } from 'app/crud/sales/purchase/purchase';
import { Tasktype } from 'app/crud/sales/tasktype/tasktype';
import { Audit } from 'app/shared/model/audit';

// Purchasetask class
export class Purchasetask {

  // Key
  id: number;

  // Fields
  purchase: Purchase; // = new Purchase();
  tasktype: Tasktype; // = new Tasktype();
  details: string;
  plandate: string;
  donedate: string;

  // Audit
  audit : Audit = new Audit();
}

