<div class="content-wrapper">

  <app-header-content header="Geo"></app-header-content>

  <section class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Body -->
      <div class="box-body">
      <form [formGroup]="geoForm" focus>

        <!-- Form -->
        <div class="row">
          <div class="col-xs-3">
            <div class="form-group">
              <label class="control-label" for="client">Tipo de cliente:</label>
                <ng-select 
                  formControlName="client"
                  (change)="clientChange($event)"
                  [items]="clientoptions"
                  clearable="false"
                  bindLabel="name"
                  bindValue="id"
                  style="width: 100%;">
                </ng-select>
            </div>
          </div> <!-- end Field -->
        </div> <!-- end Field row -->

        <!-- Tabbed panel-->
        <div class="nav-tabs-custom">

          <!-- Tabs menu -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#tabMap" data-toggle="tab">Mapa</a>
            </li>
            <li>
              <a href="#tabList" data-toggle="tab">Lista</a>
            </li>
          </ul>

          <!-- Tabs content -->
          <div class="tab-content">

            <!-- Tab -->
            <div class="active tab-pane" id="tabMap">
              <div class="box-body" style="height:620px;">
                <div id="map"></div>
              </div>
            </div> <!-- end tab -->
      
            <!-- Tab -->
            <div class="active tab-pane" id="tabList">
              <div class="box-body">

                <!-- Table -->
                <div class="row">
                <div class="col-sm-12">
                <table id="table-geo" class="table table-bordered table-hover dataTable" role="grid">

                  <!-- Titles row -->	
                  <thead>
                  <tr role="row">
                    <th style="width:1%;">
                      <div class="checkbox">
                      <input type="checkbox" checked="{{ checkedAll }}" (change)="checkboxChangeAll($event)">
                      <label></label>
                      </div>
                    </th>
                    <th class="col-lg-1 ">Id</th>
                    <th class="col-lg-1 ">Dirección</th>
                    <th class="col-lg-1 ">Nombre</th>
                    <th class="col-lg-1 ">Email</th>
                    <th class="col-lg-1 ">Teléfono</th>
                    <th class="col-lg-1 ">Referencia</th>
                    <th class="col-lg-1 ">Tags</th>
                    <th style="width:1%;">Acciones</th>
                  </tr>
                  </thead>

                  <!-- Data rows -->					
                  <tbody>
                  <tr role="row" [ngClass]="{ odd:odd, even:even }"  *ngFor="let o of data; odd as odd; even as even">
                    <td>
                      <div class="checkbox">
                      <input type="checkbox" value="{{ o.id }}" checked="{{ o.checked }}" (change)="checkboxChange($event)">
                      <label></label>
                      </div>
                    </td>
                    <td class="">{{ o.id }}</td>
                    <td class="">{{ o.address }}</td>
                    <td class="">{{ o.name }}</td>
                    <td class="">{{ o.email }}</td>
                    <td class="">{{ o.phone }}</td>
                    <td class="">{{ o.reference }}</td>
                    <td class="">{{ o.tags }}</td>
                    <td>
                      <div class="btn-group" *ngIf="geoForm.get('client').value == '0'">
                        <button [routerLink]="['/purchases', o.id, 'edit']" type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i></button>
                        <button [routerLink]="['/purchases', o.id, 'view']" type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i></button>
                      </div>
                      <div class="btn-group" *ngIf="geoForm.get('client').value == '1'">
                        <button [routerLink]="['/sales', o.id, 'edit']" type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i></button>
                        <button [routerLink]="['/sales', o.id, 'view']" type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i></button>
                      </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
                </div>
                </div> <!-- end Table -->
	

              </div>
            </div> <!-- end tab -->

          </div>  <!-- end tab content -->

        </div>  <!-- end tabs -->

      </form>
      </div> <!-- end Body -->

    </div> <!-- end Panel -->

  </div>
  </div>
  </section> <!-- end Section -->

</div>
