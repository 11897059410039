// tslint:disable: max-line-length
// Angular imports
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Shared imports
import { Page } from 'app/shared/model/page';
import { Globals  } from 'app/shared/services/globals.service';

// Entity
import { Saledocument } from 'app/crud/sales/saledocument/saledocument';

// Request headers
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

// Service is injectable and singleton
@Injectable({
  providedIn: 'root'
})
export class SaledocumentService {

  // Page number
  page = 0;

  // Constructor
  constructor(
    private globals: Globals,
    private http: HttpClient
  ) { }

  // Read all saledocuments
  readAll(sort: string): Observable<Page<Saledocument>> {
    return this.read(0, sort, '', 999);
  }

  // Read pages of saledocuments
  read(page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Saledocument>> {
    let url = `${this.globals.server}saledocuments?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Saledocument>>(url).pipe(
      // tap(_ => console.log('read saledocuments')),
      catchError(this.handleError<Page<Saledocument>>('read Saledocument'))
    );
  }

  // Read pages of saledocuments from sales, sales cannot have composite keys
  readFromSale(sale: number, page: number = 0, sort: string = '', filter: string = '', size?: number): Observable<Page<Saledocument>> {
    let url = `${this.globals.server}sales/${sale}/saledocuments?page=${page}&sort=${sort}&filter=${filter}`;
    if (size !== undefined) {
      url = url + `&size=${size}`;
    }
    return this.http.get<Page<Saledocument>>(url).pipe(
      // tap(_ => console.log('read saledocuments')),
      catchError(this.handleError<Page<Saledocument>>('read Saledocument'))
    );
  }

  // Read one saledocument
  readOne(id: number): Observable<Saledocument> {
    return this.http.get<Saledocument>(`${this.globals.server}saledocuments/${id}`).pipe(
      // tap(_ => console.log(`readOne saledocument ${id} `)),
      catchError(this.handleError<Saledocument>(`readOne Saledocument ${id} `))
    );
  }

  // Count saledocuments
  count(): Observable<Saledocument[]> {
    return this.http.get<Saledocument[]>(`${this.globals.server}saledocuments/count`).pipe(
      // tap(_ => console.log('count saledocuments')),
      catchError(this.handleError('count Saledocument', []))
    );
  }

  // Create saledocument
  create(o: Saledocument): Observable<Saledocument> {
    const input = new FormData();
    if (o.file) { input.append('file', o.file, o.filename); }
    delete o.file;
    input.append('data', JSON.stringify(o));
    return this.http.post<Saledocument>(`${this.globals.server}saledocuments`, input).pipe(
      // tap(_ => console.log('create saledocument')),
      catchError(this.handleError<Saledocument>('create Saledocument'))
    );
  }

  // Update saledocument
  update(id: number, o: Saledocument): Observable<any> {
    const input = new FormData();
    if (o.file) { input.append('file', o.file, o.filename); }
    delete o.file;
    input.append('data', JSON.stringify(o));
    return this.http.put(`${this.globals.server}saledocuments/${id}`, input).pipe(
      // tap(_ => console.log(`update saledocument ${id} `)),
      catchError(this.handleError<any>(`update Saledocument ${id} `))
    );
  }

  // Delete saledocument
  delete(id: number): Observable<Saledocument> {
    return this.http.delete<Saledocument>(`${this.globals.server}saledocuments/${id}`, httpOptions).pipe(
      // tap(_ => console.log(`delete saledocument ${id} `)),
      catchError(this.handleError<Saledocument>(`delete Saledocument ${id} `))
    );
  }

  // Error handler
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return throwError(error);
    };
  }
}
