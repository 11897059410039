<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.COMMAND'|translate" [optional]="'LABEL.ENTITY.COMMAND.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="commandForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.COMMAND'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Key row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('instruction')">
              <label class="control-label" for="instruction">
                {{ 'LABEL.FIELD.COMMAND.INSTRUCTION'|translate }}
              </label>
              <input
                formControlName="instruction"
                maxlength="512"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.COMMAND.INSTRUCTION':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('instruction')?.msg|translate:err('instruction')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Key row -->

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('run')">
              <label class="control-label" for="run">
                {{ 'LABEL.FIELD.COMMAND.RUN'|translate }}
              </label>
              <div class="checkbox">
                <input
                  formControlName="run" id="run" 
                  type="checkbox">
                <label for="run">{{ 'LABEL.FIELD.COMMAND.RUN'|translate }}</label>
              </div>
              <span class="help-block">{{err('run')?.msg|translate:err('run')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="commandForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:COMMAND')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="commandForm.invalid || commandForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:COMMAND')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:COMMAND')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
