import { Sale } from 'app/crud/sales/sale/sale';
import { Tasktype } from 'app/crud/sales/tasktype/tasktype';
import { Audit } from 'app/shared/model/audit';

// Saletask class
export class Saletask {

  // Key
  id: number;

  // Fields
  sale: Sale; // = new Sale();
  tasktype: Tasktype; // = new Tasktype();
  details: string;
  plandate: string;
  donedate: string;

  // Audit
  audit : Audit = new Audit();
}

