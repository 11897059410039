import { Audit } from 'app/shared/model/audit';

// Content class
export class Content {

  // Key
  id: number;

  // Fields
  contenttype: string;
  url: string;
  title: string;
  subtitle: string;
  description: string;
  author: string;
  avatar: string;
  bio: string;
  tags: string;
  content: string;
  published: string;
  postdate: string;
  file: any;
  filetype: string;
  filename: string;
  filebytes: any;

  // Audit
  audit : Audit = new Audit();
}

