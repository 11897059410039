import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap';
import * as moment from 'moment';

import { PurchaseService } from 'app/crud/sales/purchase/purchase.service';
import { Purchase } from 'app/crud/sales/purchase/purchase';

import { AgentService } from 'app/crud/sales/agent/agent.service';
import { Agent } from 'app/crud/sales/agent/agent';
import { HometypeService } from 'app/crud/sales/hometype/hometype.service';
import { Hometype } from 'app/crud/sales/hometype/hometype';
import { OrientationService } from 'app/crud/sales/orientation/orientation.service';
import { Orientation } from 'app/crud/sales/orientation/orientation';
import { PurchasestatusService } from 'app/crud/sales/purchasestatus/purchasestatus.service';
import { Purchasestatus } from 'app/crud/sales/purchasestatus/purchasestatus';
import { SourceService } from 'app/crud/sales/source/source.service';
import { Source } from 'app/crud/sales/source/source';
import { TagService } from 'app/crud/sales/tag/tag.service';
import { Tag } from 'app/crud/sales/tag/tag';
import { ZoneService } from 'app/crud/sales/zone/zone.service';
import { Zone } from 'app/crud/sales/zone/zone';

import { ConfirmDialogService } from 'app/layout/modal/confirm-dialog.service';
import { SessionService } from 'app/shared/services/session.service';
import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-purchase-form',
  templateUrl: './purchase-form.component.html',
})
export class PurchaseFormComponent implements OnInit {

  // Defalt form mode
  mode = 'add';

  // Select fields
  zoneoptions: Zone[] = [];
  purchasestatusoptions: Purchasestatus[] = [];
  agentoptions: Agent[] = [];
  sourceoptions: Source[] = [];
  tagsoptions: Tag[] = [];
  hometypeoptions: Hometype[] = [];
  orientationoptions: Orientation[] = [];

  // Form
  purchaseForm = this.fb.group({
    // Fields
    email: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    name: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(100), ]],
    phone: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(20), ]],
    address: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(200), ]],
    zip: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(5), ]],
    zone: [{ value: '', disabled: false }, []],
    lat: [{ value: '', disabled: false }, []],
    lon: [{ value: '', disabled: false }, []],
    purchasestatus: [{ value: '', disabled: false }, [Validators.required, ]],
    statusdate: [{ value: '', disabled: false }, [Validators.required, this.dateValidator('L'), ]],
    agent: [{ value: '', disabled: false }, [Validators.required, ]],
    source: [{ value: '', disabled: false }, [Validators.required, ]],
    description: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    details: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    comments: [{ value: '', disabled: false }, [Validators.minLength(0), Validators.maxLength(1000), ]],
    tags: [{ value: '', disabled: false }, []],
    hometype: [{ value: '', disabled: false }, []],
    area: [{ value: '', disabled: false }, []],
    rooms: [{ value: '', disabled: false }, []],
    baths: [{ value: '', disabled: false }, []],
    exterior: [{ value: false, disabled: false }],
    elevator: [{ value: false, disabled: false }],
    terrace: [{ value: '', disabled: false }, []],
    garage: [{ value: '', disabled: false }, []],
    pool: [{ value: false, disabled: false }],
    orientation: [{ value: '', disabled: false }, []],
    createdon: [{ value: '', disabled: true }],
    updatedon: [{ value: '', disabled: true }],
  });

  // Working record
  data: Purchase = new Purchase();

  // Loading indicators
  isLoading = false;

  // Components constructor
  constructor(
    private apiAgent: AgentService,
    private apiHometype: HometypeService,
    private apiOrientation: OrientationService,
    private apiPurchasestatus: PurchasestatusService,
    private apiSource: SourceService,
    private apiTag: TagService,
    private apiZone: ZoneService,
    private api: PurchaseService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private confirm: ConfirmDialogService,
    private session: SessionService,
    private auth: AuthService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
  }

  // Component initialization
  ngOnInit() {
    // Init data
    this.data = new Purchase();
    this.data.zone = [];
    this.data.purchasestatus = new Purchasestatus();
    this.data.agent = new Agent();
    this.data.source = new Source();
    this.data.tags = [];
    this.data.hometype = new Hometype();
    this.data.orientation = new Orientation();

    // Load zone lookup
    this.loadZone();

    // Load purchasestatus lookup
    this.loadPurchasestatus();

    // Load agent lookup
    this.loadAgent();

    // Load source lookup
    this.loadSource();

    // Load tags lookup
    this.loadTags();

    // Load hometype lookup
    this.loadHometype();

    // Load orientation lookup
    this.loadOrientation();

    // Get form mode
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Disable fields in view mode
    if (this.mode === 'view') {
      Object.keys(this.purchaseForm.controls).forEach(key => {
        this.purchaseForm.get(key).disable();
      });
    }

    // Loads the record
    if (this.mode !== 'add') {
      this.load(
        this.route.snapshot.params.id
      );
    }
  }

  mapDataToForm(data: Purchase, form: FormGroup) {
    form.get('email').setValue(data.email);
    form.get('name').setValue(data.name);
    form.get('phone').setValue(data.phone);
    form.get('address').setValue(data.address);
    form.get('zip').setValue(data.zip);
    if (data.zone) { form.get('zone').setValue(data.zone.map(x => x.id)); } else { data.zone = []; }
    form.get('lat').setValue(data.lat);
    form.get('lon').setValue(data.lon);
    if (data.purchasestatus) { form.get('purchasestatus').setValue(data.purchasestatus.id); } else { data.purchasestatus = new Purchasestatus(); }
    this.setDateTime(form.get('createdon'), data.audit.createdon, 'L LTS');
    this.setDateTime(form.get('statusdate'), data.statusdate, 'L');
    if (data.agent) { form.get('agent').setValue(data.agent.id); } else { data.agent = new Agent(); }
    if (data.source) { form.get('source').setValue(data.source.id); } else { data.source = new Source(); }
    form.get('description').setValue(data.description);
    form.get('details').setValue(data.details);
    form.get('comments').setValue(data.comments);
    if (data.tags) { form.get('tags').setValue(data.tags.map(x => x.id)); } else { data.tags = []; }
    if (data.hometype) { form.get('hometype').setValue(data.hometype.id); } else { data.hometype = new Hometype(); }
    form.get('area').setValue(data.area);
    form.get('rooms').setValue(data.rooms);
    form.get('baths').setValue(data.baths);
    form.get('exterior').setValue(data.exterior);
    form.get('elevator').setValue(data.elevator);
    form.get('terrace').setValue(data.terrace);
    form.get('garage').setValue(data.garage);
    form.get('pool').setValue(data.pool);
    if (data.orientation) { form.get('orientation').setValue(data.orientation.id); } else { data.orientation = new Orientation(); }
  }

  mapFormToData(form: FormGroup, data: Purchase) {
    data.email = form.get('email').value;
    data.name = form.get('name').value;
    data.phone = form.get('phone').value;
    data.address = form.get('address').value;
    data.zip = form.get('zip').value;
    if (form.get('zone').value) { data.zone = form.get('zone').value.map(x => ({id: x})); }
    data.lat = form.get('lat').value;
    data.lon = form.get('lon').value;
    if (!form.get('purchasestatus').value) { data.purchasestatus = null; } else { data.purchasestatus.id = form.get('purchasestatus').value; }
    data.statusdate = this.getDateTime(form.get('statusdate'), data.statusdate, 'L');
    if (!form.get('agent').value) { data.agent = null; } else { data.agent.id = form.get('agent').value; }
    if (!form.get('source').value) { data.source = null; } else { data.source.id = form.get('source').value; }
    data.description = form.get('description').value;
    data.details = form.get('details').value;
    data.comments = form.get('comments').value;
    if (form.get('tags').value) { data.tags = form.get('tags').value.map(x => ({id: x})); }
    if (!form.get('hometype').value) { data.hometype = null; } else { data.hometype.id = form.get('hometype').value; }
    data.area = form.get('area').value;
    data.rooms = form.get('rooms').value;
    data.baths = form.get('baths').value;
    data.exterior = form.get('exterior').value;
    data.elevator = form.get('elevator').value;
    data.terrace = form.get('terrace').value;
    data.garage = form.get('garage').value;
    data.pool = form.get('pool').value;
    if (!form.get('orientation').value) { data.orientation = null; } else { data.orientation.id = form.get('orientation').value; }
  }

  // Load selected record
  load(id) {
    this.isLoading = true;
    this.api.readOne(id).subscribe(
      res => {
        this.data = res;
        this.mapDataToForm(this.data, this.purchaseForm);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('view', 'PURCHASE', err);
      });
  }

  // Create a new record
  createData() {
    this.isLoading = true;
    this.mapFormToData(this.purchaseForm, this.data);
    this.api.create(this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('add', 'PURCHASE');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('add', 'PURCHASE', err);
      });
  }

  // Update record
  updateData() {
    this.isLoading = true;
    this.mapFormToData(this.purchaseForm, this.data);
    this.api.update(this.data.id, this.data).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
        this.globals.crudOk('update', 'PURCHASE');
        this.back();
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('update', 'PURCHASE', err);
      });
  }

  // Delete record
  deleteData() {
    const title = this.translate.instant('MESSAGE.DELETE') + ' ' + this.translate.instant('LABEL.ENTITY.PURCHASE');
    const text  = this.translate.instant('MESSAGE.SURE');
    this.confirm.confirmThis(title, text, () => {}, () => {
      this.isLoading = true;
      this.api.delete(this.data.id).subscribe(
        res => {
          this.isLoading = false;
          this.globals.crudOk('delete', 'PURCHASE');
          this.back();
        },
        err => {
          this.isLoading = false;
          this.globals.crudKo('delete', 'PURCHASE', err);
        });
    });
  }

  // Return to list page
  back() {
    this.location.back();
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.purchaseForm, name);
  }

  // Load zone lookup
  loadZone() {
    this.apiZone.readAll('+name').subscribe(
      res => {
        this.zoneoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load purchasestatus lookup
  loadPurchasestatus() {
    this.apiPurchasestatus.readAll('+name').subscribe(
      res => {
        this.purchasestatusoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load agent lookup
  loadAgent() {
    this.apiAgent.readAll('+name').subscribe(
      res => {
        this.agentoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load source lookup
  loadSource() {
    this.apiSource.readAll('+name').subscribe(
      res => {
        this.sourceoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load tag lookup
  loadTags() {
    this.apiTag.readAll('+name').subscribe(
      res => {
        this.tagsoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load hometype lookup
  loadHometype() {
    this.apiHometype.readAll('+name').subscribe(
      res => {
        this.hometypeoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Load orientation lookup
  loadOrientation() {
    this.apiOrientation.readAll('+name').subscribe(
      res => {
        this.orientationoptions = res.content;
      },
      err => {
        console.log(err);
      }
    );
  }

  dateValidator(format: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value === null || control.value === '') return null;
      moment.locale(this.session.lang.code);
      const date = moment.utc(control.value, format);
      return date.isValid() ? null : { invaliddate: true };
    };
  }

  getDateTime(control: AbstractControl, date: any, format: string) {
    if (control.value === null || control.value === '') return null;
    // Ñapa
    const aux = control.value;
    if (typeof aux != 'string')
      aux.setHours(aux.getHours() - aux.getTimezoneOffset() / 60);
    // Ñapa
    moment.locale(this.session.lang.code);
    const d = moment.utc(aux, format);
    if (d.isValid()) {
      return d.toISOString();
    } else {
      return date;
    }
  }

  setDateTime(control: AbstractControl, date: any, format: string) {
    moment.locale(this.session.lang.code);
    const d = moment.utc(date, 'YYYY-MM-DD');
    if (d.isValid()) {
      control.setValue(d.format(format));
    }
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
