import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { SalevaluationService } from 'app/crud/sales/salevaluation/salevaluation.service';
import { Salevaluation } from 'app/crud/sales/salevaluation/salevaluation';

import { DoctypeService } from 'app/crud/sales/doctype/doctype.service';
import { Doctype } from 'app/crud/sales/doctype/doctype';
import { SalecontactService } from 'app/crud/sales/salecontact/salecontact.service';
import { Salecontact } from 'app/crud/sales/salecontact/salecontact';
import { SaleService } from 'app/crud/sales/sale/sale.service';
import { Sale } from 'app/crud/sales/sale/sale';

@Component({
  selector: 'app-salevaluation-sale-table',
  templateUrl: './salevaluation-sale-table.component.html',
})
export class SalevaluationSaleTableComponent implements OnInit {

  // Select fields
  doctypeoptions: Doctype[] = [];
  salecontactoptions: Salecontact[] = [];
  saleoptions: Sale[] = [];

  // Parent
  @Input() parent: string;
  @Input() value: any;

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    sale_id: '',
    sale_address: '',
    contact_id: '',
    contact_email: '',
    contact_crmid: '',
    doctype_id: '',
    doctype_name: '',
    details: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  //? sort = 'sale';
  //? dir = '+';
  sort = '';
  dir = '';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Salevaluation>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiDoctype: DoctypeService,
    private apiSalecontact: SalecontactService,
    private apiSale: SaleService,
    private api: SalevaluationService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'SalevaluationSale') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load doctype lookup
    this.loadDoctype();

    // Load salecontact lookup
    this.loadSalecontact();

    // Load sale lookup
    this.loadSale();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.readFromSale(this.value, this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'SALEVALUATION', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'SalevaluationSale';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'sale.id', this.search.sale_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'sale.address', this.search.sale_address, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'contact.id', this.search.contact_id, 'eq', this.session.lang.code, 'Long');
    Globals.pushFilter(f, 'contact.email', this.search.contact_email, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'contact.crmid', this.search.contact_crmid, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'doctype.id', this.search.doctype_id, 'eq', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'doctype.name', this.search.doctype_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'details', this.search.details, 'like', this.session.lang.code, 'String');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'SalevaluationSale';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.sale_id = '';
    this.search.sale_address = '';
    this.search.contact_id = '';
    this.search.contact_email = '';
    this.search.contact_crmid = '';
    this.search.doctype_id = '';
    this.search.doctype_name = '';
    this.search.details = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load doctype lookup
  loadDoctype() {
    this.apiDoctype.readAll('').subscribe(
      res => {
        this.doctypeoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'DOCTYPE', err);
      }
    );
  }

  // Load salecontact lookup
  loadSalecontact() {
    this.apiSalecontact.readAll('').subscribe(
      res => {
        this.salecontactoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'SALECONTACT', err);
      }
    );
  }

  // Load sale lookup
  loadSale() {
    this.apiSale.readAll('').subscribe(
      res => {
        this.saleoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'SALE', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
