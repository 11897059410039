import { Why } from 'app/crud/sales/why/why';
import { Audit } from 'app/shared/model/audit';

// Entry class
export class Entry {

  // Key
  id: number;

  // Fields
  contact: string;
  subject: string;
  message: string;
  crmid: string;
  client: string;
  email: string;
  phone: string;
  rgpd: string;
  confirm: string;
  reference: string;
  zone: string;
  why: Why; // = new Why();
  data: string;
  comments: string;
  tags: string;
  transferred: string;

  // Audit
  audit : Audit = new Audit();
}

