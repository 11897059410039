<div class="content-wrapper">

  <app-spinner *ngIf="isLoading"></app-spinner>
  <app-confirm-dialog></app-confirm-dialog>
  
  <app-header-content [header]="'LABEL.ENTITY.PURCHASESTATUS'|translate" [optional]="'LABEL.ENTITY.PURCHASESTATUS.DESCRIPTION'|translate"></app-header-content>

  <section *ngIf="data" class="content">
  <div class="row">
  <div class="col-xs-12">

    <!-- Form -->
    <form [formGroup]="purchasestatusForm" focus>

    <!-- Panel-->
    <div class="box box-primary">

      <!-- Header -->
      <div class="box-header with-border">
        <h3 class="box-title">{{ 'LABEL.ENTITY.PURCHASESTATUS'|translate }}</h3>
      </div> <!-- end Header -->

      <!-- Body -->
      <div class="box-body">

        <!-- Key row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('id')">
              <label class="control-label" for="id">
                {{ 'LABEL.FIELD.PURCHASESTATUS.ID'|translate }}
              </label>
              <input
                formControlName="id"
                maxlength="2"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASESTATUS.ID':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('id')?.msg|translate:err('id')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Key row -->

        <!-- Field row (1) -->
        <div class="row">

          <!-- Field -->
          <div class="col-xs-6">
            <div class="form-group" [class.has-error]="err('name')">
              <label class="control-label" for="name">
                {{ 'LABEL.FIELD.PURCHASESTATUS.NAME'|translate }} <i class="text-danger fa fa-exclamation"></i>
              </label>
              <input
                formControlName="name"
                maxlength="20"
                placeholder="{{ (mode!='view'?'MESSAGE.ENTER':'')|translate }} {{ (mode!='view'?'LABEL.FIELD.PURCHASESTATUS.NAME':'')|translate }}"
                type="text"
                class="form-control" autocomplete="off" >
              <span class="help-block">{{err('name')?.msg|translate:err('name')?.param}}</span>
            </div>
          </div> <!-- end Field -->
        
        </div> <!-- end Field row -->

        <!-- Button bar -->
        <div class="box-footer">
          <div class="btn-grp">
            <button (click)="back()" type="button" class="btn btn-default">{{ 'BUTTON.CANCEL'|translate }}</button>
            <button [disabled]="purchasestatusForm.invalid" *ngIf="mode=='add' && auth.canShow('CREATE:PURCHASESTATUS')" (click)="createData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }}</button>
            <button [disabled]="purchasestatusForm.invalid || purchasestatusForm.pristine" *ngIf="mode=='edit' && auth.canShow('UPDATE:PURCHASESTATUS')" (click)="updateData()" type="submit" style="margin-right: 5px;"class="btn btn-primary pull-right">{{ 'BUTTON.SAVE'|translate }}</button>
            <button *ngIf="mode=='edit' && auth.canShow('DELETE:PURCHASESTATUS')" (click)="deleteData()" type="button" style="margin-right: 5px;"class="btn btn-danger pull-right">{{ 'BUTTON.DELETE'|translate }}</button>
          </div>
        </div> <!-- end Button bar -->

      </div> <!-- end Body -->

    </div> <!-- end Panel -->

    </form>

  </div>
  </div>
  </section> <!-- end Section -->

</div>
