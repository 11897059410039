import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../model/language';
import { registerLocaleData } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap';
import { esLocale, frLocale } from 'ngx-bootstrap/locale';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeEsExtra from '@angular/common/locales/es';
import localeFrExtra from '@angular/common/locales/fr';

@Injectable({
  providedIn: 'root'
})
export class Globals {

  // Configuration
  config: any;

  // Servers
  server: any;
  auth: any;

  // Languages
  public langs: Language[] = [
    { code: 'en', name: 'English', culture: 'en' },
    { code: 'es', name: 'Español', culture: 'es' },
    //{ code: 'fr', name: 'Français', culture: 'fr' },
    //{ code: 'pt', name: 'Portugues', culture: 'pt' },
  ];

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService) {

    console.log('Globals created');

    // Register other locales
    defineLocale('es', esLocale); registerLocaleData(localeEs, 'es', localeEsExtra);
    defineLocale('fr', frLocale); registerLocaleData(localeFr, 'fr', localeFrExtra);

    // Add supported languages
    translate.addLangs(this.langs.map( (x) => x.code ));

    // Read server global conf
    // this.http.get(`${environment.server}config/admin`).subscribe(
    this.server = environment.server;
    this.auth = environment.auth;
    this.http.get('assets/json/config.json').subscribe(
      data => {
        this.config = data;
        console.log('Globals config read');
      }
    );
  }

  // ***************************************************
  // Global functions
  // ***************************************************

  // Clean a URI
  static clean(str: string): string {
    if (str) {
      return str
        .replace(/,/g,  '')
        .replace(/\*/g, '')
        .replace(/\?/g, '')
        .replace(/\\/g, '')
      ;
    }
    return '';
  }

  // Encode URI
  static encode(str: string): string {
    if (str) {
      return encodeURI(str)
        .replace(/!/g,  '%21')
        .replace(/&/g,  '%26')
        .replace(/\//g, '%2F')
        .replace(/\+/g, '%2B')
        .replace(/\-/g, '%2D')
      ;
    }
    return '';
  }

  // Add a criteria to a filter
  static pushFilter(f: string[], field: string, search: string, op: string, locale: string, cls: string) {

    // Clean searh value
    const data: string = Globals.clean(search);

    // No search
    if (data == '') 
      return;

    // Not null operator
    if (data.startsWith('!!')) {
      f.push(field + ':nnul:a'); 
      return;
    } 

    // Null operator
    if (data.startsWith('!')) {
      if (data.length > 1) { 
        f.push(field + ':ne:' + this.parse(cls, locale, data.substring(1))); 
      }
      else { 
        f.push(field + ':null:a'); 
      }
      return;
    } 

    // Comparisons
    let val = null;
    if (data.startsWith('>=')) {
      op = 'ge';
      if (data.length > 2) 
        val = this.parse(cls, locale, data.substring(2));
    } 
    else if (data.startsWith('<=')) {
      op = 'le';
      if (data.length > 2) 
        val = this.parse(cls, locale, data.substring(2));
    } 
    else if (data.startsWith('>')) {
      op = 'gt';
      if (data.length > 1) 
        val = this.parse(cls, locale, data.substring(1));
    } 
    else if (data.startsWith('<')) {
      op = 'lt';
      if (data.length > 1) 
        val = this.parse(cls, locale, data.substring(1));
    } 
    else {
      val = this.parse(cls, locale, data);
    }

    // Push filter
    if (val != null) {
      f.push(field + ':' + op + ':' + val);
    }
  }

  // Parse value
  static parse(cls: string, locale: string, value: string) {
    
    // Date only
    if (cls == 'LocalDate') {
      return this.getDate(locale, value);
    }

    // Time only
    if (cls == 'LocalTime') {
      return value;
    }

    // Datetime
    if (cls == 'Date') {
      return this.getDateTime(locale, value);
    }

    // Rest
    return value;
  }

  // Validate search datetime
  static getDateTime(locale: string, value: string) {
    if (locale === undefined)
      return value;
    //const d = moment.utc(value, "L");
    const d = moment(value, 'DD/MM/YYYY HH:mm:ss');
    console.log(d.format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'));
    if (d.isValid()) {
      return d.format('YYYY-MM-DD[T]HH:mm:ss[.000Z]');
    } else {
      return null;
    }
  }

  // Validate search date
  static getDate(locale: string, value: string) {
    if (locale === undefined)
      return value;
    moment.locale(locale);
    //const d = moment.utc(value, "L");
    const d = moment(value, 'DD/MM/YYYY');
    console.log(d.format('YYYY-MM-DD'));
    if (d.isValid()) {
      return d.format("YYYY-MM-DD");
    } else {
      return null;
    }
  }

  // Form error messages
  static err(form: FormGroup, name: string) {
    const field = form.get(name);
    if (field === undefined) {
      return null;
    }
    if (field.invalid && (field.dirty || field.touched)) {
      if (field.hasError('minlength')) {
        return {msg: 'ERROR.FIELD.MINLENGTH', param: {n: field.getError('minlength').requiredLength}};
      }
      if (field.hasError('maxlength')) {
        return {msg: 'ERROR.FIELD.MAXLENGTH', param: {n: field.getError('maxlength').requiredLength}};
      }
      if (field.hasError('min')) {
        return {msg: 'ERROR.FIELD.MIN',       param: {n: field.getError('min').min}};
      }
      if (field.hasError('max')) {
        return {msg: 'ERROR.FIELD.MAX',       param: {n: field.getError('max').max}};
      }
      if (field.hasError('required')) {
        return {msg: 'ERROR.FIELD.MANDATORY'};
      }
      if (field.hasError('pattern')) {
        return {msg: 'ERROR.FIELD.INVALID'};
      }
      if (field.hasError('invaliddate')) {
        return {msg: 'ERROR.FIELD.INVALIDDATE'};
      }
      if (field.hasError('invaliddatetime')) {
        return {msg: 'ERROR.FIELD.INVALIDDATETIME'};
      }
      if (field.hasError('email')) {
        return {msg: 'ERROR.FIELD.INVALIDEMAIL'};
      }
    }
    return null;
  }

  // Get icon for mime-type
  static mimeIcon(mimeType: string) {
    if (mimeType === undefined) { return ''; }

    if (mimeType.startsWith('image')) { return 'fa-file-image-o'; }
    if (mimeType.startsWith('audio')) { return 'fa-file-audio-o'; }
    if (mimeType.startsWith('video')) { return 'fa-file-video-o'; }

    if (mimeType.startsWith('application/msword')) { return 'fa-file-word-o'; }
    if (mimeType.startsWith('application/vnd.ms-word')) { return 'fa-file-word-o'; }
    if (mimeType.startsWith('application/vnd.oasis.opendocument.text')) { return 'fa-file-word-o'; }
    if (mimeType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml')) { return 'fa-file-word-o'; }

    if (mimeType.startsWith('application/vnd.ms-excel')) { return 'fa-file-excel-o'; }
    if (mimeType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml')) { return 'fa-file-excel-o'; }
    if (mimeType.startsWith('application/vnd.oasis.opendocument.spreadsheet')) { return 'fa-file-excel-o'; }

    if (mimeType.startsWith('application/vnd.ms-powerpoint')) { return 'fa-file-powerpoint-o'; }
    if (mimeType.startsWith('application/vnd.openxmlformats-officedocument.presentationml')) { return 'fa-file-powerpoint-o'; }
    if (mimeType.startsWith('application/vnd.oasis.opendocument.presentation')) { return 'fa-file-powerpoint-o'; }

    if (mimeType === 'text/plain') { return 'fa-file-text-o'; }
    if (mimeType === 'text/html') { return 'fa-file-code-o'; }
    if (mimeType === 'application/pdf') { return 'fa-file-pdf-o'; }
    if (mimeType === 'application/json') { return 'fa-file-code-o'; }
    if (mimeType === 'application/gzip') { return 'fa-file-archive-o'; }
    if (mimeType === 'application/zip') { return 'fa-file-archive-o'; }

    return 'fa-file-o';
  }

  // Toast Ok
  crudOk(op: string, entity: string) {
    const tEntity = this.translate.instant('LABEL.ENTITY.' + entity.toUpperCase());
    const tResult = this.translate.instant('MESSAGE.OK');
    const tMsg = this.translate.instant('MESSAGE.' + op.toUpperCase() + '.OK', {x: tEntity, e: ''});
    this.toastr.success(tMsg, tResult);
  }

  // Toask KO
  crudKo(op: string, entity: string, error?: any) {
    let details = '';
    if (error !== undefined) {
      // console.log(error);
      if (error instanceof HttpErrorResponse) {
        try {
          details = this.translate.instant(error.error.code);
        } catch {
          details = error.statusText;
        }
      }
    }
    const tEntity = this.translate.instant('LABEL.ENTITY.' + entity.toUpperCase());
    const tResult = this.translate.instant('MESSAGE.KO');
    const tMsg = this.translate.instant('MESSAGE.' + op.toUpperCase() + '.KO', { x: tEntity, e: details });
    this.toastr.error(tMsg, tResult);
  }

  labelcolor(id: number) {
    switch (id % 5) {
      case 0:  return 'label-warning';
      case 1:  return 'label-danger';
      case 2:  return 'label-success';
      case 3:  return 'label-info';
      default: return 'label-primary';
    }

  }

  // ***************************************************
  // Custom functions
  // ***************************************************

  // Update command
  generateBlog(data) {
    this.http.put('https://api.newhomers.com/api/v1/commands/generate-web', { 'run': true }).subscribe();
  }

}
