// Angular imports
import { HttpClient } from '@angular/common/http';
import { Component, OnInit  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'app/shared/services/globals.service';

@Component({
  selector: 'app-valuator',
  templateUrl: './valuator.component.html',
  styleUrls: ['./valuator.component.css']
})
export class ValuatorComponent implements OnInit {

  // Form
  public valuatorForm = this.fb.group({
    ref: [{ value: '', disabled: false }, [Validators.required, ]],
    ren: [{ value: '', disabled: false }, [Validators.required, ]],
    exi: [{ value: 'EX', disabled: false }, [Validators.required, ]],
    tpl: [{ value: 'newhomers-pdf', disabled: false }, [Validators.required, ]],
    sup: [{ value: '', disabled: false }, []],
    p_act: [{ value: '', disabled: false }, []],
    p_new: [{ value: '', disabled: false }, []],
    p_ren: [{ value: '', disabled: false }, []],
    p_sal: [{ value: '', disabled: false }, []],
  });

  // Options
  public exioptions = [
    { id: 'EX', name: 'Exterior' },
    { id: 'IN', name: 'Interior' },
  ];
  public tploptions = [
    { id: 'default-pdf', name: 'Interno (PDF)' },
    { id: 'default-html', name: 'Interno (HTML)' },
    { id: 'newhomers-pdf', name: 'Newhomers (PDF)' },
    { id: 'newhomerscomercial-pdf', name: 'Newhomers Comercial (PDF)' },
    { id: 'arkittai-pdf', name: 'Arkittai (PDF)' },
    { id: 'arkittaicomercial-pdf', name: 'Arkittai Comercial (PDF)' },
  ];

  // Loading
  public isLoading = false;

  // Components constructor
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private globals: Globals) {}

  // Component initialization
  ngOnInit() {
  }

  // Field error status and  message
  err(name: string) {
    return Globals.err(this.valuatorForm, name);
  }

  // Valuate
  valuate() {

    // Loading
    this.isLoading = true;

    // Get form values
    const ref   = this.valuatorForm.get('ref').value;
    const ren   = this.valuatorForm.get('ren').value;
    const exi   = this.valuatorForm.get('exi').value;
    const tpl   = this.valuatorForm.get('tpl').value;
    const sup   = this.valuatorForm.get('sup').value;
    const p_act = this.valuatorForm.get('p_act').value;
    const p_new = this.valuatorForm.get('p_new').value;
    const p_ref = this.valuatorForm.get('p_ren').value;
    const p_sal = this.valuatorForm.get('p_sal').value;

    // Call
    let url = 'https://alpha.arkittai.com/app/val/calcc' +
              '?rc='       + ref +
              '&rf='       + ren +
              '&ex='       + exi +
              '&template=' + tpl +
              '&area='     + sup +
              '&pact='     + p_act +
              '&pnew='     + p_new +
              '&pref='     + p_ref +
              '&psal='     + p_sal +
              '&id=CRMID';
    this.http.get(url, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        let objectURL = URL.createObjectURL(response);
        this.isLoading = false;
        window.open(objectURL, '_blank');
      });
  }  
}
