	<!-- Loading spinner -->
	<app-spinner *ngIf="isLoading"></app-spinner>

	<!-- Data -->
	<div *ngIf="data">

	  <!-- Header -->
	  <div class="box-header with-border">
		<h3 class="box-title">{{ 'LABEL.ENTITY.PRIVILEGE.TABLE'|translate }}</h3>
		<button *ngIf="mode!='view' && auth.canShow('CREATE:PRIVILEGE')"
		  [routerLink]="['/', parent, value, 'privileges', 'add']" 
		  type="button" class="btn btn-primary pull-right">{{ 'BUTTON.ADD'|translate }} {{ 'LABEL.ENTITY.PRIVILEGE'|translate }}</button>
	  </div> <!-- end Header -->
	
	  <!-- Body -->
	  <div class="box-body dataTables_wrapper form-inline dt-bootstrap">

		<!-- Table -->
		<div class="row">
		<div class="col-sm-12">
		<table id="table-privilege" class="table table-bordered table-hover dataTable" role="grid">

		  <!-- Titles row -->	
		  <thead>
			<tr role="row">
			  <!-- <th style="width:1%;"><div class="checkbox"><input type="checkbox"><label></label></div></th> -->
			  <th class="col-lg-1 " [ngClass]="isSorted('resource')" (click)="doSort('resource')">
			    {{'LABEL.FIELD.PRIVILEGE.RESOURCE'|translate}}
			  </th>
			  <th class="col-lg-1 " [ngClass]="isSorted('action')" (click)="doSort('action')">
			    {{'LABEL.FIELD.PRIVILEGE.ACTION'|translate}}
			  </th>
			  <th style="width:1%;">{{'LABEL.ACTIONS'|translate}}</th>
			</tr>
		  </thead>

		  <!-- Filters row -->	
		  <thead>
			<tr role="row">
			  <!-- <th></th> -->
			  <th class="">
			    <select [(ngModel)]="search.resource" (input)="doFilter($event)" class="form-control" style="width: 100%;">
			  	<option value="">{{ 'LABEL.ALL'|translate }}</option>
			  	<option *ngFor="let item of resourceoptions" value="{{ item|uppercase }}">{{ 'ENUM.RESOURCE.'+item|translate }}</option>
			    </select>
			  </th>
			  <th class="">
			    <select [(ngModel)]="search.action" (input)="doFilter($event)" class="form-control" style="width: 100%;">
			  	<option value="">{{ 'LABEL.ALL'|translate }}</option>
			  	<option *ngFor="let item of actionoptions" value="{{ item|uppercase }}">{{ 'ENUM.ACTION.'+item|translate }}</option>
			    </select>
			  </th>
			  <th>
				<button (click)="clearFilter()" type="button" class="btn btn-default btn-sm">
				  <i class="fa fa-filter" style="position: relative;">
					<i class="fa fa-ban" style="position: absolute; left: 0px; color:Tomato"></i>
				  </i>
				</button>
			  </th>
			</tr>
		  </thead>

		  <!-- Data rows -->					
		  <tbody>
			<tr role="row"[ngClass]="{ odd:odd, even:even }" *ngFor="let o of data.content; odd as odd; even as even">
			  <!-- <td><div class="checkbox"><input type="checkbox"><label></label></div></td> -->
			  <td class="">
			    {{ 'ENUM.RESOURCE.' + o.resource|translate }}
			  </td>
			  <td class="">
			    {{ 'ENUM.ACTION.' + o.action|translate }}
			  </td>
			  <td>
				<div class="btn-group">
				  <button *ngIf="mode!='view' && auth.canShow('UPDATE:PRIVILEGE')"
					[routerLink]="['/', parent, value, 'privileges', o.id, 'edit']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-edit"></i>
				  </button>
				  <button
					[routerLink]="['/', parent, value, 'privileges', o.id, 'view']"
					type="button" class="btn btn-default btn-sm"><i class="fa fa-eye"></i>
				  </button>
				</div>
			  </td>
			</tr>
		  </tbody>

		</table>
		</div>
		</div> <!-- end Table -->
	
		<!-- Pagination -->
		<app-pagination [total]="data.totalElements" [size]="data.size" [num]="data.numberOfElements" (go)="doPage($event)"></app-pagination>
	
	  </div> <!-- end Body -->

	</div> <!-- end Data -->
